import React from 'react';
import { DocumentHead, Loading, PageError } from '../../components';
import { useState } from './state';

export const CheckoutSuccess: React.ComponentType = () => {
	const state = useState();

	if (state.status === 'error' && state.error) {
		if (state.error.code === 'deadline-exceeded') {
			return (
				<PageError
					error={
						'We have failed to verify your payment'
					}
				/>
			);
		}
		return <PageError error={state.error} />;
	}

	return (
		<>
			<DocumentHead title="Finalizing payment..." />
			<Loading showRandomLabels />
		</>
	);
};
