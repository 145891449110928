import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useCurrentUser, useCurrentAccount } from '@common';
import { Showcase } from './Showcase';
import { ctaSettings } from './SliderSettings';
import { useFormattedHubUrlOrPath } from '../../../data/urlBuilding';
import { Card } from './card';

const CDN = 'https://cdn.remotesocial.io/hub/img';

const CtaShowcase = ({ ctas, onSelect }) => {
	const user = useCurrentUser();
	const accounts = useCurrentAccount();
	const currentAccount = accounts.currentAccount;
	const role = user.uid === currentAccount.owner ? 'admin' : 'member';
	const combinedCtas = (ctas[role] || []).concat(ctas.all);

	return (
		<Showcase sliderSetting={ctaSettings}>
			{combinedCtas.map((cta) => {
				return (
					<div key={cta.name}>
						<CtaCard cta={cta} onSelect={() => onSelect(cta)} />
					</div>
				);
			})}
		</Showcase>
	);
};

export { CtaShowcase };

const CtaCard = ({ cta, onSelect }) => {
	const theme = useTheme();
	const breakPoint = useMediaQuery(theme.breakpoints.up('md')) ? 'md' : 'xs';
	const parsedHref = useFormattedHubUrlOrPath(cta.href);
	return (
		<Card
			avatarUrl={`${CDN}/ctas/${cta.images[breakPoint]}`}
			avatarAlt={cta.name}
			href={parsedHref}
			onSelect={onSelect}
			isPro={cta.isPro}
		/>
	);
};
