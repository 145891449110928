import { Route, Switch } from 'react-router-dom';
import { Loading, PageNotFound } from '@common/components';
import {
	AccountRolesRoute,
	Auth,
	CommonRouter,
	HasAccountRoute,
} from '@common/routes';
import { hubRoutes } from '@common/routes/hubRoutes';
import { Home } from '../pages/home/page';
import { SlackErrors } from '../features/slack/SlackErrors';
import People from '../features/account/people/People';
import { GameSettings } from '../features/settings/GameSettings';
import { GeneralSettings } from '../features/settings/general-settings/page';
import { AccountPlayHistory } from '../features/account/AccountPlayHistory';
import { MemberProfile } from '../pages/member-profile/page';
import { TriviaCustomizer } from '../features/settings/GameSettings/TriviaCustomizer';
import { Billing } from '../features/settings/billing';
import { HubBreadcrumbs } from '../components/breadcrumbs';
import { JoinZiagoGame } from '../pages/join-ziago-game/page';
import { OnboardingLayout } from '../components/onboardingLayout';
import { HubLayout } from '../components/hubLayout';
import { CreateMemberProfile } from '../pages/create-member-profile/page';
import { TypeFormLayout } from '../components/typeFormLayout';
import { LoginSettings } from '../pages/login-settings/page';

const HubPageNotFound = () => (
	<HubLayout>
		<PageNotFound />
	</HubLayout>
);

const HubLoading = () => (
	<HubLayout>
		<Loading showRandomLabels={true} />
	</HubLayout>
);

export const HubAppRouter = () => {
	return (
		<CommonRouter
			public={
				<>
					<Route path={hubRoutes.handleInvite.path}>
						<Auth
							variant="HandleInvite"
							containerVariant="two-column"
							ScreenContainer={OnboardingLayout}
						/>
					</Route>
					<Route path={hubRoutes.games.joinZiagoGame.path}>
						<OnboardingLayout>
							<JoinZiagoGame />
						</OnboardingLayout>
					</Route>
					<Route path={hubRoutes.slack.path}>
						<OnboardingLayout>
							<SlackErrors />
						</OnboardingLayout>
					</Route>
				</>
			}
			private={
				<>
					<HasAccountRoute exact path={hubRoutes.home.path}>
						<HubLayout>
							<Home />
						</HubLayout>
					</HasAccountRoute>
					<HasAccountRoute
						exact
						path={hubRoutes.user.loginSettings.path}
					>
						<HubLayout>
							<LoginSettings />
						</HubLayout>
					</HasAccountRoute>
					<HasAccountRoute
						exact
						path={hubRoutes.accounts.createMemberProfile.path}
					>
						<TypeFormLayout>
							<CreateMemberProfile />
						</TypeFormLayout>
					</HasAccountRoute>
					<HasAccountRoute
						exact
						path={hubRoutes.accounts.memberProfile.path}
					>
						<HubLayout>
							<MemberProfile />
						</HubLayout>
					</HasAccountRoute>
					<HasAccountRoute
						path={[
							hubRoutes.sessions.path,
							hubRoutes.settings.root.path,
						]}
					>
						<HubLayout>
							<HubBreadcrumbs />
							<Switch>
								<Route exact path={hubRoutes.sessions.path}>
									<AccountPlayHistory />
								</Route>
								<Route
									exact
									path={hubRoutes.settings.people.path}
								>
									<People />
								</Route>
								<AccountRolesRoute
									path={hubRoutes.settings.root.path}
									roles={['owner', 'admin']}
								>
									<Switch>
										<Route
											exact
											path={
												hubRoutes.settings.account.path
											}
										>
											<GeneralSettings />
										</Route>
										<Route
											exact
											path={
												hubRoutes.settings.billing.path
											}
										>
											<Billing />
										</Route>
										<Route
											exact
											path={
												hubRoutes.settings.activity.root
													.path
											}
										>
											<GameSettings />
										</Route>
										<Route
											exact
											path={
												hubRoutes.settings.activity
													.trivia.path
											}
										>
											<TriviaCustomizer />
										</Route>
										<Route>
											<PageNotFound />
										</Route>
									</Switch>
								</AccountRolesRoute>
								<Route>
									<PageNotFound />
								</Route>
							</Switch>
						</HubLayout>
					</HasAccountRoute>
				</>
			}
			PageNotFound={HubPageNotFound}
			ScreenContainer={OnboardingLayout}
			Loading={HubLoading}
		/>
	);
};
