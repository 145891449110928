import { MenuItem } from '@material-ui/core';
import { ActionOpts } from './types';
import { ConfirmationDialogContent } from '../ConfirmationDialogContent';
import { useCurrentAccount } from '@common/contexts';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

export const useUnblockMember = ({
	member,
	closeDialogue,
	refreshMembers,
	showDialogueContent,
	showSuccessSnackBar,
}: ActionOpts) => {
	const { updateMemberRole } = useCurrentAccount();

	const handleUnblockMember = async () => {
		await updateMemberRole(member.id, 'member');
		refreshMembers();
		closeDialogue();
		showSuccessSnackBar(`${member.displayName} has been unblocked`);
	};

	const DialogueContent = () => {
		const content = {
			title: 'Unblock this member',
			subTitle1: 'This member will be unblocked from your Team.',
			subTitle2:
				'If you change your mind, you can simply block this member again later.',
			btnText: 'UNBLOCK',
		};
		return (
			<ConfirmationDialogContent
				content={content}
				action={handleUnblockMember}
				handleClose={closeDialogue}
			/>
		);
	};

	const openDialog = () => {
		showDialogueContent(DialogueContent);
	};

	return (
		<MenuItem onClick={openDialog}>
			<PersonOutlineOutlinedIcon style={{ marginRight: 10 }} />
			Unblock member
		</MenuItem>
	);
};
