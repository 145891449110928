import {
	useBackendFunction,
	useConfigFlag,
	useCurrentUser,
	useQueryParams,
} from '@common/hooks';
import {
	isSuccess,
	RequestState,
	useCombineRequestState,
} from '@common/store-tools';
import { useHistory, useLocation } from 'react-router-dom';
import React from 'react';
import { env } from '@common/environment';
import { ensureExists } from '@common/utils';
import { routes } from '@common';
import { ZiagoGameType } from '@contracts/ziago-games/gameTypes';
import { useParams } from 'react-router';

type State = RequestState<undefined, { accountId: string }>;

type RouteParams = {
	gameType: ZiagoGameType;
};

export const useState = (): State => {
	const currentUser = useCurrentUser();
	const history = useHistory();
	const location = useLocation();
	const routeParams = useParams<RouteParams>();
	const gameType = routeParams.gameType;
	const queryParams = useQueryParams();
	const accountId = queryParams.accountId;
	const gameId = queryParams.gameId;
	const ziagoLatestApi = useConfigFlag('ziagoLatestApi').asBoolean();

	const [joinRequest] = useBackendFunction('ziagoGames-join', {
		callOnChange: () => {
			if (!accountId) {
				return;
			}

			return {
				accountId,
			};
		},
	});

	const state = useCombineRequestState(
		{
			join: joinRequest,
		},
		{
			accountId,
		},
	);

	React.useEffect(() => {
		if (currentUser.isLoaded && !currentUser.isAuthenticated) {
			history.replace(
				routes.onboard({ preserveContinuePath: true, location }),
			);
		}
	}, [currentUser.isAuthenticated, currentUser.isLoaded, history, location]);

	React.useEffect(() => {
		if (!accountId || !gameType || !gameId) {
			history.replace(routes.home({ location }));
		}
	}, [accountId, gameId, history, location, gameType]);

	React.useEffect(() => {
		if (isSuccess(joinRequest)) {
			const url = new URL(
				`${env().ziagoUrl}/play/${ensureExists(gameType)}`,
			);

			/**
			 * @todo remove the old params backwards compatability when latest
			 * Ziago API is in production
			 * */

			// new API params
			url.searchParams.set('gameId', ensureExists(gameId));
			url.searchParams.set('uid', ensureExists(currentUser.uid));
			url.searchParams.set('accountId', ensureExists(accountId));
			currentUser.displayName &&
				url.searchParams.set('displayName', currentUser.displayName);

			if (!ziagoLatestApi) {
				// old API params
				url.searchParams.set('game', ensureExists(gameId));
				url.searchParams.set('user', ensureExists(currentUser.uid));
				url.searchParams.set('account', ensureExists(accountId));
				currentUser.displayName &&
					url.searchParams.set('name', currentUser.displayName);
			}

			window.location.href = url.toString();
		}
	}, [
		accountId,
		currentUser.displayName,
		currentUser.uid,
		gameId,
		gameType,
		history,
		joinRequest,
		ziagoLatestApi,
	]);

	return state;
};
