import React from 'react';
import {
	Grid,
	Typography,
	makeStyles,
	IconButton,
	Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingButton } from '@common';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	cancelButton: {
		color: '#BDBDBD',
	},
	gridSpacing: {
		padding: theme.spacing(1),
	},
	closeButton: {
		position: 'absolute',
		top: 15,
		right: 15,
		padding: 0,
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

export function ConfirmationDialogContent({ content, action, handleClose }) {
	const classes = useStyles();

	const [loading, setLoading] = React.useState(false);

	const handleAction = () => {
		setLoading(true);
		Promise.resolve(action()).finally(() => {
			setLoading(false);
		});
	};

	return (
		<>
			{content && (
				<Grid container direction="column" className={classes.root}>
					<IconButton
						onClick={handleClose}
						className={classes.closeButton}
					>
						<CloseIcon />
					</IconButton>
					<Grid className={classes.gridSpacing}>
						<Typography variant="h5">{content.title}</Typography>
					</Grid>
					<Grid
						container
						direction="column"
						className={classes.subitleSection}
					>
						{content.subTitle1 && (
							<Grid item className={classes.gridSpacing}>
								<Typography>{content.subTitle1}</Typography>
							</Grid>
						)}
						<Grid item className={classes.gridSpacing}>
							<Typography>{content.subTitle2}</Typography>
						</Grid>
					</Grid>
					<Grid>
						<Grid
							container
							justifyContent="flex-end"
							direction="row"
							className={classes.gridSpacing}
						>
							<Grid item xs={3}>
								<Grid
									container
									justifyContent="center"
									alignItems="center"
									direction="row"
									style={{ height: '100%' }}
								>
									<Button
										onClick={handleClose}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
								</Grid>
							</Grid>
							<Grid item xs={3}>
								<LoadingButton
									loading={loading}
									variant="contained"
									disableElevation
									onClick={handleAction}
								>
									{content.btnText}
								</LoadingButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
}
