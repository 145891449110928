import { ConnectCollections } from '@contracts/connect';
import { FlatSchema } from '@contracts/shared';
import {
	combineReducers,
	makeFirebaseReducer,
	makeFirestoreReducer,
} from '@common/store-tools';

export const reducer = combineReducers({
	firebase: makeFirebaseReducer(),
	firestore: makeFirestoreReducer<FlatSchema<ConnectCollections>>(),
});
