import { Avatar, makeStyles, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	paper: {
		borderRadius: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		height: `100%`,
	},
	avatarContainer: {
		position: 'relative',
		padding: theme.spacing(0, 2, 0, 2),
		marginTop: theme.spacing(2),
	},
	avatar: {
		width: `100%`,
		height: `100%`,
		borderRadius: theme.spacing(1),
		[`& .MuiAvatar-fallback`]: {
			width: `100%`,
			height: `100%`,
		},
	},
	textContainer: {
		padding: theme.spacing(1),
	},
	name: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		marginBottom: theme.spacing(0.5),
	},
	jobTitle: {
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
}));

export const AccountMemberPlaceholder: React.ComponentType = () => {
	const styles = useStyles();
	return (
		<Paper className={styles.paper}>
			<div className={styles.avatarContainer}>
				<Skeleton className={styles.avatar} variant="rect">
					<Avatar variant="rounded" className={styles.avatar} />
				</Skeleton>
			</div>
			<div className={styles.textContainer}>
				<Typography
					variant="body1"
					align="center"
					className={styles.name}
				>
					<Skeleton />
				</Typography>
				<>
					<Typography
						variant="caption"
						color="textSecondary"
						align="center"
						className={styles.jobTitle}
					>
						<Skeleton />
						<Skeleton />
					</Typography>
				</>
			</div>
		</Paper>
	);
};
