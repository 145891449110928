import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
	Container,
	Divider,
	Paper,
	Theme,
	Typography,
} from '@material-ui/core';
import { DocumentHead } from '@common/components';
import { DeleteAccount } from './deleteAccount';
import { EditName } from './editName';

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		padding: theme.spacing(3),
		borderRadius: '1.25rem',

		[theme.breakpoints.down('sm')]: {
			padding: 0,
			boxShadow: 'none',
			background: 'transparent',
		},
	},
	heading: {
		paddingBottom: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
	},
	divider: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
}));

export const GeneralSettings: React.ComponentType = () => {
	const styles = useStyles();

	return (
		<>
			<DocumentHead title="Team Settings" />
			<Container maxWidth="md">
				<Typography variant="h2" className={styles.heading}>
					Team Settings
				</Typography>
				<Paper elevation={5} className={styles.paper}>
					<EditName />
					<Divider className={styles.divider} />
					<DeleteAccount />
				</Paper>
			</Container>
		</>
	);
};
