import React from 'react';
import { registerError } from '@common/errors';
import { useAuth, useCurrentUser } from '@common/hooks';
import {
	Box,
	makeStyles,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@common/components';

const useStyles = makeStyles((theme: Theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: theme.spacing(1.5),
		marginTop: theme.spacing(2),
		width: '100%',

		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
		},
	},
	inputs: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1.5),
		alignItems: 'center',

		[theme.breakpoints.up('sm')]: {
			width: '50%',
		},
	},
	actions: {
		[theme.breakpoints.up('sm')]: {
			alignSelf: 'flex-end',
		},
	},
	button: {
		[theme.breakpoints.up('sm')]: {
			width: '170px',
		},
	},
}));

export const UpdatePassword = (): React.ReactElement => {
	const styles = useStyles();
	const user = useCurrentUser();
	const { updatePassword, reauthenticate } = useAuth();
	const [isPending, setIsPending] = React.useState(false);

	const onSubmit = React.useCallback(
		async ({
			currentPassword,
			newPassword,
		}: {
			currentPassword: string;
			newPassword: string;
		}) => {
			setIsPending(true);
			try {
				await reauthenticate(user.email, currentPassword);
				await updatePassword(newPassword);
			} catch (err) {
				registerError(err);
			} finally {
				setIsPending(false);
			}
		},
		[reauthenticate, updatePassword, user.email],
	);

	return (
		<Box>
			<Typography variant="h5" gutterBottom>
				Update Password
			</Typography>

			<Formik
				enableReinitialize
				initialValues={{
					currentPassword: '',
					newPassword: '',
				}}
				onSubmit={onSubmit}
				submitting={false}
				validateOnMount={false}
				validationSchema={Yup.object().shape({
					currentPassword: Yup.string()
						.min(6)
						.required('Current password is a required field'),
					newPassword: Yup.string()
						.min(6)
						.required('New password is a required field'),
				})}
			>
				{({
					dirty,
					isValid,
					values,
					errors,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => (
					<Form
						onSubmit={(event) => {
							event.preventDefault();
							handleSubmit();
						}}
					>
						<div className={styles.form}>
							<div className={styles.inputs}>
								<TextField
									type="password"
									fullWidth
									variant="outlined"
									aria-label="Current Password"
									placeholder="Current password"
									name="currentPassword"
									value={values.currentPassword}
									onChange={handleChange}
									onBlur={handleBlur}
									helperText={errors.currentPassword}
									error={Boolean(errors.currentPassword)}
								/>
								<TextField
									type="password"
									fullWidth
									variant="outlined"
									aria-label="New Password"
									placeholder="New password"
									name="newPassword"
									value={values.newPassword}
									onChange={handleChange}
									onBlur={handleBlur}
									helperText={errors.newPassword}
									error={Boolean(errors.newPassword)}
								/>
							</div>
							<div className={styles.actions}>
								<LoadingButton
									className={styles.button}
									type="submit"
									loading={isPending}
									variant="contained"
									color="primary"
									fullWidth
									disabled={!dirty || !isValid}
								>
									Save
								</LoadingButton>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
