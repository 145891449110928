import {
	Checkbox,
	FormControlLabel,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { CopyText } from '@common/components';
import { ReactComponent as MemberProfileEmpty } from '../../img/member-profile-empty.svg';
import React from 'react';
import { useBackendFunction } from '@common/hooks';
import { env } from '@common/environment';
import { hubRoutes } from '@common/routes/hubRoutes';
import { Location } from 'history';
import { ensureExists } from '@common/utils/preconditions';
import {
	fullPathFromLocation,
	useCurrentAccount,
} from '@remote-social/common/src';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
}));

const createInviteLink = (params: {
	checked: boolean;
	inviteId: string;
	accountId: string;
	location: Location<unknown>;
}) => {
	const url = new URL(
		`${env().hubUrl}${fullPathFromLocation(
			hubRoutes.handleInvite({
				inviteId: params.inviteId,
				continuePath: params.checked
					? ensureExists(
							hubRoutes.accounts.createMemberProfile({
								accountId: params.accountId,
								location: params.location,
							}).pathname,
					  )
					: hubRoutes.home.path,
				location: params.location,
			}),
		)}`,
	);
	return url.toString();
};

const useComponentState = () => {
	const { currentAccountId } = useCurrentAccount();
	const accountId = ensureExists(currentAccountId);
	const location = useLocation();
	const [checked, setChecked] = React.useState(false);
	const [permanentInviteRequest] = useBackendFunction(
		'accountInvites-getPermanent',
		{
			callOnChange: () => {
				return {
					accountId,
				};
			},
			initialValue: undefined,
		},
	);

	const permanentInviteLink =
		permanentInviteRequest.status === 'success'
			? createInviteLink({
					checked,
					accountId,
					inviteId: permanentInviteRequest.data.inviteId,
					location,
			  })
			: undefined;

	return {
		permanentInviteLink,
		permanentInviteRequest,
		checked,
		setChecked,
	};
};

export const InviteMembersDialogueContent: React.ComponentType<{}> = () => {
	const styles = useStyles();
	const { permanentInviteLink, checked, setChecked } = useComponentState();
	return (
		<div className={styles.root}>
			<MemberProfileEmpty />
			<Typography variant="body1">
				People will be added to your team.
			</Typography>
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={() => setChecked((checked) => !checked)}
						/>
					}
					label="Member to create a profile after joining?"
				/>
			</div>
			<CopyText text={permanentInviteLink} />
		</div>
	);
};
