import { Loading, PageError, PageNotFound } from '@common';
import { useState } from './state';
import React from 'react';
import { MemberProfilePageLayout } from './layout';

type Props = {};

export const MemberProfile = () => {
	return <MemberProfileLoader />;
};

const MemberProfileLoader: React.ComponentType<Props> = () => {
	const pageRequest = useState();

	if (pageRequest.status === 'error') {
		return <PageError error={pageRequest.error} />;
	}

	if (pageRequest.status === 'not-a-member') {
		return <PageNotFound />;
	}

	if (pageRequest.status !== 'success') {
		return <Loading showRandomLabels={true} />;
	}

	return <MemberProfilePageLayout {...pageRequest.data} />;
};
