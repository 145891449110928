import {
	Box,
	makeStyles,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import React from 'react';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@common/components';
import { useCurrentAccount } from '@common/contexts';
import * as Yup from 'yup';
import { ensureExists } from '@common/utils';
import {
	isPending,
	isSuccess,
	RequestStateNoPayload,
} from '@common/store-tools';
import { useBackendFunction } from '@common/hooks';

const useStyles = makeStyles((theme: Theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: theme.spacing(1.5),
		marginTop: theme.spacing(2),
		width: '100%',

		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
		},
	},
	inputs: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1.5),
		alignItems: 'center',

		[theme.breakpoints.up('sm')]: {
			width: '50%',
		},
	},
	actions: {
		[theme.breakpoints.up('sm')]: {
			alignSelf: 'flex-end',
		},
	},
	button: {
		[theme.breakpoints.up('sm')]: {
			width: '170px',
		},
	},
}));

type State = {
	name: string;
	updateAccount: (values: { name: string }) => void;
	updateAccountRequest: RequestStateNoPayload;
};

const useState = (): State => {
	const { currentAccount, currentAccountId, refresh } = useCurrentAccount();

	const [updateAccountRequest, accountUpdate] =
		useBackendFunction('account-update');

	const success = isSuccess(updateAccountRequest);

	const updateAccount = React.useCallback(
		async (values: { name: string }) => {
			accountUpdate({
				accountId: ensureExists(currentAccountId),
				name: values.name,
			});
		},
		[accountUpdate, currentAccountId],
	);

	React.useEffect(() => {
		if (success) {
			refresh();
		}
	}, [success, refresh]);

	return {
		name: ensureExists(currentAccount).name || '',
		updateAccount,
		updateAccountRequest,
	};
};

export const EditName: React.ComponentType = () => {
	const styles = useStyles();
	const { name, updateAccount, updateAccountRequest } = useState();

	return (
		<Box>
			<Typography variant="h5" gutterBottom>
				Team Name
			</Typography>

			<Formik
				enableReinitialize
				initialValues={{
					name,
				}}
				onSubmit={updateAccount}
				submitting={false}
				validateOnMount
				validationSchema={Yup.object().shape({
					name: Yup.string()
						.min(3, 'Team name cannot be shorter than 3 characters')
						.max(
							50,
							'Team name cannot be longer than 50 characters',
						)
						.required('Team name is required'),
				})}
			>
				{({
					dirty,
					isValid,
					values,
					errors,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => (
					<Form
						onSubmit={(event) => {
							event.preventDefault();
							handleSubmit();
						}}
					>
						<div className={styles.form}>
							<div className={styles.inputs}>
								<TextField
									name="name"
									fullWidth
									placeholder="My Team"
									aria-label="Team name"
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									variant="outlined"
									helperText={errors.name}
									error={Boolean(errors.name)}
								/>
							</div>
							<div className={styles.actions}>
								<LoadingButton
									className={styles.button}
									type="submit"
									loading={isPending(updateAccountRequest)}
									variant="contained"
									color="primary"
									fullWidth
									disabled={!dirty || !isValid}
								>
									Save
								</LoadingButton>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
