import { env } from '@common/environment/envConfig';
import { buildCrossDomainUrl } from '@common/routes/useCrossDomainUrl';
import { hubRoutes } from '@common/routes/hubRoutes';

type Banner = {
	name: string;
	images: {
		xs: string;
		md: string;
	};
	priority: number;
	newTab?: boolean;
	href?: string;
};

type BannerConfig = {
	admin: Banner[];
	member: Banner[];
	all: Banner[];
};

export const banners = (accountId?: string): BannerConfig => {
	const envConfig = env();
	const connectUrl = buildCrossDomainUrl({
		boundToOrigin: envConfig.connectUrl,
		accountId,
	});
	const checkinUrl = buildCrossDomainUrl({
		boundToOrigin: envConfig.checkinUrl,
		accountId,
	});

	return {
		admin: [
			{
				name: 'Remote Social Connect',
				images: {
					xs: '2021-10-20-rs-connect-xs.png',
					md: '2021-10-20-rs-connect-md.png',
				},
				priority: 1,
				href: connectUrl,
				newTab: true,
			},
			{
				name: 'Custom Trivia for Admins',
				images: {
					xs: '2021-07-custom-trivia-admin-xs.png',
					md: '2021-07-custom-trivia-admin-md.png',
				},
				priority: 1,
				href: `${hubRoutes.settings.activity.root.path}?activity=Trivia`,
			},
		],
		member: [
			{
				name: 'Create your own team',
				images: {
					xs: '2021-07-02-create-circle-xs.png',
					md: '2021-07-02-create-circle-md.png',
				},
				priority: 1,
				href: '/create-account',
			},
			{
				name: 'Custom Trivia for Members',
				images: {
					xs: '2021-07-02-custom-trivia-member-xs.png',
					md: '2021-07-02-custom-trivia-member-md.png',
				},
				priority: 1,
				href: '/create-account',
			},
		],
		all: [
			{
				name: 'Remote Social Check-in',
				images: {
					xs: '2022-02-02-rs-check-in-xs.png',
					md: '2022-02-02-rs-check-in-md.png',
				},
				priority: 0,
				href: checkinUrl,
				newTab: true,
			},
		],
	};
};
