import React from 'react';
import { DocumentHead, Loading, PageError } from '../../components';
import { AccountOwnerPaywallContent } from '../components/accountOwnerPaywallContent';
import { AccountMemberPaywallContent } from '../components/accountMemberPaywallContent';
import { useState } from './state';

export const Subscribe: React.ComponentType = () => {
	const state = useState();

	if (state.status === 'error' && state.error) {
		return <PageError error={state.error} />;
	}

	if (state.status !== 'success') {
		return <Loading showRandomLabels />;
	}

	const { isOwner } = state.data;

	return (
		<>
			<DocumentHead title="Subscribe" />
			{isOwner ? (
				<AccountOwnerPaywallContent />
			) : (
				<AccountMemberPaywallContent />
			)}
		</>
	);
};
