import { useHistory, useLocation } from 'react-router';
import { useQueryParams } from '../../hooks';
import { useCurrentAccount } from '../../contexts/useCurrentAccount';
import { RequestState } from '../../store-tools';
import { routes } from '../../routes';
import React from 'react';
import { useTimeoutState } from '../../store-tools/useTimeoutState';
import { registerError } from '../../errors';

type State = RequestState<void>;

export const useState = (): State => {
	const params = useQueryParams();
	const location = useLocation();
	const history = useHistory();
	const { currentAccount } = useCurrentAccount();

	const continuePath = params.continuePath || routes.home({ location });
	const isProTier = Boolean(currentAccount && currentAccount.tier === 'pro');

	const { timeoutError } = useTimeoutState({
		milliseconds: 15000,
		errorMessage: () =>
			'Timed out waiting for account to be updated with billing subscription information',
		disabledWhen: isProTier,
	});

	React.useEffect(() => {
		if (timeoutError) {
			registerError(timeoutError, {
				contexts: {
					account: {
						accountId: params.accountId,
					},
				},
			});
		}
	}, [params.accountId, timeoutError]);

	React.useEffect(() => {
		if (isProTier) {
			history.replace(continuePath);
		}
	}, [continuePath, history, isProTier]);

	if (timeoutError) {
		return {
			status: 'error',
			error: timeoutError,
		};
	} else if (!isProTier) {
		return {
			status: 'pending',
		};
	} else {
		return {
			status: 'success',
		};
	}
};
