import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useConfigFlag } from '../../hooks';
import { useCurrentAccount } from '../../contexts';
import { ensureExists } from '../../utils';
import { routes } from '../routes';
import { HasAccountRoute } from './hasAccountRoute';

const ProPageRouteInternal: React.ComponentType<RouteProps> = (props) => {
	const { currentAccount } = useCurrentAccount();
	const location = useLocation();

	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();

	if (billingEnabled && ensureExists(currentAccount).tier !== 'pro') {
		return (
			<Redirect
				path={location.pathname}
				to={routes.subscribe({
					preserveContinuePath: true,
					location,
					intent: 'paywall',
				})}
			/>
		);
	}

	return <Route {...props} />;
};

export const ProPageRoute: React.ComponentType<RouteProps> = ({
	children,
	...rest
}) => {
	return (
		<HasAccountRoute {...rest} shouldUpsell={false}>
			<ProPageRouteInternal>{children}</ProPageRouteInternal>
		</HasAccountRoute>
	);
};
