import React from 'react';
import {
	Box,
	Button,
	Dialog,
	IconButton,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Showcase } from './Showcase';
import { checkinSettings } from './SliderSettings';
import { env } from '@common/environment';
import { ProChip } from '@common/components/proChip';
import { Card } from './card';
import { useCurrentAccount } from '@common/contexts';
import { buildCrossDomainUrl } from '@common/routes/useCrossDomainUrl';

const useStyles = makeStyles((theme) => ({
	card: {
		height: '290px',
		paddingBottom: '1rem',
	},
	border: {
		borderRadius: '1.5rem',
	},
	modalPaper: {
		padding: 10,
		maxWidth: 800,
	},
	closeButton: {
		position: 'absolute',
		top: 15,
		right: 15,
		padding: 0,
	},
	modalWrapper: {
		position: 'relative',
		minWidth: '100%',
		display: 'grid',
		gridTemplateAreas: '"illustration" "buttons" "content"',
		gridTemplateColumns: '1fr',
		gridTemplateRows: '1fr auto auto',
		gridGap: theme.spacing(3),
		padding: theme.spacing(2),
		paddingRight: theme.spacing(1.5),

		[theme.breakpoints.up('md')]: {
			gridTemplateAreas: '"illustration content" "illustration buttons"',
			gridTemplateColumns: '1fr 1fr',
			gridTemplateRows: '1fr',
		},
	},
	illustrationContainer: {
		gridArea: 'illustration',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	illustration: {
		width: '100%',
		height: '100%',

		[theme.breakpoints.up('sm')]: {
			width: 400,
			height: 400,
		},
	},
	content: {
		gridArea: 'content',
	},
	title: {
		fontWeight: 900,
		textAlign: 'center',
		marginBottom: theme.spacing(1),

		[theme.breakpoints.up('md')]: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(2),
		},
	},
	pointText: {
		fontSize: '0.875rem',
		marginBottom: theme.spacing(1),
	},
	buttons: {
		gridArea: 'buttons',
	},
}));

const IntroModal = ({ open, onClose, pack, ...rest }) => {
	const styles = useStyles();
	const { currentAccountId } = useCurrentAccount();
	const primaryLink = pack?.links?.find((link) => link.rel === 'primary');

	const url =
		primaryLink &&
		buildCrossDomainUrl({
			boundToOrigin: env().checkinUrl,
			location: primaryLink,
			accountId: currentAccountId,
		});

	return (
		<Dialog
			open={open}
			onClose={onClose}
			{...rest}
			PaperProps={{
				className: `${styles.border} ${styles.modalPaper}`,
			}}
		>
			<Box className={styles.modalWrapper}>
				<Box className={styles.illustrationContainer}>
					<video
						className={`${styles.border} ${styles.illustration}`}
						src={pack.introduction?.explainerVideoUrl}
						title={pack.name}
						playsInline={true}
						loop={true}
						controls={false}
						autoPlay={true}
						muted
					/>
				</Box>
				<Box className={styles.content}>
					<Typography variant="h4" className={styles.title}>
						{pack.name} <ProChip />
					</Typography>
					{pack.introduction?.points?.map((point, index) => (
						<Typography key={index} className={styles.pointText}>
							{point}
						</Typography>
					))}
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					className={styles.buttons}
				>
					{url && (
						<Button variant="contained" color="default" href={url}>
							{primaryLink.label}
						</Button>
					)}
				</Box>
			</Box>
			<IconButton
				onClick={onClose}
				aria-label="close"
				className={styles.closeButton}
			>
				<CloseIcon />
			</IconButton>
		</Dialog>
	);
};

const CheckinCard = ({ pack, onSelect }) => {
	const styles = useStyles();
	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.up('md')) ? 'md' : 'xs';
	const checkinTile = pack.tiles[breakpoint];
	const { currentAccountId } = useCurrentAccount();
	const url =
		pack.href &&
		buildCrossDomainUrl({
			boundToOrigin: env().checkinUrl,
			location: pack.href,
			accountId: currentAccountId,
		});

	return (
		<Card
			className={styles.card}
			avatarUrl={checkinTile}
			avatarAlt={pack.avatarAlt}
			onSelect={() => onSelect(pack)}
			href={url}
		/>
	);
};

const CheckinShowcase = ({ checkinData }) => {
	const [intro, setIntro] = React.useState({
		isOpen: false,
		pack: {},
	});

	const _onSelect = React.useCallback(
		(pack) => {
			setIntro({
				isOpen: true,
				pack: pack,
			});
		},
		[setIntro],
	);

	const _onDeselect = React.useCallback(() => {
		setIntro({
			isOpen: false,
			pack: {},
		});
	}, [setIntro]);

	const selectedPack = checkinData?.find((pack) => pack.id === intro.pack.id);

	return (
		<>
			<Showcase
				title="Check-in"
				titleProBadge
				subtitle="Use Check-in to align your team and boost engagement"
				sliderSetting={checkinSettings}
			>
				{checkinData.map((pack, index) => {
					return (
						<div key={index}>
							<CheckinCard pack={pack} onSelect={_onSelect} />
						</div>
					);
				})}
			</Showcase>
			{selectedPack && (
				<IntroModal
					open={intro.isOpen}
					onClose={_onDeselect}
					pack={intro.pack}
					aria-labelledby="checkin-introduction"
					maxWidth={false}
				/>
			)}
		</>
	);
};

export { CheckinShowcase };
