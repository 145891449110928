import React from 'react';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';
import { routes } from '../routes';
import { isAllowed } from '../user-land-routes/isAllowedRoute';
import { isLogoutContinueParameter } from '../isLogoutContinueParameter';
import { continuePathFromLocation } from '../continuePathFromLocation';

export const useRedirectToContinueForLoginRoutes = ({
	unauthenticatedRoutes,
}: {
	unauthenticatedRoutes: React.ReactElement[];
}) => {
	const location = useLocation<{ from?: string } | undefined>();
	const continuePath =
		continuePathFromLocation(location) || routes.home({ location });
	const redirectToHomeForLoginRoutes = React.useMemo(() => {
		const createRedirect = ({ path }: { path: string }) => (
			<Redirect from={path} to={continuePath} />
		);
		return [
			// in case we have made a mistake and trying to
			// redirect from /login to /logout immediately -
			// override this by safely redirecting to home
			// otherwise we will be loggin in and out infinitely
			// without being able to visit other pages
			...(isLogoutContinueParameter(continuePath)
				? [<Redirect from="/login" to="/" />]
				: []),
			...unauthenticatedRoutes
				.filter(
					(element) =>
						React.isValidElement<RouteProps>(element) &&
						isAllowed(element) &&
						element.props.path,
				)
				.flatMap(({ props }: { props: RouteProps }) => {
					if (!props.path) {
						return [];
					}
					return typeof props.path === 'string'
						? [createRedirect({ path: props.path })]
						: props.path.map((path) => createRedirect({ path }));
				}),
		];
	}, [continuePath, unauthenticatedRoutes]);
	return redirectToHomeForLoginRoutes;
};
