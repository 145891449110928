import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Loading } from '../../components';
import { useCurrentUser } from '../../hooks';
import { routes } from '../routes';
import React from 'react';

export const VerifyEmailRoute: React.ComponentType<
	RouteProps & {
		Loading?: React.ComponentType;
	}
> = (props) => {
	const user = useCurrentUser();
	const location = useLocation();
	const LoadingProp = props.Loading || Loading;

	if (!user.isLoaded) {
		return <LoadingProp />;
	}

	const isAnonymousOrNoEmail = !user.email || user.isAnonymous;
	const needsEmailVerification = user.email && !user.emailVerified;

	if (isAnonymousOrNoEmail) {
		return (
			<Redirect
				path={location.pathname}
				to={routes.upgrade({
					preserveContinuePath: true,
					location,
				})}
			/>
		);
	}

	if (needsEmailVerification) {
		return (
			<Redirect
				path={location.pathname}
				to={routes.verifyEmail({
					preserveContinuePath: true,
					location,
				})}
			/>
		);
	}

	return <Route {...props} />;
};
