import React from 'react';
import { Member } from '@contracts/member-profile';
import { makeStyles } from '@material-ui/core';
import { AccountMember } from '../account-member/accountMember';
import {
	isInitial,
	isPending,
	isSuccess,
	RequestState,
} from '@common/store-tools';
import Measure, { ContentRect } from 'react-measure';
import { AccountMemberPlaceholder } from '../account-member/placeholder';

const useStyles = makeStyles((theme) => ({
	container: {
		gap: theme.spacing(2),

		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, minmax(6rem, 1fr))`,
		gridAutoRows: `1fr`,
	},
}));

type Props = {
	membersRequest: RequestState<Member[], Member[]>;
	onCellSizeMeasured?: (opts: { width: number; height: number }) => void;
};

export const AccountMembersGrid: React.ComponentType<Props> = ({
	membersRequest,
	onCellSizeMeasured,
}) => {
	const styles = useStyles();

	const onCellResize = React.useCallback(
		(contentRect: ContentRect) => {
			if (!contentRect.bounds || !onCellSizeMeasured) {
				return;
			}
			onCellSizeMeasured({
				width: contentRect.bounds.width,
				height: contentRect.bounds.height,
			});
		},
		[onCellSizeMeasured],
	);

	return (
		<div className={styles.container}>
			{(isInitial(membersRequest) || isPending(membersRequest)) && (
				<>
					{onCellSizeMeasured ? (
						<Measure onResize={onCellResize} bounds>
							{({ measureRef }) => {
								return (
									<div ref={measureRef}>
										<AccountMemberPlaceholder />
									</div>
								);
							}}
						</Measure>
					) : (
						<AccountMemberPlaceholder />
					)}
					<AccountMemberPlaceholder />
					<AccountMemberPlaceholder />
					<AccountMemberPlaceholder />
					<AccountMemberPlaceholder />
					<AccountMemberPlaceholder />
					<AccountMemberPlaceholder />
					<AccountMemberPlaceholder />
					<AccountMemberPlaceholder />
					{/*
					    TODO: When we know how many members a team has
							before loading all members we will be able to show
							the right amount of placeholders to avoid layout
							shifts
					 */}
				</>
			)}
			{isSuccess(membersRequest) &&
				membersRequest.data.map((member, index) =>
					index === 0 ? (
						<Measure key="first" onResize={onCellResize} bounds>
							{({ measureRef }) => {
								return (
									<div ref={measureRef}>
										<AccountMember member={member} />
									</div>
								);
							}}
						</Measure>
					) : (
						<AccountMember key={member.uid} member={member} />
					),
				)}
		</div>
	);
};
