import React from 'react';
import { Container, Link, makeStyles, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ProChip } from '@common/components/proChip';

const useStyles = makeStyles((theme) => ({
	showcase: {
		position: 'relative',
	},
	emptyMessage: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		marginTop: theme.spacing(6),
	},
	arrowsContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(1),
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},
	arrows: {
		cursor: 'pointer',
		background: '#D9d9d9',
		borderRadius: 10,
		height: '1.7rem',
		'&:hover': {
			background: '#c0c0c0',
		},
	},
	sliderContainer: {
		marginLeft: theme.spacing(-1),
		marginRight: theme.spacing(-1),
		marginBottom: theme.spacing(-3),
	},
	showcaseHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}));

export const Showcase = ({
	title,
	titleProBadge = false,
	subtitle,
	filterBar,
	className,
	browseAll = false,
	emptyMessage,
	sliderSetting,
	children,
}) => {
	const classes = useStyles();
	let sliderRef = React.useRef();

	const prev = React.useCallback(() => {
		sliderRef.current.slickPrev();
	}, []);
	const next = React.useCallback(() => {
		sliderRef.current.slickNext();
	}, []);

	return (
		<Container maxWidth="lg" fixed style={{ padding: 0 }}>
			<div className={clsx(className, classes.showcase)}>
				<div className={classes.showcaseHeader}>
					<div>
						<Typography variant="h2">
							{title}
							{titleProBadge && (
								<>
									{' '}
									<ProChip />
								</>
							)}
						</Typography>
						<Typography variant="body1">{subtitle}</Typography>
					</div>
					<div className={classes.arrowsContainer}>
						{browseAll ? (
							<Link
								href="https://experiences.remotesocial.io/s"
								target="_blank"
								rel="noopener noreferrer"
								className={classes.browseAll}
							>
								Browse All
							</Link>
						) : null}
						<ChevronLeftIcon
							className={classes.arrows}
							onClick={prev}
						/>
						<ChevronRightIcon
							className={classes.arrows}
							onClick={next}
						/>
					</div>
				</div>
				{React.Children.count(children) ? (
					<div className={classes.sliderContainer}>
						<Slider ref={sliderRef} {...sliderSetting}>
							{children}
						</Slider>
					</div>
				) : (
					<div className={classes.emptyMessage}>{emptyMessage}</div>
				)}
			</div>
		</Container>
	);
};
