import { Link, LinkProps, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

type Props = LinkProps<RouterLink> & {
	current?: boolean;
};

export const BreadcrumbSegment: React.ComponentType<Props> = ({
	current,
	...rest
}) =>
	current ? (
		<Typography color="textPrimary">{rest.children}</Typography>
	) : (
		<Link color="primary" component={RouterLink} {...rest}>
			{rest.children}
		</Link>
	);
