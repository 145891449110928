import { ProfileField } from '@contracts/member-profile';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { ProfileEditState } from './editState';
import { FieldEditor } from './fieldEditor';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gap: theme.spacing(4),

		padding: theme.spacing(2, 0),
	},
	field: {
		gridColumn: 1,
	},
}));

type Props = Pick<ProfileEditState, 'useFieldValue'> & {
	fields: ProfileField[];
};

export const EditProfileFieldList: React.ComponentType<Props> = React.memo(
	(props) => {
		const styles = useStyles();
		const { fields, useFieldValue } = props;
		return (
			<div className={styles.root}>
				{fields.map((field) => (
					<div key={field.profileFieldId} className={styles.field}>
						<FieldEditor
							field={field}
							useFieldValue={useFieldValue}
						/>
					</div>
				))}
			</div>
		);
	},
);
