import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((_theme) => {
	return createStyles({
		value: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		wordBreakAll: {
			wordBreak: 'break-all',
		},
	});
});

type Props = {
	label: string;
	value?: React.ReactNode;
	wordBreakAll?: boolean;
};

export const AboutField: React.ComponentType<Props> = ({
	label,
	value,
	wordBreakAll,
}) => {
	const styles = useStyles();
	return value ? (
		<div>
			<Typography color="textSecondary" variant="caption">
				{label}
			</Typography>
			<Typography
				variant="body2"
				className={clsx(styles.value, {
					[styles.wordBreakAll]: wordBreakAll ?? false,
				})}
				component="div"
			>
				{value}
			</Typography>
		</div>
	) : null;
};
