export type Cta = {
	name: string;
	type: string;
	gameId?: string;
	images: {
		xs: string;
		md: string;
	};
	isPro: boolean;
};

export type Ctas = { admin: Cta[]; member: Cta[]; all: Cta[] };

export const ctas: Ctas = {
	admin: [],
	member: [],
	all: [
		{
			name: 'Two Truths',
			type: 'game',
			gameId: 'z_6_2truths',
			images: {
				xs: '2022-02-two-truths-xs.png',
				md: '2022-02-two-truths-md.png',
			},
			isPro: false,
		},
		{
			name: 'Play Bingo',
			type: 'game',
			gameId: 'bingo',
			images: {
				xs: '2021-07-play-bingo-xs.png',
				md: '2021-07-play-bingo-md.png',
			},
			isPro: false,
		},
		{
			name: 'Schedule',
			type: 'schedule',
			images: {
				xs: '2021-07-schedule-session-xs.png',
				md: '2021-07-schedule-session-md.png',
			},
			isPro: false,
		},
	],
};
