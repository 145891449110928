import { Location } from 'history';
import { LaxContinueState } from './types';

/**
 * Get path to return to, Back behaviour
 */
export function returnPathFromLocation(
	location: Location<unknown> | Location<LaxContinueState>,
) {
	if (!location.state) {
		return;
	}

	const state = location.state as LaxContinueState;

	return state.returnPath;
}
