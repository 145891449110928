import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Loading } from '../../components/loading';
import { routes } from '../routes';
import { AccountRole } from '@contracts/auth';
import { useRolesCheck } from '../../hooks/useRolesCheck';

type Props = {
	roles: AccountRole[];
	Loading?: React.ComponentType;
};

export const AccountRolesRoute: React.ComponentType<RouteProps & Props> = ({
	roles,
	Loading: LoadingProp = Loading,
	...rest
}) => {
	const { status } = useRolesCheck({ roles });
	const location = useLocation();

	if (status === 'pending') {
		return <LoadingProp />;
	}

	if (status === 'not-allowed') {
		return (
			<Redirect from={location.pathname} to={routes.home({ location })} />
		);
	}

	return <Route {...rest} />;
};
