export const shuffle = <T>(array: T[]) => {
	let randomIndex;
	for (let index = array.length - 1; index > 0; index--) {
		randomIndex = Math.floor(Math.random() * index);
		[array[index], array[randomIndex]] = [
			array[randomIndex] as T,
			array[index] as T,
		];
	}
	return array;
};
