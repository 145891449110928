import { MenuItem } from '@material-ui/core';
import { ActionOpts } from './types';
import { ConfirmationDialogContent } from '../ConfirmationDialogContent';
import { useCurrentAccount } from '@common/contexts';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

export const useRemoveMember = ({
	member,
	closeDialogue,
	refreshMembers,
	showDialogueContent,
	showSuccessSnackBar,
}: ActionOpts) => {
	const { deleteAccountMember } = useCurrentAccount();

	const handleRemoveMember = async () => {
		await deleteAccountMember(member.id);
		refreshMembers();
		closeDialogue();
		showSuccessSnackBar(
			`${member.displayName} has been removed from your team`,
		);
	};

	const DialogueContent = () => {
		const content = {
			title: 'Remove this member',
			subTitle1:
				'This member will be removed from your Team. This is permanent.',
			subTitle2:
				'If you change your mind, you will need to re-invite them to join the Circle again.',
			btnText: 'REMOVE',
		};
		return (
			<ConfirmationDialogContent
				content={content}
				action={handleRemoveMember}
				handleClose={closeDialogue}
			/>
		);
	};

	const openDialog = () => {
		showDialogueContent(DialogueContent);
	};

	return (
		<MenuItem onClick={openDialog}>
			<RemoveCircleOutlineOutlinedIcon style={{ marginRight: 10 }} />
			Remove member
		</MenuItem>
	);
};
