import { MemberProfile } from '@contracts/member-profile';
import { Button, Paper, Typography } from '@material-ui/core';
import { ProfileAvatar } from './profileAvatar';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { paperPaddings, paperStyles } from './sharedStyles';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';

const paperHeight = 31;
const gradientHeight = 19;

const useStyles = makeStyles((theme) => {
	return createStyles({
		topHorizontalPaper: {
			backgroundImage: `radial-gradient(99.18% 2426.58% at 0.82% 4.3%, #D748A5 0%, #6012C4 100%);`,
			backgroundSize: `100% ${theme.spacing(gradientHeight)}`,
			backgroundRepeat: 'no-repeat',

			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.up('sm')]: {
				alignItems: 'flex-end',
				flexDirection: 'row',

				height: theme.spacing(paperHeight),
			},

			position: 'relative',

			...paperStyles(theme),
		},
		dividedByGradientLine: {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',

			width: `100%`,

			marginTop: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(2),
				// negative margin here to make the math of specifying
				// the `aboveGradientLine.height` easier
				marginTop: theme.spacing(-paperPaddings),
				marginBottom: theme.spacing(-paperPaddings),
			},
		},
		aboveGradientLine: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'end',
			[theme.breakpoints.up('sm')]: {
				height: theme.spacing(gradientHeight),
			},
		},
		belowGradientLine: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			flexWrap: 'wrap',

			width: `100%`,
			// if we ever need bottom alignment of the button
			// height: theme.spacing(paperHeight - gradientHeight),
		},
		displayNameAndPronounsContainer: {
			[theme.breakpoints.up('sm')]: {
				color: 'white',
			},
		},
		displayNameTruncate: {
			[theme.breakpoints.up('sm')]: {
				display: '-webkit-box',
				'-webkit-line-clamp': 2,
				'-webkit-box-orient': 'vertical',
				overflow: 'hidden',
			},
		},
		pronouns: {
			marginLeft: theme.spacing(1),
		},
		role: {
			[theme.breakpoints.up('sm')]: {
				marginTop: theme.spacing(1),
			},
		},
		truncate: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		buttonContainer: {
			alignSelf: 'flex-end',
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(3, 0),
			},
		},
	});
});

export const Head: React.ComponentType<{
	className: string;
	profile: MemberProfile;
	onEdit?: () => void;
}> = ({ className, profile, onEdit }) => {
	const styles = useStyles();
	return (
		<Paper
			className={clsx(styles.topHorizontalPaper, className)}
			elevation={5}
		>
			<ProfileAvatar member={profile.props} />
			<div className={styles.dividedByGradientLine}>
				<div className={styles.aboveGradientLine}>
					<div className={styles.displayNameAndPronounsContainer}>
						<div className={styles.displayNameTruncate}>
							<Typography variant="h2">
								{profile.props.displayName || 'Anonymous'}
								<Typography
									className={styles.pronouns}
									variant="body1"
									component="span"
								>
									{profile.props.pronouns && (
										<>({profile.props.pronouns})</>
									)}
								</Typography>
							</Typography>
						</div>
					</div>
				</div>
				<div className={styles.belowGradientLine}>
					<div>
						<div>
							<Typography variant="h4" className={styles.role}>
								{profile.props.jobTitle}
							</Typography>
						</div>
						<div>
							<Typography variant="body1">
								{profile.props.location}
							</Typography>
						</div>
					</div>
					<div className={styles.buttonContainer}>
						{onEdit && (
							<Button
								color="primary"
								variant="contained"
								size="small"
								startIcon={<EditIcon />}
								onClick={onEdit}
							>
								Edit Profile
							</Button>
						)}
					</div>
				</div>
			</div>
		</Paper>
	);
};
