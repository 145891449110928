import React from 'react';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';
import { isLogoutContinueParameter } from '../isLogoutContinueParameter';
import { routes } from '../routes';
import { isAllowed } from '../user-land-routes/isAllowedRoute';

export const useRedirectToOnboardForPrivateRoutes = ({
	privateRoutes,
}: {
	privateRoutes: React.ReactElement[];
}) => {
	const location = useLocation();

	const redirectToLoginForEveryPrivateRoute = React.useMemo(() => {
		const createRedirect = ({ path }: { path: string }) => (
			<Redirect
				from={path}
				to={
					// if our current location is the logout page
					// do not use that as continue url so we do not
					// end up infinitely logging in and out without being
					// able to visit any other pages
					isLogoutContinueParameter(location)
						? routes.onboard({
								location,
						  })
						: routes.onboard({
								preserveContinuePath: true,
								location,
						  })
				}
			/>
		);
		return privateRoutes
			.filter(
				(element) =>
					React.isValidElement<RouteProps>(element) &&
					isAllowed(element) &&
					element.props.path,
			)
			.flatMap(({ props }: { props: RouteProps }) => {
				if (!props.path) {
					return [];
				}
				return typeof props.path === 'string'
					? [createRedirect({ path: props.path })]
					: props.path.map((path) => createRedirect({ path }));
			});
	}, [location, privateRoutes]);
	return redirectToLoginForEveryPrivateRoute;
};
