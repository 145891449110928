import React from 'react';
import { useCurrentAccount } from '@common/contexts';
import { useBilling } from '@common/hooks/useBilling';
import {
	DocumentHead,
	fullPathFromLocation,
	Loading,
	PageError,
	useSessionStorage,
} from '@common';
import { hubRoutes } from '@common/routes/hubRoutes';
import { useHistory, useLocation } from 'react-router-dom';

export const Billing: React.ComponentType = () => {
	const history = useHistory();
	const location = useLocation();
	const { currentAccount, isLoaded } = useCurrentAccount();
	const { navigateToSubscriptionPortal, navigateToBillingPortal, error } =
		useBilling({
			// go back to billing again so we can restore the session variable state
			returnPath: fullPathFromLocation(
				hubRoutes.settings.billing({
					location,
				}),
			),
		});

	// This page needs to handle the browser Back button, and redirect to home
	// if we are coming from Billing. The way we understand we coming from billing
	// is stored in the session variable. This also means when people click Back
	// link in Stripe we also need to come back to the same Billing page so we can
	// properly restore state of this variable.
	const [isNavigatingToStripe, setIsNavigatingToStripe] = useSessionStorage(
		'isNavigatingToStripe',
		false,
	);

	const isNavigatingToStripeRef = React.useRef(isNavigatingToStripe);
	isNavigatingToStripeRef.current = isNavigatingToStripe;

	const isPro = currentAccount && currentAccount.tier === 'pro';

	React.useEffect(() => {
		if (!isLoaded) {
			return;
		}
		if (typeof isPro !== 'boolean') {
			return;
		}

		// handles user pressing 'back' button in stripe
		if (isNavigatingToStripeRef.current) {
			setIsNavigatingToStripe(false);

			history.push(hubRoutes.home.path);
		} else {
			setIsNavigatingToStripe(true);

			if (isPro) {
				navigateToBillingPortal();
			} else {
				navigateToSubscriptionPortal();
			}
		}
	}, [
		isPro,
		history,
		isLoaded,
		navigateToBillingPortal,
		navigateToSubscriptionPortal,
		setIsNavigatingToStripe,
	]);

	if (error) {
		return <PageError error={error} />;
	}

	return (
		<>
			<DocumentHead title="Billing" />
			<Loading showRandomLabels />
		</>
	);
};
