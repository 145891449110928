import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { CommonDialog } from '@remote-social/common/src';
import React from 'react';
import { InviteMembersDialogueContent } from './content';

export const useInviteMembersDialogue = () => {
	const [open, setOpen] = React.useState(false);

	const openDialogue = React.useCallback(() => {
		setOpen(true);
	}, [setOpen]);

	const closeDialogue = React.useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return {
		openDialogue,
		dialogueElement: (
			<InviteMembersDialogue open={open} onClose={closeDialogue} />
		),
	};
};

export const InviteMembersDialogue: React.ComponentType<{
	open: boolean;
	onClose: () => void;
}> = (props) => {
	return (
		<CommonDialog
			open={props.open}
			onClose={props.onClose}
			variant="single-column"
		>
			<DialogTitle disableTypography>
				<Typography variant="h5">
					Invite members to your team
				</Typography>
			</DialogTitle>
			<DialogContent>
				<InviteMembersDialogueContent />
			</DialogContent>
		</CommonDialog>
	);
};
