import React from 'react';
import { makeStyles } from '@material-ui/core';

/**
 * Why custom layout?
 *
 * When TypeForm is fixed height it might lead to unnecessary scrolling on devices
 * which have enough real estate. Meh.
 *
 * When TypeForm is not fixed and tries to be full screen the only mode that is
 * supports correctly is when TypeForm is the only element on the screen - otherwise
 * we might have two scrollbars at times. And at times the button that starts the
 * form might not be visible or accessible.
 *
 * E.g. as explained by developer in the answer here:
 * https://community.typeform.com/typeform-developers-44/typeform-not-displaying-full-size-on-our-web-page-how-do-we-resize-or-fix-the-issue-2880
 *
 * Quote from the link above:
 * > The full page embed code is intended to be the only content of HTML file when you copy it.
 * > If you have other content there (from the screenshot it looks like you do) you want to use standard (widget) embed code.
 *
 * So the layout below makes the TypeForm the only element on the screen.
 *
 * This component is intentionally a separate component to highlight the difference between TypeForm
 * pages from non-TypeForm pages and make sure that we do not suddenly try to wrap TypeForm with CommonAppLayout.
 */

const useStyles = makeStyles(() => ({
	'@global': {
		html: {
			height: `100%`,
			width: `100%`,
		},
		body: {
			height: `100%`,
		},
		'#root': {
			height: `100%`,
		},
	},
}));

export const TypeFormLayout: React.ComponentType<{}> = (props) => {
	useStyles();
	return <>{props.children}</>;
};
