import { ConfirmationDialogContent } from '../ConfirmationDialogContent';
import { useCurrentAccount } from '@common/contexts';
import { MenuItem } from '@material-ui/core';

export type LeaveAccountActionOpts = {
	showDialogueContent: (Component: React.ComponentType) => void;
	closeDialogue: () => void;
};

export const useLeaveAccount = ({
	showDialogueContent,
	closeDialogue,
}: LeaveAccountActionOpts) => {
	const { currentAccount, leaveAccount } = useCurrentAccount();

	const handleLeaveAccount = async () => {
		if (currentAccount?.accountId) {
			await leaveAccount(currentAccount.accountId);
			closeDialogue();
		}
	};

	const DialogueContent = () => {
		const content = {
			title: 'Leave this Team',
			subTitle1: `You will loose access to ${currentAccount?.name}, and all data associated with this Team`,
			subTitle2: 'To rejoin this Team you will need to be reinvited.',
			btnText: 'Leave',
		};

		return (
			<ConfirmationDialogContent
				content={content}
				action={handleLeaveAccount}
				handleClose={closeDialogue}
			/>
		);
	};

	const openDialog = () => {
		showDialogueContent(DialogueContent);
	};

	return <MenuItem onClick={openDialog}>Leave Team</MenuItem>;
};
