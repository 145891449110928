import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export function ActionsButton({ member, showActions }) {
	const id = `actions-menu-${member.id || member.email}`;

	const handleClick = React.useCallback(
		(e) => {
			const anchorEl = e.currentTarget;
			showActions({
				id,
				member,
				anchorEl,
			});
		},
		[showActions, member, id],
	);

	return (
		<IconButton
			aria-controls={id}
			aria-haspopup="true"
			onClick={handleClick}
		>
			<MoreHorizIcon />
		</IconButton>
	);
}
