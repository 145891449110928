import { MenuItem } from '@material-ui/core';
import { ActionOpts } from './types';
import { ConfirmationDialogContent } from '../ConfirmationDialogContent';
import { useCurrentAccount } from '@common/contexts';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';

export const useResendInvite = ({
	member,
	closeDialogue,
	refreshMembers,
	showDialogueContent,
	showSuccessSnackBar,
}: ActionOpts) => {
	const { resendInviteEmail } = useCurrentAccount();

	const handleResendInvite = async () => {
		await resendInviteEmail(member.id);
		refreshMembers();
		closeDialogue();
		showSuccessSnackBar(`Invitation has been resent`);
	};

	const DialogueContent = () => {
		const content = {
			title: 'Resend invite',
			subTitle1: 'A new invitation email will be sent',
			subTitle2: 'If you change your mind you can cancel the invitation.',
			btnText: 'CONFIRM',
		};
		return (
			<ConfirmationDialogContent
				content={content}
				action={handleResendInvite}
				handleClose={closeDialogue}
			/>
		);
	};

	const openDialog = () => {
		showDialogueContent(DialogueContent);
	};

	return (
		<MenuItem onClick={openDialog}>
			<SendOutlinedIcon style={{ marginRight: 10 }} />
			Resend invite
		</MenuItem>
	);
};
