import { ActionOpts } from './types';
import { useMakeAdmin } from './makeAdmin';
import { useRevokeAdmin } from './revokeAdmin';
import { useRemoveMember } from './removeMember';
// import { useBlockMember } from './blockMember';
import { useUnblockMember } from './unblockMember';
import { useResendInvite } from './resendInvite';
import { useCancelInvite } from './cancelInvite';
import React from 'react';
import { useLeaveAccount } from './leaveAccount';
import { useCurrentUser } from '@common/hooks';
import { useCurrentAccount } from '@common/contexts';

const optional = <T>(arr: T[] | false | undefined): T[] => {
	return arr || [];
};

export function useActionItems(opts: ActionOpts) {
	const { uid } = useCurrentUser();
	const { currentAccount } = useCurrentAccount();
	const { member } = opts;
	const makeAdmin = useMakeAdmin(opts);
	const revokeAdmin = useRevokeAdmin(opts);
	const removeMember = useRemoveMember(opts);
	// const blockMember = useBlockMember(opts);
	const unblockMember = useUnblockMember(opts);
	const resendInvite = useResendInvite(opts);
	const cancelInvite = useCancelInvite(opts);
	const leaveAccount = useLeaveAccount(opts);
	const self = [leaveAccount];
	const otherMembers = ['owner', 'admin'].includes(currentAccount?.role || '')
		? [
				member.role === 'member' && makeAdmin,
				member.role === 'admin' && revokeAdmin,
				...optional(
					['member', 'admin'].includes(member.role) && [
						removeMember,
						// blockMember,
					],
				),
				...optional(
					member.role === 'blocked' && [unblockMember, removeMember],
				),
				...optional(!member.role && [resendInvite, cancelInvite]),
		  ]
				.filter(Boolean)
				.filter(React.isValidElement)
		: [];
	const items = uid === member.id ? self : otherMembers;
	return items.map((item, i) => React.cloneElement(item, { key: i }));
}
