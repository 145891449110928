import { Box, Button, Dialog, Theme, Typography } from '@material-ui/core';
import { LoadingButton } from '@common/components';
import React from 'react';
import { useCurrentAccount } from '@common/contexts';
import { makeStyles } from '@material-ui/styles';
import { useConfigFlag } from '@common/hooks/useConfigFlag';

const useStyles = makeStyles((theme: Theme) => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: theme.spacing(1.5),
		marginTop: theme.spacing(2),
		width: '100%',

		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
		},
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1.5),
		justifyContent: 'center',

		[theme.breakpoints.up('sm')]: {
			width: '50%',
		},
	},
	actions: {
		[theme.breakpoints.up('sm')]: {
			alignSelf: 'flex-end',
		},
	},
	button: {
		[theme.breakpoints.up('sm')]: {
			width: '170px',
		},
	},
	dialog: {
		maxWidth: 400,
		borderRadius: '0.5rem',
		padding: theme.spacing(2),
	},
}));

export const DeleteAccount: React.ComponentType = () => {
	const styles = useStyles();
	const { isLoaded, currentAccount, deleteAccount } = useCurrentAccount();
	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();
	const hasActiveSubscription =
		billingEnabled && currentAccount?.tier === 'pro';
	const [showDeleteConfirmation, setShowDeleteConfirmation] =
		React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const processDeleteRequest = React.useCallback(async () => {
		if (currentAccount) {
			try {
				setLoading(true);
				await deleteAccount(currentAccount.accountId);
			} finally {
				setLoading(false);
			}
		}
	}, [currentAccount, deleteAccount]);

	if (!isLoaded && !currentAccount) {
		return <></>;
	}

	return (
		<Box>
			<Typography variant="h5" gutterBottom>
				Delete Team
			</Typography>

			<div className={styles.content}>
				<div className={styles.details}>
					<Typography variant="body1">
						Deleting will remove{' '}
						<strong>{currentAccount?.name}</strong> and associated
						data
					</Typography>
				</div>

				<div className={styles.actions}>
					<LoadingButton
						className={styles.button}
						fullWidth
						loading={loading}
						disabled={loading}
						variant="outlined"
						color="secondary"
						onClick={() => setShowDeleteConfirmation(true)}
					>
						Delete
					</LoadingButton>
				</div>
			</div>

			<Dialog
				open={showDeleteConfirmation}
				onClose={() => setShowDeleteConfirmation(false)}
				aria-labelledby="delete-team"
				maxWidth={false}
				PaperProps={{
					className: `${styles.dialog}`,
				}}
			>
				{hasActiveSubscription ? (
					<>
						<Typography variant="h5">
							Unable to delete current Team
						</Typography>
						<Box mt={1}>
							<Typography variant="body1">
								You cannot delete a team with an active
								subscription. Cancel your subscription and wait
								for the current period to finish, then try
								again.
							</Typography>
						</Box>
					</>
				) : (
					<>
						<Typography variant="h5">
							Delete current Team?
						</Typography>
						<Box mt={1}>
							<Typography variant="body1" gutterBottom>
								You'll lose all the data from this Team, and
								won't be able to recover it.
							</Typography>
							<Typography variant="body1">
								Are you sure you want to permanently delete this
								Team?
							</Typography>
						</Box>
					</>
				)}

				<Box display="flex" justifyContent="space-between" mt={4}>
					<Button
						variant="text"
						fullWidth={false}
						onClick={() => setShowDeleteConfirmation(false)}
						size="small"
					>
						Cancel
					</Button>

					<Button
						variant="contained"
						color="secondary"
						fullWidth={false}
						onClick={processDeleteRequest}
						size="small"
						disabled={hasActiveSubscription}
					>
						Yes, delete team
					</Button>
				</Box>
			</Dialog>
		</Box>
	);
};
