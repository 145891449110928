import React from 'react';
import { Carousel } from 'antd';
import { Link, shuffle, useCurrentAccount, useCurrentUser } from '@common';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormattedHubUrlOrPath } from '../../../data/urlBuilding';

const useStyles = makeStyles(() => ({
	bannerImage: {
		borderRadius: '1.5rem',
		width: '100%',
	},
}));

const CDN = 'https://cdn.remotesocial.io/hub/img';

function sortByPriority(banners) {
	const bannersByPriority = banners.reduce((result, item) => {
		const priority = item.priority ?? Number.MAX_SAFE_INTEGER;
		const list = result[priority] ?? [];
		result[priority] = list;
		list.push(item);
		return result;
	}, {});
	return Object.keys(bannersByPriority)
		.sort()
		.map((priority) => shuffle(bannersByPriority[priority]))
		.flatMap((result) => result);
}

const BannerCarousel = ({ banners }) => {
	const user = useCurrentUser();
	const { currentAccount } = useCurrentAccount();
	const role = user.uid === currentAccount.owner ? 'admin' : 'member';
	const combinedBanners = (banners[role] || []).concat(banners.all);
	const sortedBanners = sortByPriority(combinedBanners);
	return (
		<Carousel autoplay>
			{sortedBanners.map((banner) => {
				return <BannerCard banner={banner} key={banner.name} />;
			})}
		</Carousel>
	);
};

export { BannerCarousel };

const BannerCard = ({ banner }) => {
	const classes = useStyles();
	const theme = useTheme();
	const breakPoint = useMediaQuery(theme.breakpoints.up('md')) ? 'md' : 'xs';
	const parsedHref = useFormattedHubUrlOrPath(banner.href);

	if (parsedHref) {
		if (parsedHref.startsWith('/')) {
			return (
				<Link key={banner.name} to={parsedHref}>
					<img
						src={`${CDN}/banners/${banner.images[breakPoint]}`}
						alt={banner.name}
						className={classes.bannerImage}
					/>
				</Link>
			);
		}

		if (banner.newTab) {
			return (
				<a
					key={banner.name}
					href={parsedHref}
					target="'_blank"
					rel="noopener noreferrer"
				>
					<img
						src={`${CDN}/banners/${banner.images[breakPoint]}`}
						alt={banner.name}
						className={classes.bannerImage}
					/>
				</a>
			);
		}

		return (
			<a key={banner.name} href={parsedHref} target="_self">
				<img
					src={`${CDN}/banners/${banner.images[breakPoint]}`}
					alt={banner.name}
					className={classes.bannerImage}
				/>
			</a>
		);
	}

	return (
		<img
			key={banner.name}
			src={`${CDN}/banners/${banner.images[breakPoint]}`}
			alt={banner.name}
			className={classes.bannerImage}
		/>
	);
};
