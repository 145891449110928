// these are mostly for quick experimenting when building first MVP version
// as many paddings/gaps need to be consistent across components;
// this is not for configuration purposes;
// this is not a convention and doesn't have to be copy-pasted to other places;
// this possibly breaks isolation between components, just copy-pasting styles
// in some cases is a better way;

import { StyleRules, Theme } from '@material-ui/core';

export const paperPaddings = 3;
export const paperSpacing = 3;
export const externalSpacing = 2;
export const internalSpacing = 2;
export const workingWithMeRatio = 0.7;
export const workingWithMeRatioSmDown = 0.65;

export const paperStyles = (theme: Theme): StyleRules['string'] => ({
	borderRadius: theme.spacing(2.5),
	padding: theme.spacing(paperPaddings),
	[theme.breakpoints.down('xs')]: {
		boxShadow: 'none',
		borderRadius: theme.spacing(0),
		padding: theme.spacing(2),
	},
});
