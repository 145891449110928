import React from 'react';
import { PageError } from '@common';
import { env } from '@common/environment';
import { useLocation } from 'react-router';

const sanitizeContinueUrl = (url) => {
	if (!url) {
		return env().hubUrl;
	}
	if (env().name === 'local' && url.hostname === window.location.hostname) {
		return url;
	}
	if (
		(url.hostname.endsWith('.remotesocial.io') ||
			url.hostname.endsWith('.remotesocial.app')) &&
		url.protocol === 'https:'
	) {
		return url;
	}
	return env().hubUrl;
};

export const SlackErrors = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const continueURL = params.get('continue');
	const sanitizedUrl = sanitizeContinueUrl(
		continueURL && new URL(continueURL),
	);

	const errorCode = params.get('code');
	let errorMessage;
	switch (errorCode) {
		// Maybe we want to handle some of these codes differently at some point?
		// InstallerInitializationError = "slack_oauth_installer_initialization_error",
		// AuthorizationError = "slack_oauth_installer_authorization_error",
		// GenerateInstallUrlError = "slack_oauth_generate_url_error",
		// MissingStateError = "slack_oauth_missing_state",
		// UnknownError = "slack_oauth_unknown_error"
		default:
			errorMessage = params.get('error');
	}

	return (
		<PageError
			error={
				errorMessage ||
				'An unexpected error occurred during Slack setup. Please try again or contact support if the problem persists.'
			}
			continueUrl={sanitizedUrl}
		/>
	);
};
