import { Plan } from './plan';

export const plans: Plan[] = [
	{
		planId: 'free',
		title: 'Free',
		price: '$0',
		currency: 'USD',
		priceNote: 'Forever',
		features: [
			{
				planFeatureId: 'all-games',
				intro: 'All Games',
				description:
					'Unlimited access to all games and activities on Remote Social.',
			},
			{
				planFeatureId: 'team-profiles',
				intro: 'Team Profiles',
				description:
					'View teammates profiles, create and view in-depth “Work with me” guides.',
			},
		],
	},
	{
		planId: 'pro',
		title: 'Pro',
		icon: 'pro',
		price: '$29',
		currency: 'USD',
		priceNote: 'Month-to-month, or $290 per year.',
		features: [
			{
				planFeatureId: 'connect-new-starter-onboarding',
				intro: 'Connect: New Starter Onboarding',
				description:
					'Help your new starter come out of their shell with prompts and activities.',
			},
			{
				planFeatureId: 'connect-water-cooler',
				intro: 'Connect: The Water Cooler',
				description:
					'Unique prompts that will inspire fulfilling discussions and connections in your team.',
			},
			{
				planFeatureId: 'connect-wordle',
				intro: 'Connect: Wordle',
				description:
					'Play the hit sensation Wordle daily with your team.',
			},
			{
				planFeatureId: 'connect-globetrotters',
				intro: 'Connect: Globetrotters',
				description:
					'Inspire wanderlust and re-live past travel experiences with your team.',
			},
			{
				planFeatureId: 'connect-below-the-surface',
				intro: 'Connect: Below The Surface',
				description:
					'Get your team to open up whilst tapping into their desire to share more about themselves.',
			},
			{
				planFeatureId: 'team-toolkit-check-in',
				intro: 'Team Toolkit: Check-in',
				description:
					'Backed by neuroscience, Check-in is a tool used weekly to help team bonding.',
			},
		],
	},
];
