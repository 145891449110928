import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	chip: {
		color: theme.palette.common.white,
	},
}));

const colors = {
	admin: '#BB6BD9',
	owner: '#9B51E0',
	invited: '#3397FF',
	blocked: '#FF505F',
	today: '#4CAF50',
	upcoming: '#3397FF',
	previous: '#FF505F',
};

export function TableChip({ value }) {
	const classes = useStyles();
	return (
		<Chip
			label={value}
			size="small"
			style={{ background: colors[value.toLowerCase()] }}
			className={classes.chip}
		/>
	);
}
