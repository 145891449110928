import { banners } from './banners';
import { ctas } from './ctas';
import { connect } from './connect';
import { checkin } from './checkin';

export const dashboard = {
	banners,
	ctas,
	connect,
	checkin,
};
