import { Route, Switch } from 'react-router-dom';
import {
	staticBreadcrumbDescriptionByRoute,
	StaticBreadcrumbs,
} from './staticBreadcrumbs';

const staticCrumbsPath = [...Object.keys(staticBreadcrumbDescriptionByRoute)];

export const HubBreadcrumbs = () => {
	return (
		<Switch>
			<Route path={staticCrumbsPath} exact>
				<StaticBreadcrumbs />
			</Route>
		</Switch>
	);
};
