import { Member } from '@contracts/member-profile';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { typographyConstants } from '@remote-social/common/src/utils/typographyConstants';
import clsx from 'clsx';
import React from 'react';
import { Link } from '@common/components/link';
import { hubRoutes } from '@common/routes/hubRoutes';
import { useLocation } from 'react-router';
import { FluidImgContainer } from '@remote-social/common/src';

const useStyles = makeStyles((theme) => ({
	paper: {
		borderRadius: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		height: `100%`,
	},
	avatarContainer: {
		position: 'relative',
		padding: theme.spacing(0, 2, 0, 2),
		marginTop: theme.spacing(2),
	},
	avatar: {
		objectFit: 'cover',
		width: `100%`,
		height: `100%`,
		borderRadius: theme.spacing(1),
		[`& .MuiAvatar-fallback`]: {
			width: `100%`,
			height: `100%`,
		},
	},
	absoluteChipContainer: {
		// Position the chip at the top of the avatar
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		transform: `translate(0, -50%)`,
		display: 'flex',
		placeItems: 'center',
		justifyContent: 'center',
	},
	chip: {
		borderRadius: theme.spacing(8),
		padding: theme.spacing(0.1, 0.6),
		// NOTE: Current typography doesn't have a text size
		// that is this small, so need to review this
		fontSize: `10px`,
		fontWeight: 500,
		lineHeight: 1.4,
	},
	owner: {
		color: theme.palette.ownerChip.contrastText,
		background: theme.palette.ownerChip.background,
	},
	admin: {
		color: theme.palette.adminChip.contrastText,
		background: theme.palette.adminChip.background,
	},
	textContainer: {
		padding: theme.spacing(1),
	},
	name: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		marginBottom: theme.spacing(0.5),
	},
	jobTitle: {
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
}));

type Props = {
	member: Member;
};

export const AccountMember: React.ComponentType<Props> = (props) => {
	const styles = useStyles();
	const location = useLocation();
	return (
		<Link
			to={hubRoutes.accounts.memberProfile({
				uid: props.member.uid,
				accountId: props.member.accountId,
				location,
			})}
			underline="none"
		>
			<Paper className={styles.paper}>
				<div className={styles.avatarContainer}>
					<FluidImgContainer aspectRatio={1}>
						<img
							className={styles.avatar}
							src={props.member.photoURL}
							alt={props.member.displayName}
						/>
					</FluidImgContainer>

					<div className={styles.absoluteChipContainer}>
						<>
							{props.member.accountRole === 'owner' && (
								<Typography
									className={clsx(styles.chip, styles.owner)}
									variant="caption"
								>
									Owner
								</Typography>
							)}
							{props.member.accountRole === 'admin' && (
								<Typography
									className={clsx(styles.chip, styles.admin)}
									variant="caption"
								>
									Admin
								</Typography>
							)}
						</>
					</div>
				</div>
				<div className={styles.textContainer}>
					<Typography
						variant="body1"
						align="center"
						className={styles.name}
						title={props.member.displayName}
					>
						{props.member.displayName}
					</Typography>
					<Typography
						variant="caption"
						color="textSecondary"
						align="center"
						className={styles.jobTitle}
						title={props.member.jobTitle}
					>
						{props.member.jobTitle}
						{/* Do not collapse when jobTitle is empty */}
						{typographyConstants.zeroWidthSpace}
					</Typography>
				</div>
			</Paper>
		</Link>
	);
};
