import { env } from '@common/environment/envConfig';

export type GameLink = {
	type: 'GET';
	rel: string;
	href: string;
};

export type GameInformation = {
	id: string;
	name: string;
	category: string;
	description: string;
	avatarAlt: string;
	avatarUrl: string;
	publisher: string;
	subdomain: string;
	links: GameLink[];
	playerSupport: {
		min: number;
		max?: number;
	};
	introduction: {
		explainerVideo: string;
		points: string[];
	};
	canSchedule: boolean;
	publishedToHub: boolean;
	priority: number;
	isPro: boolean;
};

export const games = (
	ziagoLatestApi: boolean,
	deps = { env },
): GameInformation[] => {
	const envConfig = deps.env();
	return [
		{
			avatarAlt: 'Bingo',
			avatarUrl: '/tiles/game-bingo-v2.png',
			description:
				'A quick and fun energiser to kickstart your next team meeting. BINGO!',
			name: 'Bingo',
			publisher: 'remotesocial',
			subdomain: 'bingo',
			links: [
				{
					type: 'GET',
					rel: 'new-game',
					href: `${envConfig.bingoUrl}/#create/{accountId}`,
				},
				{
					type: 'GET',
					rel: 'lobby',
					href: `${envConfig.bingoUrl}/game/{accountId}/{gameSlug}`,
				},
			],
			category: 'bingo',
			playerSupport: {
				min: 1,
			},
			id: 'bingo',
			introduction: {
				explainerVideo:
					'https://cdn.remotesocial.io/common/game-intro/bingo.mp4',
				points: [
					'The goal of Bingo is to be the first player to get 5 stamped numbers in a row on your scorecard.',
					'The screen will display number call-outs for you to mark on your scorecard with a single click.',
					'If you’re the first player to get 5 in a row in the chosen pattern, click “BINGO!” to win the game!',
				],
			},
			canSchedule: true,
			publishedToHub: true,
			priority: 1,
			isPro: false,
		},
		{
			avatarAlt: 'Knowing Me, Knowing You',
			avatarUrl: '/tiles/game-knowing.png',
			description:
				'Who do you know more about? Match answers with people to win!',
			name: 'Knowing Me, Knowing You',
			publisher: 'ziago',
			subdomain: 'games',
			links: [
				{
					type: 'GET',
					rel: 'new-game',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/startgame?gameType=knowing&displayName={displayName}&uid={userId}&accountId={accountId}`
						: `${envConfig.ziagoUrl}/play/startgame?game=knowing&name={displayName}&user={userId}&account={accountId}&gameType=knowing&displayName={displayName}&uid={userId}&accountId={accountId}`,
				},
				{
					type: 'GET',
					rel: 'lobby',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/knowing?gameId={gameSlug}`
						: `${envConfig.ziagoUrl}/play/knowing?game={gameSlug}&gameId={gameSlug}`,
				},
			],
			category: 'team',
			playerSupport: {
				max: 8,
				min: 2,
			},
			id: 'z_5_knowing',
			canSchedule: false,
			introduction: {
				explainerVideo:
					'https://cdn.remotesocial.io/common/game-intro/knowing.mp4',
				points: [
					'How well do you really know your fellow players? Jump into this insightful and hilariously fun social game to find out.',
					'Players take turns providing facts about themselves whilst everyone else predicts the correct answer.',
					'Be the first to score 7 points whilst also discovering interesting information about others you never knew before.',
				],
			},
			publishedToHub: true,
			priority: 1,
			isPro: false,
		},
		{
			avatarAlt: 'Trivia',
			avatarUrl: '/tiles/game-trivia-v2.png',
			description:
				'Grab some snacks and a drink, and bring your team together for trivia. Who will win?',
			name: 'Trivia',
			publisher: 'remotesocial',
			subdomain: 'trivia',
			links: [
				{
					type: 'GET',
					rel: 'new-game',
					href: `${envConfig.triviaUrl}/#create/{accountId}`,
				},
				{
					type: 'GET',
					rel: 'lobby',
					href: `${envConfig.triviaUrl}/game/{accountId}/{gameSlug}`,
				},
			],
			category: 'trivia',
			playerSupport: {
				max: 100,
				min: 1,
			},
			id: 'trivia',
			introduction: {
				explainerVideo:
					'https://cdn.remotesocial.io/common/game-intro/trivia.mp4',
				points: [
					'Challenge your Remote Social Circle to a game of trivia.',
					'Pick from multiple categories or create your own custom category.',
					'Be quick; the faster you can answer correctly, the more points are awarded.',
				],
			},
			canSchedule: true,
			publishedToHub: true,
			priority: 0,
			isPro: false,
		},
		{
			avatarAlt: 'Who Am I',
			avatarUrl: '/tiles/game-whoami-v2.png',
			description:
				'Guess your secret identity before your opponents guess theirs. Classic celebrity head.',
			name: 'Who Am I',
			publisher: 'ziago',
			subdomain: 'games',
			links: [
				{
					type: 'GET',
					rel: 'new-game',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/startgame?gameType=whoami&displayName={displayName}&uid={userId}&accountId={accountId}`
						: `${envConfig.ziagoUrl}/play/startgame?game=whoami&name={displayName}&user={userId}&account={accountId}&gameType=whoami&displayName={displayName}&uid={userId}&accountId={accountId}`,
				},
				{
					type: 'GET',
					rel: 'lobby',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/whoami?gameId={gameSlug}`
						: `${envConfig.ziagoUrl}/play/whoami?game={gameSlug}&gameId={gameSlug}`,
				},
			],
			category: 'team',
			playerSupport: {
				max: 6,
				min: 3,
			},
			id: 'z_1_whoami',
			canSchedule: false,
			introduction: {
				explainerVideo:
					'https://cdn.remotesocial.io/common/game-intro/who-am-i.mp4',
				points: [
					'Everyone takes turns to correctly guess the name of a famous person or character assigned to them.',
					'Each turn, a player will ask the other players 3 yes or no questions to find out who they are.',
					'If a player feels they have enough information to guess, they can on their turn but only a total of 3 times in a game.',
				],
			},
			publishedToHub: true,
			priority: 1,
			isPro: false,
		},
		{
			avatarAlt: 'Team Taboo',
			avatarUrl: '/tiles/game-teamtaboo-v2.png',
			description:
				'Get your team to guess the secret word without saying it. Great team fun.',
			name: 'Team Taboo',
			publisher: 'ziago',
			subdomain: 'games',
			links: [
				{
					type: 'GET',
					rel: 'new-game',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/startgame?gameType=teamtaboo&displayName={displayName}&uid={userId}&accountId={accountId}`
						: `${envConfig.ziagoUrl}/play/startgame?game=teamtaboo&name={displayName}&user={userId}&account={accountId}&gameType=teamtaboo&displayName={displayName}&uid={userId}&accountId={accountId}`,
				},
				{
					type: 'GET',
					rel: 'lobby',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/teamtaboo?gameId={gameSlug}`
						: `${envConfig.ziagoUrl}/play/teamtaboo?game={gameSlug}&gameId={gameSlug}`,
				},
			],
			category: 'team',
			playerSupport: {
				max: 12,
				min: 4,
			},
			id: 'z_2_teamtaboo',
			canSchedule: false,
			introduction: {
				explainerVideo:
					'https://cdn.remotesocial.io/common/game-intro/team-taboo.mp4',
				points: [
					'How quickly can you describe a word to your team without saying it?',
					"Players each have 40 seconds to describe 5 ‘taboo’ words to their team. Don't say the word or your out!",
					'Your teammates will type their guesses for the taboo words. Points are awarded for correct guesses and spelling.',
					'The first team to 40 points wins.',
				],
			},
			publishedToHub: true,
			priority: 1,
			isPro: false,
		},
		{
			avatarAlt: 'Most Likely',
			avatarUrl: '/tiles/game-mostlikely-v2.png',
			description:
				'Find the player in your group most likely to... lose their keys? take the longest lunch?...',
			name: 'Most Likely',
			publisher: 'ziago',
			subdomain: 'games',
			links: [
				{
					type: 'GET',
					rel: 'new-game',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/startgame?gameType=mostlikely&displayName={displayName}&uid={userId}&accountId={accountId}`
						: `${envConfig.ziagoUrl}/play/startgame?game=mostlikely&name={displayName}&user={userId}&account={accountId}&gameType=mostlikely&displayName={displayName}&uid={userId}&accountId={accountId}`,
				},
				{
					type: 'GET',
					rel: 'lobby',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/mostlikely?gameId={gameSlug}`
						: `${envConfig.ziagoUrl}/play/mostlikely?game={gameSlug}&gameId={gameSlug}`,
				},
			],
			category: 'team',
			playerSupport: {
				max: 12,
				min: 3,
			},
			id: 'z_3_mostlikely',
			canSchedule: false,
			introduction: {
				explainerVideo:
					'https://cdn.remotesocial.io/common/game-intro/most-likely.mp4',
				points: [
					'Find out who in your team is the most likely to... (lose their keys? Be late to a meeting?)',
					'Each player gets a turn to read a “Who’s most likely to…” question on their screen.',
					'Everyone votes on who they think the ‘most likely’ player is, and that person will have to tell a joke!',
				],
			},
			publishedToHub: true,
			priority: 1,
			isPro: false,
		},
		{
			avatarAlt: 'Bad Blends',
			avatarUrl:
				'https://cdn.remotesocial.io/hub/img/games/bad-blends-v2-mobile.png',
			description:
				'Create the funniest and most inappropriate response to win. Adults only!',
			name: 'Bad Blends',
			publisher: 'ziago',
			subdomain: 'games',
			links: [
				{
					type: 'GET',
					rel: 'new-game',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/startgame?gameType=badblends&displayName={displayName}&uid={userId}&accountId={accountId}`
						: `${envConfig.ziagoUrl}/play/startgame?game=badblends&name={displayName}&user={userId}&account={accountId}&gameType=badblends&displayName={displayName}&uid={userId}&accountId={accountId}`,
				},
				{
					type: 'GET',
					rel: 'lobby',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/badblends?gameId={gameSlug}`
						: `${envConfig.ziagoUrl}/play/badblends?game={gameSlug}&gameId={gameSlug}`,
				},
			],
			category: 'adult',
			playerSupport: {
				max: 12,
				min: 3,
			},
			id: 'z_4_badblends',
			canSchedule: false,
			introduction: {
				explainerVideo:
					'https://cdn.remotesocial.io/common/game-intro/bad-blends.mp4',
				points: [
					'The most inappropriate answer wins in this hillarious classic adults-only showdown.',
					'Players take turns submitting their best, wittiest, or rudest random answer to a prompt.',
					'The selected winning answer will score a point, first to 10 wins.',
				],
			},
			publishedToHub: true,
			priority: 1,
			isPro: false,
		},
		{
			avatarAlt: 'Two Truths and a Lie',
			avatarUrl: '/tiles/game-2truths-v2.png',
			description: '',
			name: 'Two Truths and a Lie',
			publisher: 'ziago',
			subdomain: 'games',
			links: [
				{
					type: 'GET',
					rel: 'new-game',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/startgame?gameType=2truths&displayName={displayName}&uid={userId}&accountId={accountId}`
						: `${envConfig.ziagoUrl}/play/startgame?game=2truths&name={displayName}&user={userId}&account={accountId}&gameType=2truths&displayName={displayName}&uid={userId}&accountId={accountId}`,
				},
				{
					type: 'GET',
					rel: 'lobby',
					href: ziagoLatestApi
						? `${envConfig.ziagoUrl}/play/2truths?gameId={gameSlug}`
						: `${envConfig.ziagoUrl}/play/2truths?game={gameSlug}&gameId={gameSlug}`,
				},
			],
			category: 'adult',
			playerSupport: {
				max: 12,
				min: 3,
			},
			id: 'z_6_2truths',
			canSchedule: false,
			introduction: {
				explainerVideo:
					'https://cdn.remotesocial.io/common/game-intro/2truths.mp4',
				points: [
					'Find out who is the best liar in your group! In this game, everyone will take turns presenting three personal statements and it will be up to you to separate the lies from the truth.',
					"Two points are awarded to a presenter who hides their lie with no one guessing. One point is awarded for correctly guessing someone else's lie. The player with the most points at the end wins!",
				],
			},
			publishedToHub: true,
			priority: 0,
			isPro: false,
		},
	];
};
