import React from 'react';
import { GameInformation, games as gamesData } from '../../data/games';
import { preFormatGameUrlsTemplates } from '../../data/urlBuilding';
import { shuffle } from '@common/utils/shuffle';
import { useConfigFlag, useCurrentUser } from '@common/hooks';
import { useCurrentAccount } from '@common/contexts';
import { ensureExists } from '@common/utils/preconditions';

const Context = React.createContext<GameInformation[]>([]);

function sortGamesByPriority(games: GameInformation[]) {
	const gamesByPriority = games.reduce<Record<number, GameInformation[]>>(
		(result, item) => {
			const priority = item.priority ?? Number.MAX_SAFE_INTEGER;
			const list = result[priority] ?? [];
			result[priority] = list;
			list.push(item);
			return result;
		},
		{},
	);
	return Object.keys(gamesByPriority)
		.sort()
		.map((priority) =>
			shuffle(ensureExists(gamesByPriority[Number(priority)])),
		)
		.flatMap((result) => result);
}

export const PlayableGamesProvider: React.ComponentType = ({ children }) => {
	const { uid, displayName } = useCurrentUser();
	const { currentAccountId } = useCurrentAccount();
	const ziagoLatestApi = useConfigFlag('ziagoLatestApi').asBoolean();

	const games = React.useMemo(() => {
		if (!currentAccountId || !uid) {
			// while we are not logged in and don't have current account
			return [];
		}
		return sortGamesByPriority(
			preFormatGameUrlsTemplates(gamesData(ziagoLatestApi), {
				accountId: currentAccountId,
				displayName,
				uid,
			}),
		);
	}, [currentAccountId, uid, ziagoLatestApi, displayName]);

	return <Context.Provider value={games}>{children}</Context.Provider>;
};

export const usePlayableGames = () => React.useContext(Context);
