import React from 'react';
import {
	Avatar,
	Button,
	Chip,
	Grid,
	Hidden,
	Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { ChipFormField, LoadingButton, useCurrentAccount } from '@common';
import { emailSchema } from '@common/utils/validation';
import { useFirebase } from 'react-redux-firebase';
import clsx from 'clsx';
import * as Yup from 'yup';
import { ImageBackground } from '@common/components/imageBackground';
import { registerError } from '@common/errors';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridAutoColumns: '1fr',
	},
	rightColumn: {
		display: 'grid',
		gridTemplateRows: 'auto 1fr auto',

		padding: theme.spacing(4, 4, 2, 4),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
		},
	},
	title: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	chipWrapper: {
		flex: 1,
		overflowY: 'auto',
		overflowX: 'hidden',
		scrollbarWidth: 'thin',
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(2),
		[`& > button`]: {
			marginLeft: theme.spacing(2),
		},
	},
	contents: {
		display: 'contents',
	},
}));

const EmailChip = (props) => {
	return (
		<Chip
			{...props}
			avatar={<Avatar>{props?.label?.[0]?.toUpperCase()}</Avatar>}
			size="small"
		/>
	);
};

const ChipsContainer = ({ children, className, ...rest }) => {
	const classes = useStyles();

	return (
		<Grid {...rest} className={clsx(className, classes.chipWrapper)}>
			{children}
		</Grid>
	);
};

const emailsSchema = Yup.object().shape({
	emails: Yup.array(emailSchema)
		.required()
		.min(1, 'Please provide at least one email'),
});

export function InvitePeople({ handleClose, showSuccessSnackBar }) {
	const classes = useStyles();
	const firebase = useFirebase();
	const { currentAccount } = useCurrentAccount();
	const accountId = currentAccount?.accountId;

	const handleSubmit = async (
		payload,
		{ setFieldError, setValues, setSubmitting },
	) => {
		const { emails } = payload;
		if (!emails.length) {
			return;
		}
		setSubmitting(true);
		try {
			const { data } = await firebase
				.functions()
				.httpsCallable('accountInvites-invitePeople')({
				inviteEmails: payload.emails,
				accountId,
			});

			const { invited, failed } = data;

			if (invited.length > 0) {
				const snackbarMsg =
					invited.length === 1
						? 'Invitation sent!'
						: `${invited.length} invitations sent!`;
				showSuccessSnackBar(snackbarMsg);
			}

			if (failed.length === 0) {
				handleClose();
			} else {
				setValues(
					{
						emails: failed,
					},
					false,
				);
				setFieldError('emails', `Failed to send invites to`);
			}
		} catch (e) {
			registerError(e);
			setFieldError(
				'emails',
				`Something went wrong, couldn't send invites`,
			);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div className={classes.root}>
			<Hidden xsDown>
				<ImageBackground>
					<img
						src="https://cdn.remotesocial.io/hub/img/email.gif"
						alt="Invite people"
						// intrinsic size
						width="544"
						height="544"
					/>
				</ImageBackground>
			</Hidden>
			<div className={classes.rightColumn}>
				<Typography className={classes.title} variant="h4">
					Invite people to join your team
				</Typography>
				<Formik
					initialValues={{
						emails: [],
					}}
					onSubmit={handleSubmit}
					validationSchema={emailsSchema}
				>
					{(form) => (
						<Form className={classes.contents}>
							<ChipFormField
								fullWidth
								variant="outlined"
								name="emails"
								placeholder="Add emails here"
								helperText="Enter emails separated by space"
								chipValidationSchema={emailSchema}
								Chip={EmailChip}
								ChipsContainer={ChipsContainer}
							/>
							<div className={classes.buttons}>
								<Button onClick={handleClose}>Cancel</Button>
								<LoadingButton
									disabled={form.values.emails?.length === 0}
									loading={form.isSubmitting}
									fullWidth={false}
									disableElevation
									color="primary"
									variant="contained"
									type="submit"
								>
									Send <Hidden xsDown>invites</Hidden>
								</LoadingButton>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}
