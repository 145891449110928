import React from 'react';
import { DocumentHead, Loading, PageError } from '@common/components';
import { useState } from './state';

export const JoinZiagoGame: React.ComponentType = () => {
	const pageRequest = useState();

	if (pageRequest.status === 'error') {
		return <PageError error={pageRequest.error} />;
	}

	return (
		<>
			<DocumentHead title="Joining Game" />
			<Loading showRandomLabels />
		</>
	);
};
