import {
	Loading,
	PageError,
	useBackendFunction,
	useCurrentAccount,
	useCurrentUser,
} from '@common';
import React from 'react';
import { createWidget } from '@typeform/embed';
import { ensureExists } from '@contracts/shared/preconditions';
import { makeStyles } from '@material-ui/core';
import { env } from '@common/environment';
import { EnvName } from '@common/environment/types';
import { useHistory, useLocation } from 'react-router';
import { hubRoutes } from '@remote-social/common/src/routes/hubRoutes';

const useStyles = makeStyles(() => ({
	container: {
		width: `100%`,
		height: `100%`,
		[`& div`]: {
			height: `100%`,
		},
	},
	center: {
		display: 'flex',
		height: `100%`,
		width: `100%`,
		placeItems: 'center',
		placeContent: 'center',
	},
}));

// we could probably load this up from server side but I'm in favour
// of better user experience of not loading stuff;
//
// TODO: alternatively this could be in our contract library and be shared
// between FE and BE, but that will require us moving @common/environment
// stuff there as well
const createMemberProfileTypeFormIdByEnv: Record<EnvName, string> = {
	local: 'x5lTo2fS',
	staging: 'x5lTo2fS',
	prod: 's4RkdUen',
};

const usePageState = () => {
	const history = useHistory();
	const location = useLocation();
	const account = useCurrentAccount();
	const user = useCurrentUser();
	const accountId = ensureExists(account.currentAccountId);
	const uid = ensureExists(user.uid);

	const [typeFormImportRequest, typeFormImport] = useBackendFunction(
		'memberProfile-typeFormImport',
	);

	const searchParams = new URLSearchParams(location.search);
	const searchFormId = searchParams.get('responseId');
	const searchResponseId = searchParams.get('responseId');
	// we will need iframe mounted if we don't have response id, so
	// loading = true then, meaning we are loading the iframe
	const [iframeLoading, setIFrameLoading] = React.useState(!searchResponseId);

	React.useEffect(() => {
		if (!searchResponseId) {
			return;
		}
		typeFormImport({
			accountId,
			responseId: searchResponseId,
		});
	}, [accountId, searchResponseId, typeFormImport]);

	React.useEffect(() => {
		if (typeFormImportRequest.status === 'success') {
			history.replace(
				hubRoutes.accounts.memberProfile({
					accountId,
					uid,
					location,
				}),
			);
		}
	}, [accountId, history, location, typeFormImportRequest, uid]);

	React.useEffect(() => {
		if (typeFormImportRequest.status !== 'initial' || searchResponseId) {
			// unmount when import started or if we already have a response
			return;
		}

		const formId =
			searchFormId || createMemberProfileTypeFormIdByEnv[env().name];

		const { unmount } = createWidget(formId, {
			container: ensureExists(
				document.querySelector<HTMLDivElement>('#typeForm'),
			),
			// autoResize: true,
			iframeProps: {
				width: '100%',
				height: '100%',
			},
			enableFullscreen: true,
			hidden: {
				account_id: accountId,
				uid,
			},
			onReady: () => {
				setIFrameLoading(false);
			},
			onSubmit: ({ responseId }) => {
				// remember the response id so we can retry by refreshing the page in case of error
				// this will also lead to the typeForm import to happen
				history.replace(
					hubRoutes.accounts.createMemberProfile({
						accountId,
						responseId,
						formId,
						location,
					}),
				);
			},
		});

		return () => {
			unmount();
		};
	}, [
		accountId,
		history,
		location,
		searchFormId,
		searchResponseId,
		typeFormImport,
		typeFormImportRequest,
		uid,
	]);

	return {
		iframeLoading,
		typeFormImportRequest,
		shouldMountTypeForm:
			typeFormImportRequest.status === 'initial' && !searchResponseId,
	};
};

export const CreateMemberProfile: React.ComponentType = () => {
	const styles = useStyles();
	const { iframeLoading, typeFormImportRequest, shouldMountTypeForm } =
		usePageState();
	return (
		<>
			{shouldMountTypeForm && (
				<div id="typeForm" className={styles.container} />
			)}
			{(iframeLoading || typeFormImportRequest.status === 'pending') && (
				<Loading showRandomLabels={true} />
			)}
			{typeFormImportRequest.status === 'error' && (
				<div className={styles.center}>
					<PageError error={typeFormImportRequest.error} />
				</div>
			)}
		</>
	);
};
