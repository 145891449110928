import React from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { FACEBOOK, GOOGLE, useAuth } from '@common/hooks';
import FacebookLogo from '@common/assets/images/login-providers/facebook.png';
import GoogleLogo from '@common/assets/images/login-providers/google.png';
import { ErrorType, registerError } from '@common/errors';
import { AuthErrorMessage } from '@common/components/authErrorMessage';
import { LoadingButton } from '@common/components';
import { capitalize } from '@common/utils/capitalize';

type Provider = typeof GOOGLE | typeof FACEBOOK;

const useStyles = makeStyles((theme: Theme) => ({
	providers: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1.5),
		marginTop: theme.spacing(2),
	},
	provider: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	details: {
		display: 'flex',
		gap: theme.spacing(1.5),
		alignItems: 'center',
	},
	button: {
		width: '170px',
	},
}));

type State = {
	providerLogos: { [providerName: string]: string };
	error: ErrorType | null;
	socialLoginStatuses: {};
	dialogOpen: boolean;
	activeProviderID?: Provider;
	isPending: boolean;
	onDialogOpen: (providerID: Provider) => void;
	onDialogClose: () => void;
	linkProvider: (providerID: Provider) => void;
	unlinkProvider: (providerID: Provider) => void;
};

const useState = (): State => {
	const { getSocialLoginStatuses, linkWithPopup, unlink } = useAuth();
	const [activeProviderID, setActiveProviderID] = React.useState<Provider>();
	const [socialLoginStatuses, setSocialLoginStatuses] = React.useState(
		getSocialLoginStatuses(),
	);
	const [error, setError] = React.useState<ErrorType | null>(null);
	const [isPending, setIsPending] = React.useState<boolean>(false);
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const onDialogOpen = React.useCallback((providerID: Provider) => {
		setActiveProviderID(providerID);
		setDialogOpen(true);
	}, []);

	const onDialogClose = React.useCallback(() => {
		setDialogOpen(false);
	}, []);

	const linkProvider = React.useCallback(
		async (providerID: Provider) => {
			try {
				setIsPending(true);
				await linkWithPopup(providerID);
				setSocialLoginStatuses(getSocialLoginStatuses());
			} catch (err) {
				registerError(error);
				setError(error as ErrorType);
			} finally {
				setIsPending(false);
				setActiveProviderID(undefined);
			}
		},
		[error, getSocialLoginStatuses, linkWithPopup],
	);

	const unlinkProvider = React.useCallback(
		async (providerID: Provider) => {
			try {
				setIsPending(true);
				await unlink(providerID);
				setSocialLoginStatuses(getSocialLoginStatuses());
				onDialogClose();
			} catch (err) {
				registerError(error);
				setError(error as ErrorType);
			} finally {
				setIsPending(false);
				setActiveProviderID(undefined);
			}
		},
		[error, getSocialLoginStatuses, onDialogClose, unlink],
	);

	const result = React.useMemo(() => {
		const providerLogos = {
			[GOOGLE]: GoogleLogo,
			[FACEBOOK]: FacebookLogo,
		};
		return {
			providerLogos,
			error,
			socialLoginStatuses,
			dialogOpen,
			activeProviderID,
			isPending,
			linkProvider,
			onDialogOpen,
			onDialogClose,
			unlinkProvider,
		};
	}, [
		error,
		socialLoginStatuses,
		dialogOpen,
		activeProviderID,
		isPending,
		linkProvider,
		onDialogOpen,
		onDialogClose,
		unlinkProvider,
	]);

	return result;
};

export const UpdateProviders = (): React.ReactElement => {
	const styles = useStyles();
	const {
		providerLogos,
		error,
		socialLoginStatuses,
		dialogOpen,
		activeProviderID,
		isPending,
		linkProvider,
		unlinkProvider,
		onDialogOpen,
		onDialogClose,
	} = useState();

	return (
		<Box>
			<Typography variant="h5" gutterBottom>
				Update Login Providers
			</Typography>

			<div className={styles.providers}>
				{Object.entries(socialLoginStatuses).map(
					([providerID, isLinked]) => {
						const existingProvider = [GOOGLE, FACEBOOK].includes(
							providerID,
						);

						const providerLogo =
							existingProvider && providerLogos[providerID];

						return (
							<div className={styles.provider} key={providerID}>
								<div className={styles.details}>
									{providerLogo && (
										<img
											width={32}
											height={32}
											src={providerLogo}
											alt={providerID}
										/>
									)}
									<Typography variant="h6">
										{capitalize(providerID)}
									</Typography>
								</div>
								<div>
									<LoadingButton
										className={styles.button}
										aria-label={`${providerID} login provider`}
										loading={
											providerID === activeProviderID &&
											isPending
										}
										disabled={
											providerID === activeProviderID &&
											isPending
										}
										onClick={() =>
											isLinked
												? onDialogOpen(
														providerID as
															| typeof GOOGLE
															| typeof FACEBOOK,
												  )
												: linkProvider(
														providerID as
															| typeof GOOGLE
															| typeof FACEBOOK,
												  )
										}
										variant="outlined"
										color={
											isLinked ? 'secondary' : 'primary'
										}
									>
										{isLinked ? 'Disconnect' : 'Connect'}
									</LoadingButton>
								</div>
							</div>
						);
					},
				)}

				<AuthErrorMessage error={error} />
			</div>
			<Dialog
				open={dialogOpen}
				onClose={onDialogClose}
				aria-labelledby="confirm-remove-provider"
				aria-describedby="confirm-remove-provider"
			>
				<DialogTitle>Remove login provider</DialogTitle>
				<DialogContent>
					Are you sure you want to disconnect{' '}
					<strong>{capitalize(activeProviderID || '')}</strong> from
					your user login settings?
					<br />
					<br />
					You will no longer be able to use{' '}
					<strong>{capitalize(activeProviderID || '')}</strong> to log
					in.
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="default"
						onClick={onDialogClose}
						size="small"
					>
						Cancel
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						disabled={!activeProviderID}
						onClick={() =>
							unlinkProvider(
								activeProviderID as
									| typeof GOOGLE
									| typeof FACEBOOK,
							)
						}
						size="small"
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
