import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageNotFound, useCurrentUser } from '@remote-social/common/src';
import {
	getCommonAuthRoutes,
	useRedirectToOnboardForPrivateRoutes,
} from './internal';
import { useRedirectToContinueForLoginRoutes } from './internal/useRedirectToContinueForLoginRoutes';
import { Allowed, isAllowed } from './user-land-routes/isAllowedRoute';
import { useRouterDiagnostics } from './routerDiagnostics';
import { isDevBuild } from '../environment';

type Props = {
	// NOTE: there is no way to enforce type of element
	// unless we pass in Props instead of Elements

	/**
	 * Public routes, accepts a Route and Redirect or Fragment and Switch
	 * which wrap multiple Route's or Redirect's
	 */
	public?: React.ReactElement;

	/**
	 * Private routes, accepts a Route and Redirect or Fragment and Switch
	 * which wrap multiple Route's or Redirect's
	 */
	private?: React.ReactElement;

	PageNotFound?: React.ComponentType;

	/**
	 * Wraps every common route, wrapping public and private routes
	 * is handled by the user
	 */
	ScreenContainer?: React.ComponentType<{
		variant?: 'two-column' | 'single-column';
	}>;

	Loading?: React.ComponentType;
};

type Container = React.ReactElement<{ children: React.ReactNode }>;

function isContainer(element: React.ReactElement): element is Container {
	const allowed: React.JSXElementConstructor<any>[] = [
		// switch is allowed because we remove it and wrap everything
		// with our own switch
		Switch,
		React.Fragment,
	];
	return (
		React.isValidElement(element) &&
		typeof element.type !== 'string' &&
		allowed.includes(element.type)
	);
}

function collectRoutes(fragment: React.ReactElement): Allowed[] {
	if (Array.isArray(fragment)) {
		return fragment.filter(isAllowed);
	} else if (React.isValidElement(fragment)) {
		if (isAllowed(fragment)) {
			return [fragment];
		} else if (isContainer(fragment)) {
			// wrapping by Switch or Fragment?
			if (Array.isArray(fragment.props.children)) {
				return fragment.props.children.filter(isAllowed);
			} else if (isAllowed(fragment.props.children)) {
				return [fragment.props.children];
			}
		}
	}
	throw new Error(`Invalid element type passed ${fragment}`);
}

const optional = <T,>(arr: T[] | false): T[] => (Array.isArray(arr) ? arr : []);

export const CommonRouter: React.ComponentType<Props> = (props) => {
	const user = useCurrentUser();

	const commonRoutes = React.useMemo(
		() =>
			getCommonAuthRoutes({
				ScreenContainer: props.ScreenContainer,
			}),
		[props.ScreenContainer],
	);

	const publicRoutes = React.useMemo(
		() => [
			...commonRoutes.public,
			...optional(!!props.public && collectRoutes(props.public)),
		],
		[commonRoutes.public, props.public],
	);
	const privateRoutes = React.useMemo(
		() => [
			...commonRoutes.private,
			...optional(!!props.private && collectRoutes(props.private)),
		],
		[commonRoutes.private, props.private],
	);
	const unauthenticated = commonRoutes.unauthenticated;

	const redirectToNext = useRedirectToContinueForLoginRoutes({
		unauthenticatedRoutes: unauthenticated,
	}).filter(isAllowed);
	const redirectToLogin = useRedirectToOnboardForPrivateRoutes({
		privateRoutes,
	}).filter(isAllowed);

	const PageNotFoundProp = props.PageNotFound ?? PageNotFound;

	/**
	 * the order of routes and how they are spread matters a lot as there can be some conflicting routes:
	 * e.g. /login route redirects to home page when authenticated and opens up login UI when not authenticated
	 * or /create-account route redirects to login page when not-authenticated and opens up create account UI when authenticated
	 * if the order is incorrect it can lead to infinite redirects
	 */
	const elements = [
		...publicRoutes,
		...(user.isAuthenticated
			? [...redirectToNext, ...privateRoutes]
			: [...unauthenticated, ...redirectToLogin]),
		<Route>
			<PageNotFoundProp />
		</Route>,
	];

	// diagnosing routing behaviour can be challenging
	// so we have built a little tool that can make it easier
	if (isDevBuild()) {
		// the above check is constant and doesn't change
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useRouterDiagnostics({
			elements,
			isAuthenticated: user.isAuthenticated,
			isLoaded: user.isLoaded,
			enabled: false,
		});
	}

	if (!user.isLoaded) {
		return props.Loading ? <props.Loading /> : null;
	}

	return (
		<Switch
			children={elements.map((route, i) =>
				React.cloneElement(route, {
					key: i,
					/**
					 * Provides means for custom Route's to show loading
					 * indicator consistently
					 */
					Loading: props.Loading,
				}),
			)}
		/>
	);
};
