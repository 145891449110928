import { Avatar } from '@material-ui/core';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
	return createStyles({
		avatar: {
			width: theme.spacing(20),
			height: theme.spacing(20),

			border: `4px solid #FFFFFF`,
			borderRadius: theme.spacing(2.5),
		},
	});
});

type Props = {
	member: {
		displayName?: string;
		photoURL?: string;
	};
};

export const ProfileAvatar: React.ComponentType<Props> = ({ member }) => {
	const styles = useStyles();
	return (
		<Avatar
			className={styles.avatar}
			variant="rounded"
			src={member.photoURL}
			aria-label={member.displayName}
		/>
	);
};
