import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Auth } from '../Auth';
import Logout from '../Auth/Logout';
import { IntegrateWithSlack } from './slack/integrateWithSlack';
import { routes } from '../routes';
import { HasAccountRoute, VerifyEmailRoute } from '../user-land-routes';
import { Subscribe } from '../../billing/subscribe/page';
import { CheckoutSuccess } from '../../billing/checkout-success/page';

type Opts = {
	ScreenContainer?: React.ComponentType<{
		variant?: 'single-column' | 'two-column';
	}>;
};

export function getCommonAuthRoutes(opts: Opts) {
	const ScreenContainer =
		opts.ScreenContainer || (({ children }) => <>{children}</>);
	// TODO: we don't need additional level of nesting, just use the screens here directly
	return {
		// routes that are available for both authenticated and non-authenticated users
		public: [
			<Redirect exact path="/register/host" to={routes.register.path} />,
			<Route path={routes.handleLink.path}>
				<Auth
					variant="HandleLink"
					containerVariant="single-column"
					ScreenContainer={ScreenContainer}
				/>
			</Route>,
			<Route path={routes.resetPassword.path}>
				<Auth
					variant="ResetPassword"
					containerVariant="single-column"
					ScreenContainer={ScreenContainer}
				/>
			</Route>,
		],
		// mainly for login and onboarding only, landing on these
		// while authenticated redirects to home
		unauthenticated: [
			<Route path={routes.onboard.path}>
				<Auth variant="Onboard" ScreenContainer={ScreenContainer} />
			</Route>,
			<Route path={routes.login.path}>
				<Auth variant="Login" ScreenContainer={ScreenContainer} />
			</Route>,
			<Route path={routes.register.path}>
				<Auth variant="Register" ScreenContainer={ScreenContainer} />
			</Route>,
		],
		private: [
			<Route path={routes.logout.path}>
				<Logout />
			</Route>,
			<VerifyEmailRoute path={routes.createFirstAccount.path}>
				<Auth
					variant="Create"
					ScreenContainer={ScreenContainer}
					isCreateFirstAccountRoute={true}
				/>
			</VerifyEmailRoute>,
			<VerifyEmailRoute path={routes.createAccount.path}>
				<Auth variant="Create" ScreenContainer={ScreenContainer} />
			</VerifyEmailRoute>,
			<Route path={routes.selectAccountLegacy.path}>
				<Auth
					variant="SelectAccountLegacy"
					containerVariant="single-column"
					ScreenContainer={ScreenContainer}
				/>
			</Route>,
			<HasAccountRoute path={routes.selectAccount.path}>
				<Auth
					variant="SelectAccount"
					containerVariant="single-column"
					ScreenContainer={ScreenContainer}
				/>
			</HasAccountRoute>,
			<Route path={routes.verifyEmail.path}>
				<Auth variant="VerifyEmail" ScreenContainer={ScreenContainer} />
			</Route>,
			<Route path={routes.upgrade.path}>
				<Auth
					variant="RegisterUpgrade"
					containerVariant="single-column"
					ScreenContainer={ScreenContainer}
				/>
			</Route>,
			<HasAccountRoute path={routes.integrateSlack.path}>
				<ScreenContainer>
					<IntegrateWithSlack />
				</ScreenContainer>
			</HasAccountRoute>,
			<HasAccountRoute path={routes.subscribe.path}>
				<ScreenContainer variant="two-column">
					<Subscribe />
				</ScreenContainer>
			</HasAccountRoute>,
			<HasAccountRoute path={routes.checkoutSuccess.path}>
				<ScreenContainer>
					<CheckoutSuccess />
				</ScreenContainer>
			</HasAccountRoute>,
		],
	};
}
