import React from 'react';
import { TableCell } from '@material-ui/core';
import { ActionsButton } from './ActionsButton';
import { TableChip } from '../components/TableChip';
import { UserInfo } from './UserInfo';
import { firebaseTimestampToDate, useCurrentUser } from '@common';
import { useCurrentAccount } from '@common/contexts/useCurrentAccount';
import { Time } from '@common/components';

export function AccountMemberTableRow({ member, showActionsForMember }) {
	const { currentAccount } = useCurrentAccount();
	const { uid } = useCurrentUser();

	const isOwner = member.role === 'owner';
	const isCurrentUserRow = uid === member.id;
	const isCurrentUserOwnerOrAdmin = ['owner', 'admin'].includes(
		currentAccount?.role || '',
	);

	const actionsAllowed =
		!isOwner && (isCurrentUserOwnerOrAdmin || isCurrentUserRow);

	return (
		<>
			<TableCell align="center">
				{actionsAllowed && (
					<ActionsButton
						member={member}
						showActions={showActionsForMember}
					/>
				)}
			</TableCell>
			<TableCell>
				<UserInfo user={member} />
			</TableCell>
			<TableCell>
				{/* we do not display chip for members */}
				{member.role && member.role !== 'member' && (
					<TableChip value={member.role} />
				)}
				{!member.role && <TableChip value="invited" />}
			</TableCell>
			<TableCell>
				<Time timestamp={firebaseTimestampToDate(member.updatedAt)} />
			</TableCell>
		</>
	);
}
