const sliderSettings = {
	dots: false,
	infinite: true,
	speed: 500,
	slide: 'div',
	slidesToScroll: 1,
	swipeToSlide: true,
	arrows: true,
};

const ctaSettings = {
	...sliderSettings,
	slidesToShow: 3,
	responsive: [
		{
			breakpoint: 1440,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 960,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 470,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const gamesAndCategoriesSettings = {
	...sliderSettings,
	slidesToShow: 6,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 5,
			},
		},
		{
			breakpoint: 960,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 470,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const checkinSettings = {
	...sliderSettings,
	slidesToShow: 6,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 5,
			},
		},
		{
			breakpoint: 960,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 470,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const connectSettings = {
	...sliderSettings,
	slidesToShow: 4,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 960,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 470,
			settings: {
				slidesToShow: 2,
			},
		},
	],
};

const experiencesSettings = {
	...sliderSettings,
	slidesToShow: 4,
	// className: "center",
	// centerMode: true,
	centerPadding: '50px',
	rows: 2,
	responsive: [
		{
			breakpoint: 1400,
			settings: {
				slidesToShow: 4,
			},
		},
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 960,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 530,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

export {
	gamesAndCategoriesSettings,
	checkinSettings,
	ctaSettings,
	sliderSettings,
	experiencesSettings,
	connectSettings,
};
