import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	IconButton,
	Paper,
	Snackbar,
	Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, DocumentHead, Loading, registerError } from '@common';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useCurrentAccount } from '@common/contexts';
import APITextField from '@common/components/APITextField';

const useStyles = makeStyles((theme) => ({
	paper: {
		borderRadius: '1rem',
		marginTop: '1rem',
		marginBottom: '1rem',
		padding: '1.25rem',
		background: '#F8F8F8',
	},
	heading: {
		paddingBottom: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
	},
	input: {
		background: 'white',
		[theme.breakpoints.down('sm')]: {
			'& > .MuiInputBase-root > .MuiInputBase-input': {
				fontSize: '.875rem',
			},
		},
	},
	correctOption: {
		fontWeight: 700,
		color: '#6FCF97',
	},
	incorrectOption: {
		fontWeight: 700,
		color: 'rgba(235, 87, 87, 0.6)',
	},
	toggleLabel: {
		'& > .MuiFormControlLabel-label': {
			fontSize: '1rem',
		},
	},
	accordionSummary: {
		paddingRight: 0,
		'&.Mui-focused': {
			backgroundColor: 'transparent',
		},
	},
	accordionDetails: {
		padding: '0 40px 0 25px',
	},
	count: {
		fontWeight: 700,
		fontSize: '1.5rem',
		color: '#757575',
		lineHeight: 1.2,
	},
	validationMessage: {
		fontSize: 14,
		fontWeight: 600,
		color: '#757575',
	},
}));

const dummyQuestions = [
	{
		question: 'What is the collective noun for a group of unicorns?',
		options: ['Blessing', 'Glory', 'Marvel', 'Hodgepodge'],
	},
	{
		question:
			'What is the answer to the Ultimate Question of Life, the Universe, and Everything?',
		options: ['42', '40', '46', '44'],
	},
	{
		question: "what's the airspeed velocity of a European unladen swallow?",
		options: [
			'24 mph / 38.5 km/h',
			'15 mph / 24 km/h',
			'40 mph / 64 km/h',
			'17 mph / 27 km/h',
		],
	},
	{
		question: 'What is the cross between a donkey and a zebra known as?',
		options: ['Zonkey', 'Debra', 'Burro', 'Equus quagga'],
	},
	{
		question: 'What is Scooby Doo’s full name?',
		options: [
			'Scoobert Doo',
			'Scooberto Doo',
			'Scooby Doo',
			'Scoobert Doobert',
		],
	},
];

export const TriviaCustomizer = () => {
	const history = useHistory();
	let { categoryID } = useParams();
	const classes = useStyles();

	const {
		currentAccount,
		fetchCustomCategory,
		addCustomCategoryQuestion,
		deleteCustomCategoryQuestion,
	} = useCurrentAccount();
	const [loading, setLoading] = useState(true);
	const [addingQuestion, setAddingQuestion] = useState(false);
	const [snackbarConfig, setSnackbarConfig] = useState({
		isOpen: false,
		message: '',
		variant: 'info',
	});

	const [initialValues, setInitialValues] = useState({});

	useEffect(() => {
		async function fetchCategoryData() {
			const { data } = await fetchCustomCategory(categoryID);

			const values = {
				id: data.id,
				name: data.name,
				description: data.description,
				// mapping for selective fields as we don't want the form to override the stats or other private fields
				questionList: data.questionList.map((q) => ({
					id: q.id,
					question: q.question,
					options: q.options,
				})),
			};

			setInitialValues(values);
			setLoading(false);
		}

		fetchCategoryData();
	}, [categoryID, fetchCustomCategory]);

	const _deleteQuestion = async (questionID, index) => {
		try {
			await deleteCustomCategoryQuestion(questionID);

			const update = { ...initialValues };

			update.questionList.splice(index, 1);
			setInitialValues(update);
		} catch (error) {
			registerError(error);
		}
	};

	const _addQuestion = async (questionData = {}) => {
		try {
			setAddingQuestion(true);

			const { data } = await addCustomCategoryQuestion(
				categoryID,
				questionData,
			);

			// show a success snackbar
			setSnackbarConfig({
				isOpen: true,
				message: `Question ${
					initialValues.questionList.length + 1
				} added`,
				variant: 'info',
			});

			const update = { ...initialValues };

			// after question is added successfully, insert the new question in initial values array
			// for the UI to reflect the addition
			update.questionList.push(data);
			setInitialValues(update);
		} catch (error) {
			registerError(error);
		} finally {
			setAddingQuestion(false);
		}
	};

	return (
		<>
			<DocumentHead title="Trivia Customizer" />
			<Container maxWidth="md">
				<Typography variant="h2" className={classes.heading}>
					Trivia Customizer
				</Typography>

				{loading && <Loading label="loading..." />}

				{!loading && (
					<Box>
						<Paper className={classes.paper} elevation={3}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<APITextField
										label="Category name"
										value={initialValues?.name}
										fullWidth
										variant="outlined"
										multiline
										rowsMax={4}
										placeholder={
											'Your custom category title'
										}
										className={classes.input}
										name="name"
										api="trivia-updateCustomTriviaCategory"
										otherData={{
											accountID: currentAccount.accountId,
											categoryID: categoryID,
										}}
									/>
								</Grid>

								<Grid item xs={12}>
									<APITextField
										label="Category description"
										value={initialValues?.description}
										fullWidth
										variant="outlined"
										multiline
										rowsMax={4}
										placeholder={'Type a short description'}
										className={classes.input}
										name="description"
										api="trivia-updateCustomTriviaCategory"
										otherData={{
											accountID: currentAccount.accountId,
											categoryID: categoryID,
										}}
									/>
								</Grid>

								{/*<Grid item xs={12}>
											<SwitchField
												name="randomizeQuestions"
												label="Randomise questions during gameplay"
												labelProps={{
													className:
														classes.toggleLabel
												}}
											/>
										</Grid>*/}

								<Grid item>
									<Typography
										className={classes.validationMessage}
									>
										Minimum of 5 questions required to
										publish a custom category
									</Typography>
								</Grid>
							</Grid>
						</Paper>

						{initialValues?.questionList.map((Q, idx) => {
							const dummyQ =
								dummyQuestions[idx % dummyQuestions.length];

							return (
								<Paper
									key={Q.id}
									className={classes.paper}
									elevation={3}
								>
									<Accordion
										style={{
											background: 'transparent',
											boxShadow: 'none',
										}}
									>
										<Box
											display="flex"
											alignItems="flex-start"
										>
											<Typography
												className={classes.count}
											>
												{idx + 1}
											</Typography>

											<Box width="100%">
												<Box pl={2}>
													<Typography variant="h5">
														Question
													</Typography>
												</Box>
												<AccordionSummary
													expandIcon={
														<ExpandMoreIcon />
													}
													className={
														classes.accordionSummary
													}
												>
													<APITextField
														value={Q.question}
														fullWidth
														variant="outlined"
														multiline
														rowsMax={3}
														placeholder={
															dummyQ.question
														}
														className={
															classes.input
														}
														name="question"
														api="trivia-updateCategoryQuestion"
														otherData={{
															accountID:
																currentAccount.accountId,
															questionID: Q.id,
														}}
													/>
												</AccordionSummary>
											</Box>
										</Box>

										<AccordionDetails
											className={classes.accordionDetails}
										>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<Typography variant="h6">
														Correct Option
													</Typography>

													<APITextField
														value={Q.options[0]}
														fullWidth
														variant="outlined"
														multiline
														rowsMax={3}
														placeholder={
															dummyQ.options[0]
														}
														className={
															classes.input
														}
														name="Options_0"
														api="trivia-updateCategoryQuestion"
														otherData={{
															accountID:
																currentAccount.accountId,
															questionID: Q.id,
														}}
														InputProps={{
															className:
																classes.correctOption,
														}}
													/>
												</Grid>

												<Grid item xs={12}>
													<Typography variant="h6">
														Incorrect Options
													</Typography>
													<APITextField
														value={Q.options[1]}
														fullWidth
														variant="outlined"
														multiline
														rowsMax={3}
														placeholder={
															dummyQ.options[1]
														}
														className={
															classes.input
														}
														name="Options_1"
														api="trivia-updateCategoryQuestion"
														otherData={{
															accountID:
																currentAccount.accountId,
															questionID: Q.id,
														}}
														InputProps={{
															className:
																classes.incorrectOption,
														}}
													/>
												</Grid>

												<Grid item xs={12}>
													<APITextField
														value={Q.options[2]}
														fullWidth
														variant="outlined"
														multiline
														rowsMax={3}
														placeholder={
															dummyQ.options[2]
														}
														className={
															classes.input
														}
														name="Options_2"
														api="trivia-updateCategoryQuestion"
														otherData={{
															accountID:
																currentAccount.accountId,
															questionID: Q.id,
														}}
														InputProps={{
															className:
																classes.incorrectOption,
														}}
													/>
												</Grid>

												<Grid item xs={12}>
													<APITextField
														value={Q.options[3]}
														fullWidth
														variant="outlined"
														multiline
														rowsMax={3}
														placeholder={
															dummyQ.options[3]
														}
														className={
															classes.input
														}
														name="Options_3"
														api="trivia-updateCategoryQuestion"
														otherData={{
															accountID:
																currentAccount.accountId,
															questionID: Q.id,
														}}
														InputProps={{
															className:
																classes.incorrectOption,
														}}
													/>
												</Grid>

												<Grid item xs={12}>
													<Box
														display="flex"
														justifyContent="flex-end"
													>
														<IconButton
															onClick={() => {}}
														>
															<FileCopyIcon />
														</IconButton>

														<IconButton
															onClick={() =>
																_deleteQuestion(
																	Q.id,
																	idx,
																)
															}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</Box>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Paper>
							);
						})}

						<Box display="flex" justifyContent="center">
							<Typography variant="subtitle2">
								Minimum of 5 questions to publish category
							</Typography>
						</Box>

						<Box display="flex" justifyContent="center">
							<Button
								loading={addingQuestion}
								fullWidth={false}
								onClick={() => _addQuestion()}
							>
								Add question
							</Button>

							<Box width={16} />

							<Button
								fullWidth={false}
								onClick={() => history.goBack()}
							>
								Save and exit
							</Button>
						</Box>
					</Box>
				)}
			</Container>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarConfig.isOpen}
				onClose={() =>
					setSnackbarConfig({ ...snackbarConfig, isOpen: false })
				}
				autoHideDuration={5000}
			>
				<Alert
					icon={false}
					variant="filled"
					severity={snackbarConfig.variant}
					onClose={() =>
						setSnackbarConfig({ ...snackbarConfig, isOpen: false })
					}
				>
					{snackbarConfig.message}
				</Alert>
			</Snackbar>
		</>
	);
};
