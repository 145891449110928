import React, { useState } from 'react';
import { Box, CircularProgress, TextField } from '@material-ui/core';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import { makeStyles } from '@material-ui/styles';
import { registerError } from '../errors';
import { useFirebase } from 'react-redux-firebase';

const useStyle = makeStyles(() => ({
	container: {
		position: 'relative',
	},
	actionState: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-45%)',
		right: '1rem',
	},
	progress: {
		'&.MuiCircularProgress-colorPrimary': {
			color: '#BDBDBD',
		},
	},
	check: {
		color: '#7BC67E',
	},
}));

const APITextField = ({
	name,
	value,
	onFieldUpdated,
	onFieldUpdateError,
	api,
	otherData,
	...rest
}) => {
	const classes = useStyle();
	const firebase = useFirebase();
	const [text, setText] = useState(value || '');

	// this is used to determine if the API call is necessary or not
	const [previousText, setPreviousText] = useState(value || '');

	// 'loading' || 'success' || ''
	const [APIActionState, setAPIActionState] = useState('');

	const _handleChange = (evt) => {
		setText(evt.target.value || '');
	};

	const _onBlur = () => {
		// if the previous text is not same as current text value
		// trigger API call to update value
		// else pass
		if (previousText !== text) {
			_updateField({
				...(otherData || {}),
				[name]: text,
			});

			setPreviousText(text);
		}
	};

	const _updateField = async (payload) => {
		try {
			setAPIActionState('submitting');

			await firebase.functions().httpsCallable(api)(payload);
			onFieldUpdated && onFieldUpdated(text);

			setAPIActionState('success');
			setTimeout(() => {
				setAPIActionState('');
			}, 1500);
		} catch (error) {
			registerError(error);
			setAPIActionState('');
			onFieldUpdateError && onFieldUpdateError(error);
		}
	};

	return (
		<Box style={{ width: '100%' }} className={classes.container}>
			<TextField
				{...rest}
				value={text}
				onChange={_handleChange}
				onBlur={_onBlur}
			/>
			<Box className={classes.actionState}>
				{APIActionState === 'submitting' && (
					<CircularProgress size={20} className={classes.progress} />
				)}
				{APIActionState === 'success' && (
					<CheckCircleOutlinedIcon className={classes.check} />
				)}
			</Box>
		</Box>
	);
};

export default APITextField;
