import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { EditProfileFieldList } from './fieldList';
import { PhotoEditor } from './photoEditor';
import { ProfileEditState } from './editState';
import { ProfileField } from '@contracts/member-profile';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

const useStyles = makeStyles((theme) => ({
	grid: {
		display: 'grid',
		gridTemplateColumns: `.7fr .3fr`,
		gridTemplateRows: `auto auto`,

		gap: theme.spacing(2),

		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: `1fr`,
			gridTemplateRows: `auto`,
		},
	},
	topFieldsCell: {},
	photoCell: {
		[theme.breakpoints.down('xs')]: {
			order: -1,
		},

		paddingTop: theme.spacing(2),

		display: 'grid',
		alignContent: 'start',
		justifyItems: 'center',

		gap: theme.spacing(2),
	},
	bottomFieldsCell: {
		gridColumn: `1 / 3`,
		[theme.breakpoints.down('xs')]: {
			gridColumn: `1 / 2`,
		},
	},
}));

type Props = Pick<ProfileEditState, 'useFieldValue' | 'getField'> & {
	topSectionFields: ProfileField[];
	bottomSectionFields: ProfileField[];
};

export const EditProfileDialogueContent: React.ComponentType<Props> =
	React.memo((props) => {
		const styles = useStyles();
		const {
			useFieldValue,
			topSectionFields,
			bottomSectionFields,
			getField,
		} = props;
		const photoUrlField = React.useMemo(
			() => getField('photoURL'),
			[getField],
		);
		return (
			<MuiPickersUtilsProvider utils={LuxonUtils}>
				<div className={styles.grid}>
					<div className={styles.topFieldsCell}>
						<EditProfileFieldList
							fields={topSectionFields}
							useFieldValue={useFieldValue}
						/>
					</div>
					<div className={styles.photoCell}>
						<PhotoEditor
							field={photoUrlField}
							useFieldValue={useFieldValue}
						/>
					</div>
					{bottomSectionFields.length > 0 && (
						<div className={styles.bottomFieldsCell}>
							<Typography variant="h4">
								Working With Me
							</Typography>
							<EditProfileFieldList
								fields={bottomSectionFields}
								useFieldValue={useFieldValue}
							/>
						</div>
					)}
				</div>
			</MuiPickersUtilsProvider>
		);
	});
