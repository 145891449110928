import React from 'react';
import {
	Box,
	Button,
	Dialog,
	IconButton,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Showcase } from './Showcase';
import { connectSettings } from './SliderSettings';
import { env } from '@common/environment';
import { ProChip } from '@common/components/proChip';
import { Card } from './card';
import { useCurrentAccount } from '@common/contexts';
import { buildCrossDomainUrl } from '@common/routes/useCrossDomainUrl';

const useStyles = makeStyles((theme) => ({
	connectCard: {
		height: '280px',
		paddingBottom: '1rem',
	},
	playerCount: {
		marginTop: theme.typography.fontSize / 4,
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize - 4,
		fontWeight: 'bold',
	},
	chip: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	border: {
		borderRadius: '1.5rem',
	},
	modalPaper: {
		padding: 10,
		maxWidth: 800,
	},
	closeButton: {
		position: 'absolute',
		top: 15,
		right: 15,
		padding: 0,
	},
	modalWrapper: {
		position: 'relative',
		minWidth: '100%',
		display: 'grid',
		gridTemplateAreas: '"illustration" "buttons" "content"',
		gridTemplateColumns: '1fr',
		gridTemplateRows: '1fr auto auto',
		gridGap: theme.spacing(3),
		padding: theme.spacing(2),
		paddingRight: theme.spacing(1.5),

		[theme.breakpoints.up('md')]: {
			gridTemplateAreas: '"illustration content" "illustration buttons"',
			gridTemplateColumns: '1fr 1fr',
			gridTemplateRows: '1fr',
		},
	},
	illustrationContainer: {
		gridArea: 'illustration',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	illustration: {
		width: '100%',
		height: '100%',

		[theme.breakpoints.up('sm')]: {
			width: 400,
			height: 400,
		},
	},
	content: {
		gridArea: 'content',
	},
	title: {
		fontWeight: 900,
		textAlign: 'center',
		marginBottom: theme.spacing(1),

		[theme.breakpoints.up('md')]: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(2),
		},
	},
	pointText: {
		fontSize: '0.875rem',
		marginBottom: theme.spacing(1),
	},
	buttons: {
		gridArea: 'buttons',
	},
}));

const IntroModal = ({ open, onClose, pack, ...rest }) => {
	const classes = useStyles();
	const { currentAccountId } = useCurrentAccount();
	const isVideo = pack.introduction?.explainerVideoUrl.indexOf('.mp4') > 0;

	const envConfig = env();

	const primaryLink = pack?.links?.find((link) => link.rel === 'primary');
	const secondaryLink = pack?.links?.find((link) => link.rel === 'secondary');

	const primaryUrl =
		primaryLink &&
		buildCrossDomainUrl({
			boundToOrigin: envConfig.connectUrl,
			location: primaryLink.href,
			accountId: currentAccountId,
		});
	const secondaryUrl =
		secondaryLink &&
		buildCrossDomainUrl({
			boundToOrigin: envConfig.connectUrl,
			location: secondaryLink.href,
			accountId: currentAccountId,
		});

	return (
		<Dialog
			open={open}
			onClose={onClose}
			{...rest}
			PaperProps={{
				className: `${classes.border} ${classes.modalPaper}`,
			}}
		>
			<Box className={classes.modalWrapper}>
				<Box className={classes.illustrationContainer}>
					{isVideo ? (
						<video
							className={`${classes.border} ${classes.illustration}`}
							src={pack.introduction?.explainerVideoUrl}
							title={pack.name}
							playsInline={true}
							loop={true}
							controls={false}
							autoPlay={true}
							muted
						/>
					) : (
						<img
							className={`${classes.border} ${classes.illustration}`}
							src={pack.introduction?.explainerVideoUrl}
							alt={pack.name}
						/>
					)}
				</Box>
				<Box className={classes.content}>
					<Typography variant="h4" className={classes.title}>
						{pack.name} <ProChip />
					</Typography>
					{pack.introduction?.points?.map((point, index) => (
						<Typography key={index} className={classes.pointText}>
							{point}
						</Typography>
					))}
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					className={classes.buttons}
				>
					{primaryLink && (
						<Button
							variant="contained"
							color="default"
							href={primaryUrl}
						>
							{primaryLink.label}
						</Button>
					)}
					{secondaryLink && (
						<Button
							variant="outlined"
							color="default"
							href={secondaryUrl}
						>
							{secondaryLink.label}
						</Button>
					)}
				</Box>
			</Box>
			<IconButton
				onClick={onClose}
				aria-label="close"
				className={classes.closeButton}
			>
				<CloseIcon />
			</IconButton>
		</Dialog>
	);
};

const ConnectCard = ({ pack, onSelect }) => {
	const classes = useStyles();

	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.up('md')) ? 'md' : 'xs';
	const connectTile = pack.tiles[breakpoint];

	return (
		<Card
			className={classes.connectCard}
			avatarUrl={connectTile}
			avatarAlt={pack.avatarAlt}
			onSelect={() => onSelect(pack)}
		/>
	);
};

const ConnectShowcase = ({ connectData }) => {
	const [intro, setIntro] = React.useState({
		isOpen: false,
		pack: {},
	});

	const _onSelect = (pack) => {
		setIntro({
			isOpen: true,
			pack: pack,
		});
	};

	const _onDeselect = () => {
		setIntro({
			isOpen: false,
			pack: {},
		});
	};

	const selectedPack = connectData?.find((pack) => pack.id === intro.pack.id);

	return (
		<>
			<Showcase
				title="Remote Social Connect"
				titleProBadge
				subtitle="Start engaging conversations in your Slack workspace"
				sliderSetting={connectSettings}
				emptyMessage={
					<>
						<h4>You don't have access to Remote Social Connect.</h4>
						<p>
							That probably shouldn't happen, so please get in
							touch with support.
						</p>
						{env().name === 'local' && (
							<pre>DEV: Try running `npm run seed-db`</pre>
						)}
					</>
				}
			>
				{connectData.map((pack, index) => {
					return (
						<div key={index}>
							<ConnectCard pack={pack} onSelect={_onSelect} />
						</div>
					);
				})}
			</Showcase>
			{selectedPack && (
				<IntroModal
					open={intro.isOpen}
					onClose={_onDeselect}
					pack={intro.pack}
					aria-labelledby="connect-introduction"
					maxWidth={false}
				/>
			)}
		</>
	);
};

export { ConnectShowcase };
