import React from 'react';
import { PayloadOf } from '@common';
import { Container } from '@material-ui/core';
import { BreadcrumbsContainer } from '../../components/breadcrumbs/container';
import { BreadcrumbSegment } from '../../components/breadcrumbs/segment';
import { hubRoutes } from '@common/routes/hubRoutes';
import { useState } from './state';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Head } from './components/head';
import { WorkingWithMe } from './components/workingWithMe';
import { AboutPanel } from './components/aboutPanel';
import {
	externalSpacing,
	workingWithMeRatio,
	workingWithMeRatioSmDown,
} from './components/sharedStyles';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => {
	return createStyles({
		contentRoot: {},
		headingContainer: {
			gridColumn: `1 / 3`,
			gridRow: 1,
			[theme.breakpoints.down('xs')]: {},
		},
		paperContainer: {
			gap: theme.spacing(externalSpacing),
			display: 'grid',
			gridTemplateRows: 'auto auto 1fr',
			flexDirection: 'row',
			gridTemplateColumns: `minmax(0, ${workingWithMeRatio}fr) minmax(0, ${
				1 - workingWithMeRatio
			}fr)`,

			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: `minmax(0, ${workingWithMeRatioSmDown}fr) minmax(0, ${
					1 - workingWithMeRatioSmDown
				}fr)`,
			},
		},
		workingWithMePaper: {
			gridColumn: 1,
			gridRow: `2 / 4`,
			[theme.breakpoints.down('xs')]: {
				gridColumn: `1 / 3`,
				gridRow: 3,
			},
		},
		rightInfoPaper: {
			gridColumn: 2,
			gridRow: `2 / 3`,
			[theme.breakpoints.down('xs')]: {
				gridColumn: `1 / 3`,
				gridRow: 2,
			},
		},
	});
});

const aboutFields = ['email', 'birthday', 'employmentStartedAt'] as const;

export const MemberProfilePageLayout: React.ComponentType<
	PayloadOf<ReturnType<typeof useState>>
> = (props) => {
	const styles = useStyles();
	const location = useLocation();
	const {
		profile,
		accountId,
		uid,
		editProfileDialogueElement,
		openEditProfileDialogue,
	} = props;

	const hasAboutFields = React.useMemo(() => {
		const propKeys = Object.keys(profile.props);
		const hasAboutFields = aboutFields.some((field) =>
			propKeys.includes(field),
		);
		return hasAboutFields;
	}, [profile.props]);

	return (
		<>
			<BreadcrumbsContainer>
				<BreadcrumbSegment to={hubRoutes.home({ location })}>
					Dashboard
				</BreadcrumbSegment>
				<BreadcrumbSegment
					to={hubRoutes.accounts.memberProfile({
						accountId,
						uid,
						location,
					})}
					current
				>
					{profile.props.displayName}
				</BreadcrumbSegment>
			</BreadcrumbsContainer>
			<Container
				fixed
				disableGutters
				maxWidth="md"
				className={styles.contentRoot}
			>
				<div className={styles.paperContainer}>
					<Head
						className={styles.headingContainer}
						profile={profile}
						onEdit={openEditProfileDialogue}
					/>
					<WorkingWithMe
						className={styles.workingWithMePaper}
						profile={profile}
					/>
					{hasAboutFields && (
						<AboutPanel
							className={styles.rightInfoPaper}
							profile={profile}
						/>
					)}
				</div>
				{editProfileDialogueElement}
			</Container>
		</>
	);
};
