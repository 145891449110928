import { MemberProfile } from '@contracts/member-profile';
import { Paper, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { internalSpacing, paperStyles } from './sharedStyles';
import { ReactComponent as MemberProfileEmpty } from '../../../img/member-profile-empty.svg';
import { LinkButton } from '@common/components';
import { hubRoutes } from '@common/routes/hubRoutes';
import { useLocation } from 'react-router';
import { ensureExists } from '@common/utils';
import { useCurrentAccount, useCurrentUser } from '@common';

const useStyles = makeStyles((theme) => {
	return createStyles({
		workingWithMePaper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(internalSpacing * 1.75),

			...paperStyles(theme),
		},
		emptyProfileContainer: {
			display: 'flex',
			flexDirection: 'column',
			placeItems: 'center',
			justifyContent: 'center',
			gap: theme.spacing(2),
		},
		emptyProfileImageContainer: {
			marginTop: theme.spacing(4),
		},
		emptyProfileMessage: {
			maxWidth: '40ch',
			textAlign: 'center',
		},
		textField: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(0, 0),
		},
		imageField: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(0, 0),
		},
	});
});

type Props = {
	className?: string;
	profile: MemberProfile;
};

function dontBreakLastTwoWords(text?: string) {
	if (!text) {
		return text;
	}
	return text.replace(/\s+(?=\S*$)/, '\u00a0' /* &nbsp; */);
}

export const WorkingWithMe: React.ComponentType<Props> = ({
	className,
	profile,
}) => {
	const location = useLocation();
	const { currentAccountId } = useCurrentAccount();
	const styles = useStyles();
	const nonEmptyWorkingWithMeFields = useMemo(() => {
		return profile.fields.filter(
			(field) =>
				field.fieldCategoryId === 'working-with-me' &&
				field.type !== 'public-image-url' &&
				profile.values[field.profileFieldId],
		);
	}, [profile]);
	const { uid } = useCurrentUser();
	return (
		<Paper
			className={clsx(styles.workingWithMePaper, className)}
			elevation={5}
		>
			<Typography variant="h3" gutterBottom>
				Working with me
			</Typography>
			{nonEmptyWorkingWithMeFields.length === 0 && (
				<div className={styles.emptyProfileContainer}>
					<div className={styles.emptyProfileImageContainer}>
						<MemberProfileEmpty />
					</div>
					<Typography
						variant="body1"
						gutterBottom
						className={styles.emptyProfileMessage}
					>
						Looks like {profile.props.displayName ?? 'Anonymous'}{' '}
						hasn't added their Working With Me guide yet.
					</Typography>
					{uid === profile.uid && (
						<LinkButton
							to={hubRoutes.accounts.createMemberProfile({
								location,
								accountId: ensureExists(currentAccountId),
							})}
							variant="contained"
							color="primary"
						>
							Create My Profile
						</LinkButton>
					)}
				</div>
			)}
			{nonEmptyWorkingWithMeFields.length > 0 && (
				<>
					{nonEmptyWorkingWithMeFields.map((field) =>
						field.type === 'public-image-url' ? (
							<div
								className={clsx(styles.imageField)}
								key={field.profileFieldId}
							>
								<Typography variant="h6" gutterBottom>
									{field.question}
								</Typography>
								<img
									src={profile.values[field.profileFieldId]}
									alt={field.label}
								/>
							</div>
						) : (
							<div
								className={clsx(styles.textField)}
								key={field.profileFieldId}
							>
								<Typography variant="h6" gutterBottom>
									{field.question}
								</Typography>
								<Typography variant="body2" gutterBottom>
									{dontBreakLastTwoWords(
										profile.values[field.profileFieldId],
									)}
								</Typography>
							</div>
						),
					)}
				</>
			)}
		</Paper>
	);
};
