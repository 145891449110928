import { ProfileField } from '@contracts/member-profile';
import {
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import React from 'react';
import { ProfileEditState } from './editState';
import { DateTime } from 'luxon';
import CloseIcon from '@material-ui/icons/Close';

type Props = Pick<ProfileEditState, 'useFieldValue'> & {
	field: ProfileField;
};

const useStyles = makeStyles((_theme) => ({
	datePickerRoot: {
		[`&:hover $endAdornment`]: {
			visibility: 'visible',
		},
	},
	endAdornment: {
		// align with Autocomplete component
		position: 'absolute',
		right: `9px`,

		visibility: 'hidden',
	},
}));

export const FieldEditor: React.ComponentType<Props> = React.memo(
	({ field, useFieldValue }) => {
		const [value, setValue] = useFieldValue(field.profileFieldId);
		const styles = useStyles();
		const onChange: React.ChangeEventHandler<
			HTMLTextAreaElement | HTMLInputElement
		> = React.useCallback(
			(event) => setValue(event.target.value),
			[setValue],
		);
		const onOptionChange = React.useCallback(
			(_event: React.ChangeEvent<{}>, value: string | null) =>
				setValue(value || ''),
			[setValue],
		);
		const onDateChange = React.useCallback(
			(date: DateTime | null) => {
				setValue(date?.toISO() || '');
			},
			[setValue],
		);
		const clear: React.MouseEventHandler<HTMLButtonElement> =
			React.useCallback(
				(ev) => {
					ev.preventDefault();
					ev.stopPropagation();
					setValue('');
				},
				[setValue],
			);
		switch (field.type) {
			case 'text': {
				const options = field.options;
				if (options && options.length > 0) {
					return (
						<Autocomplete
							value={value}
							onChange={onOptionChange}
							onInputChange={onOptionChange}
							options={options}
							freeSolo
							renderInput={(params) => (
								<TextField
									{...params}
									label={field.label}
									fullWidth
									variant="outlined"
									helperText={field.question}
								/>
							)}
						/>
					);
				} else {
					return (
						<TextField
							label={field.label}
							fullWidth
							variant="outlined"
							helperText={field.question}
							value={value}
							onChange={onChange}
						/>
					);
				}
			}
			case 'multi-line-text': {
				return (
					<TextField
						label={field.label}
						value={value}
						onChange={onChange}
						fullWidth
						variant="outlined"
						multiline
						helperText={`${field.question} Use Enter key for a new line.`}
						autoCapitalize="true"
						autoCorrect="true"
					/>
				);
			}
			case 'date': {
				return (
					<DatePicker
						className={styles.datePickerRoot}
						label={field.label}
						value={value || null}
						onChange={onDateChange}
						inputVariant="outlined"
						fullWidth
						helperText={field.question}
						clearable
						InputProps={{
							endAdornment: (
								<InputAdornment
									position={'end'}
									className={styles.endAdornment}
								>
									<IconButton onClick={clear} size="small">
										<CloseIcon fontSize="small" />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				);
			}
			default:
				return null;
		}
	},
);
