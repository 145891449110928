import React from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Box,
	Container,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TriviaSettings } from './fragments/TriviaSettings';
import { DocumentHead } from '@common';

const useStyles = makeStyles(
	(/** @type {import('@material-ui/core').Theme} */ theme) => ({
		paper: {
			padding: theme.spacing(3),
			borderRadius: '1.25rem',

			[theme.breakpoints.down('sm')]: {
				padding: 0,
				boxShadow: 'none',
				background: 'transparent',
			},
		},
		heading: {
			paddingBottom: theme.spacing(2),

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(2),
			},
		},
		subheading: {
			fontSize: '1.25rem',
			color: '#444444',
			fontWeight: 700,
			[theme.breakpoints.down('sm')]: {
				fontSize: '1rem',
			},
		},
		gameSelector: {
			marginTop: '0.8rem',
			marginBottom: '2rem',
			'& > .MuiSelect-select': {
				padding: '.75rem',
			},
		},
	}),
);

export const GameSettings = () => {
	let history = useHistory();
	let location = useLocation();
	const query = queryString.parse(location.search);

	const classes = useStyles();

	const games = [
		{
			label: 'Trivia',
			content: <TriviaSettings />,
		},
		{
			label: 'Bingo',
			content: (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					<Typography>Coming soon</Typography>
				</Box>
			),
		},
	];

	const [currentTab, setCurrentTab] = React.useState(
		games.findIndex((game) => game.label === (query?.game || 'Trivia')),
	);

	const onTabChange = (event, tab) => {
		history.replace({
			search: `?activity=${games[tab].label}`,
		});
		setCurrentTab(tab);
	};

	return (
		<>
			<DocumentHead title="Activity Settings" />
			<Container maxWidth="md">
				<Typography variant="h2" className={classes.heading}>
					Activity Settings
				</Typography>
				<Paper elevation={5} className={classes.paper}>
					<Grid container>
						<Grid item xs={12}>
							<Typography
								variant="h3"
								className={classes.subheading}
							>
								Select game
							</Typography>

							<Select
								fullWidth
								className={classes.gameSelector}
								variant="outlined"
								value={currentTab}
								onChange={(evt) =>
									onTabChange(evt, evt.target.value)
								}
							>
								{games.map((game, idx) => (
									<MenuItem key={idx} value={idx}>
										{game.label}
									</MenuItem>
								))}
							</Select>

							{games[currentTab].content}
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</>
	);
};
