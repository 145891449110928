import { HubAppRouter } from './routes/router';
import { HubProvider } from './store/context';

export function App() {
	return (
		<HubProvider>
			<HubAppRouter />
		</HubProvider>
	);
}
