export const checkin = [
	{
		id: 'checkin',
		name: 'Check-in',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/checkin/1-intro-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/checkin/1-intro-md.png',
		},
		avatarAlt: 'Remote Social Check-in',
		priority: 0,
		category: 'home',
		introduction: {
			explainerVideoUrl:
				'https://checkin.remotesocial.io/static/media/introduction.7b95363c.mp4',
			points: [
				'Check-in with your team using a fun game-like interactive tool.',
				'Spark interesting stories within your team, while boosting alignment. Check-in is a tool backed by neuroscience to build rapport, check-in weekly with your team and humanise your meetings.',
			],
		},
		links: [
			{
				rel: 'primary',
				href: '/',
				label: 'Get started using Check-in',
			},
		],
	},
	{
		id: 'how-it-can-help',
		name: 'How It Can Help',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/checkin/2-how-it-can-help-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/checkin/2-how-it-can-help-md.png',
		},
		avatarAlt: 'Remote Social Check-in',
		priority: 0,
		href: '/',
	},
	{
		id: 'boost-engagement',
		name: 'Boost Engagement',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/checkin/3-boost-engagement-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/checkin/3-boost-engagement-md.png',
		},
		avatarAlt: 'Boost Engagements',
		priority: 0,
		href: '/',
	},
	{
		id: 'encouraging-sharing',
		name: 'Encouraging Sharing',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/checkin/4-encouraging-sharing-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/checkin/4-encouraging-sharing-md.png',
		},
		avatarAlt: 'Encouraging Sharing',
		priority: 0,
		href: '/',
	},
	{
		id: 'breaking-down-barriers',
		name: 'Breaking Down Barriers',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/checkin/5-breaking-down-barriers-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/checkin/5-breaking-down-barriers-md.png',
		},
		avatarAlt: 'Breaking Down Barriers',
		priority: 0,
		href: '/',
	},
	{
		id: 'promoting-bonding-empathy',
		name: 'Promoting Bonding & Empathy',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/checkin/6-promoting-bonding-empathy-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/checkin/6-promoting-bonding-empathy-md.png',
		},
		avatarAlt: 'Promoting Bonding & Empathy',
		priority: 0,
		href: '/',
	},
];
