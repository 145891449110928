import React from 'react';
import {
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import { AccountMemberTableRow } from './AccountMemberTableRow';

const useStyles = makeStyles((theme) => ({
	table: {
		[`& > tbody > tr:last-child > td`]: {
			borderBottom: 'none',
		},
		[`& > thead,tbody > tr > td:first-child,th:first-child`]: {
			paddingLeft: theme.spacing(3),
		},
	},
	head: {
		background: '#E0E0E0',
	},
	noResult: {
		textAlign: 'center',
	},
}));

export function AccountMembersTable({ members, showActionsForMember }) {
	const classes = useStyles();
	return (
		<>
			<Table className={classes.table}>
				<TableHead className={classes.head}>
					<TableRow>
						<TableCell align="center" style={{ width: '10%' }}>
							Actions
						</TableCell>
						<TableCell style={{ width: '40%' }}>Name</TableCell>
						<TableCell style={{ width: '25%' }}>Role</TableCell>
						<TableCell
							style={{ width: '25%', whiteSpace: 'nowrap' }}
						>
							Last Activity
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{members.length === 0 && (
						<TableRow>
							<TableCell colSpan="4" className={classes.noResult}>
								<Typography>
									No results for filter criteria
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{members.map((member) => (
						<TableRow key={member.id}>
							<AccountMemberTableRow
								member={member}
								showActionsForMember={showActionsForMember}
							/>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</>
	);
}
