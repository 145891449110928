import {
	Container,
	Grid,
	makeStyles,
	Snackbar,
	Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { GamesShowcase } from './components/GamesShowcase';
import { DocumentHead, Loading } from '@common/components';
import SchedulerFlow from '@common/components/scheduling';
import { CtaShowcase } from './components/CtaShowcase';
import { ConnectShowcase } from './components/ConnectShowcase';
import { CheckinShowcase } from './components/CheckinShowcase';
import { CategoriesShowcase } from './components/CategoriesShowcase';
import { BannerCarousel } from './components/BannerCarousel';
import { GameIntroModal } from './components/gameIntroModal';
import { useState } from './state';
import { SubscriptionBanner } from '@common/components/subscriptionBanner';
import React from 'react';
import { AccountMembersSection } from './components/accountMembersSection';

const useStyles = makeStyles(() => ({
	displayName: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

export const Home: React.ComponentType = () => {
	const styles = useStyles();
	const {
		user,
		banners,
		ctas,
		checkin,
		connect,
		games,
		hubScheduler,
		gameIntroModal,
		snackBar,
		handleCtaSelection,
		handleOpenSnackBar,
		handleCloseSnackBar,
		handleOpenScheduler,
		handleCloseScheduler,
		handleOpenGameIntro,
		handleCloseGameIntro,
		memberProfilesEnabled,
		membersRequest,
	} = useState();

	if (
		memberProfilesEnabled &&
		(membersRequest.status === 'initial' ||
			membersRequest.status === 'pending')
	) {
		return <Loading showRandomLabels />;
	}

	return (
		<>
			<DocumentHead title="Choose an activity" />
			<SubscriptionBanner />
			<Container maxWidth="lg" fixed>
				<Grid container direction="column" spacing={8}>
					<Grid container direction="row" item>
						<Grid item xs={9}>
							<Typography
								variant="h1"
								className={styles.displayName}
							>
								Hi, {user.displayName}!
							</Typography>
						</Grid>
					</Grid>
					{memberProfilesEnabled ? (
						<Grid container direction="row" item>
							<Grid item xs={12}>
								<AccountMembersSection
									membersRequest={membersRequest}
								/>
							</Grid>
						</Grid>
					) : (
						<>
							<Grid item xs={12}>
								<BannerCarousel banners={banners} />
							</Grid>
							<Grid item xs={12}>
								<CtaShowcase
									ctas={ctas}
									onSelect={handleCtaSelection}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<ConnectShowcase connectData={connect} />
					</Grid>
					<Grid item xs={12}>
						<CheckinShowcase checkinData={checkin} />
					</Grid>
					<Grid item xs={12}>
						<GamesShowcase
							onGameSelected={handleOpenGameIntro}
							games={games}
						/>
					</Grid>
					<Grid item xs={12}>
						<CategoriesShowcase />
					</Grid>
				</Grid>
			</Container>
			{hubScheduler.isOpen && (
				<SchedulerFlow
					game={hubScheduler.game}
					games={games?.filter((g) => g.canSchedule)}
					isOpen={hubScheduler.isOpen}
					onRequestClose={handleCloseScheduler}
					onScheduleSuccess={() =>
						handleOpenSnackBar('Event scheduled!')
					}
				/>
			)}
			{gameIntroModal.isOpen && (
				<GameIntroModal
					open={gameIntroModal.isOpen}
					game={gameIntroModal.game}
					onClose={handleCloseGameIntro}
					onScheduleGame={() => {
						// reset the intro details and close the intro modal
						handleCloseGameIntro();
						// callback to handle opening game scheduler for correct game
						handleOpenScheduler(gameIntroModal.game);
					}}
				/>
			)}

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				open={snackBar.isOpen}
				onClose={handleCloseSnackBar}
				autoHideDuration={5000}
			>
				<Alert
					variant="filled"
					severity="success"
					onClose={handleCloseSnackBar}
				>
					{snackBar.data}
				</Alert>
			</Snackbar>
		</>
	);
};
