import { Redirect, RedirectProps, Route, RouteProps } from 'react-router-dom';
import { HasAccountRoute } from './hasAccountRoute';
import { VerifyEmailRoute } from './verifyEmailRoute';
import { AccountRolesRoute } from './accountRolesRoute';
import { SlackIntegratedRoute } from './slackIntegratedRoute';
import React from 'react';
import { isDevBuild } from '../../environment/isDevBuild';
import { ProPageRoute } from '@common/routes/user-land-routes/proPageRoute';

export type Allowed =
	| React.ReactElement<RouteProps>
	| React.ReactElement<RedirectProps>;

/**
 * Verify whether a route is a valid route that is whitelisted to
 * be used by CommonRouter
 */
export function isAllowed(element: React.ReactNode): element is Allowed {
	const allowedElements: React.JSXElementConstructor<any>[] = [
		Route,
		Redirect,
		VerifyEmailRoute,
		AccountRolesRoute,
		HasAccountRoute,
		SlackIntegratedRoute,
		ProPageRoute,
	];
	const isAllowed =
		React.isValidElement(element) &&
		typeof element.type !== 'string' &&
		allowedElements.includes(element.type);

	if (isDevBuild()) {
		const isReactFragment =
			(element as any)?.type?.toString() === 'Symbol(react.fragment)';

		if (!isAllowed && React.isValidElement(element) && !isReactFragment) {
			console.warn(
				'Route component is not in `@common/routes/user-land-routes/isAllowed` list:',
			);
			if (
				React.isValidElement(element) &&
				typeof element.type === 'string'
			) {
				console.warn(element.type);
			} else if (
				React.isValidElement(element) &&
				typeof element.type === 'function'
			) {
				console.warn(element.type.name);
			} else {
				console.warn(element);
			}
		}
	}

	return isAllowed;
}
