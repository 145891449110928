import React, { useContext } from 'react';
import { SlackChannel } from '@contracts/platform';
import { SlackIntegration } from '@contracts/platform/slack/integration';
import { useCurrentAccount } from '../../contexts';
import { useSlackIntegration } from '../../hooks';
import { isSuccess, RequestState } from '../../store-tools';
import { ensureExists } from '../../utils';
import { useSlackChannels } from './useSlackChannels';
import { useSlackUsers, UseSlackUsersResult } from './useSlackUsers';

export type SlackStore = {
	status: 'unknown' | 'needs-integration';
	integrationId?: string;
	integrationsRequest: RequestState<SlackIntegration[], SlackIntegration[]>;
	loadIntegrations: () => void;
	listChannelsRequest: RequestState<SlackChannel[], SlackChannel[]>;
	listChannels: () => void;
	listUsersRequest: UseSlackUsersResult['listUsersRequest'];
	listUsers: () => void;
};

const Context = React.createContext<SlackStore | null>(null);

type Props = {
	requiredScopes: string[];
};

export const SlackStoreProvider: React.ComponentType<Props> = ({
	requiredScopes,
	children,
}) => {
	const { currentAccount } = useCurrentAccount();

	const accountId = currentAccount?.accountId;

	const { integrationsRequest, loadIntegrations } = useSlackIntegration({
		accountId: accountId || undefined,
		requiredScopes: requiredScopes,
	});

	const integrationId = integrationsRequest.data[0]?.integrationId;
	const slackTeamId = integrationsRequest.data[0]?.team.id;

	const { listChannelsRequest, listChannels } = useSlackChannels({
		accountId,
		integrationId,
	});

	const { listUsersRequest, listUsers } = useSlackUsers({
		accountId,
		slackTeamId,
	});

	const value: SlackStore = React.useMemo(() => {
		return {
			status:
				isSuccess(integrationsRequest) && !integrationId
					? 'needs-integration'
					: 'unknown',
			integrationId,
			integrationsRequest,
			loadIntegrations,
			listChannelsRequest,
			listChannels,
			listUsersRequest,
			listUsers,
		};
	}, [
		integrationId,
		integrationsRequest,
		loadIntegrations,
		listChannelsRequest,
		listChannels,
		listUsersRequest,
		listUsers,
	]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useSlackStore() {
	const value = useContext(Context);
	return ensureExists(value);
}
