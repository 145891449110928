import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { UserAvatar } from '@common';

const useStyles = makeStyles((theme) => ({
	displayName: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'&:focus': {
			outline: 'none',
		},
	},
	userDetails: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.up('md')]: {
			marginLeft: 0,
		},
	},
	userEmail: {
		whiteSpace: 'nowrap',
		fontSize: theme.typography.fontSize,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	invitationEmail: {
		whiteSpace: 'nowrap',
		fontSize: theme.typography.fontSize,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginTop: theme.spacing(2),
	},
	avatar: {
		paddingTop: theme.spacing(0.5),
	},
}));

export function UserInfo({ user }) {
	const classes = useStyles();
	return (
		<Grid container direction="row">
			<Grid item xs={2} className={classes.avatar}>
				<UserAvatar
					withName={false}
					orientation="horizontal"
					user={user}
				/>
			</Grid>
			<Grid item xs={10}>
				<Grid
					container
					direction="column"
					className={classes.userDetails}
				>
					<Grid item xs={12}>
						<Typography className={classes.displayName}>
							{user.displayName}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography
							className={
								user.displayName
									? classes.userEmail
									: classes.invitationEmail
							}
						>
							{user.email}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
