import React from 'react';
import { useRolesCheck } from '../../hooks/useRolesCheck';
import { RequestState } from '../../store-tools';

type ValidState = {
	isOwner: boolean;
};

type State = RequestState<ValidState>;

export const useState = (): State => {
	const ownerRoleCheck = useRolesCheck({
		roles: ['owner'],
	});

	const result = React.useMemo<State>(() => {
		if (ownerRoleCheck.status !== 'pending') {
			return {
				status: 'success',
				data: {
					isOwner: ownerRoleCheck.status === 'allowed',
				},
			};
		} else {
			return { status: 'pending' as const };
		}
	}, [ownerRoleCheck]);

	return result;
};
