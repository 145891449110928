import React from 'react';
import { useCurrentAccount } from '../contexts';
import {
	Button,
	createStyles,
	Hidden,
	makeStyles,
	SvgIcon,
	Typography,
} from '@material-ui/core';
import { SubscriptionStatus } from '@contracts/billing/subscription';
import { DateTime } from 'luxon';
import { ReactComponent as ProTransparentBadge } from '../assets/images/pro-transparent.svg';
import { LinkButton } from '../components/linkButton';
import { routes } from '../routes';
import { RequestState } from '../store-tools';
import { useBilling } from '../hooks/useBilling';
import { useConfigFlag } from '../hooks/useConfigFlag';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
	return createStyles({
		subscriptionBanner: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			gap: theme.spacing(2, 0),
			width: '100%',
			/** hack due to padding on AppLayout.main */
			marginTop: theme.spacing(-5),
			marginBottom: theme.spacing(2),
			padding: theme.spacing(2, 2),
			backgroundColor: 'rgba(0, 125, 255, 0.08)',

			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(2, 4),
			},
		},
		proBadge: {
			width: '1.25rem',
			height: '1.25rem',
			marginRight: theme.spacing(0.5),
		},
	});
});

type ValidState = {
	billingEnabled: boolean;
	accountName: string | undefined;
	hasTrialled: boolean;
	subscriptionStatus: SubscriptionStatus;
	cancelsInDays: number | undefined;
	trialEndsInDays: number | undefined;
	navigateToBillingPortal: () => void;
};

type State = RequestState<ValidState>;

const useState = (): State => {
	const { isLoaded, currentAccount, error } = useCurrentAccount();
	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();
	const { navigateToBillingPortal } = useBilling();

	const result = React.useMemo(() => {
		if (isLoaded && currentAccount) {
			const accountName = currentAccount.name;
			const hasTrialled = Boolean(currentAccount.hasTrialled);
			const subscriptionStatus =
				currentAccount.subscription?.status ?? 'inactive';

			const cancelsInDays =
				currentAccount.subscription?.activeEndedAt &&
				currentAccount.subscription?.cancelInitiatedAt
					? Number(
							DateTime.fromISO(
								currentAccount.subscription?.activeEndedAt,
							)
								.diff(DateTime.now(), 'days')
								.days.toFixed(0),
					  )
					: undefined;

			const trialEndsInDays = currentAccount.subscription?.trialEndAt
				? Number(
						DateTime.fromISO(currentAccount.subscription.trialEndAt)
							.diff(DateTime.now(), 'days')
							.days.toFixed(0),
				  )
				: undefined;

			return {
				status: 'success' as const,
				data: {
					billingEnabled,
					accountName,
					hasTrialled,
					subscriptionStatus,
					cancelsInDays,
					trialEndsInDays,
					navigateToBillingPortal,
				},
			};
		}

		if (error) {
			return { status: 'error' as const, error };
		}

		return { status: 'pending' as const };
	}, [
		billingEnabled,
		currentAccount,
		error,
		isLoaded,
		navigateToBillingPortal,
	]);

	return result;
};

function calculateDayText(dayAmount: number) {
	if (dayAmount === 0) {
		return 'today';
	} else {
		return `in ${dayAmount} day${dayAmount > 1 ? 's' : ''}`;
	}
}

export const SubscriptionBanner: React.ComponentType = () => {
	const styles = useStyles();
	const state = useState();
	const location = useLocation();

	if (state.status !== 'success') {
		return <></>;
	}

	const {
		billingEnabled,
		accountName,
		hasTrialled,
		subscriptionStatus,
		cancelsInDays,
		trialEndsInDays,
		navigateToBillingPortal,
	} = state.data;

	if (!billingEnabled) {
		return <></>;
	}

	if (subscriptionStatus === 'active' && typeof cancelsInDays !== 'number') {
		return <></>;
	}

	return (
		<div className={styles.subscriptionBanner}>
			{subscriptionStatus === 'active' &&
				typeof cancelsInDays === 'number' &&
				cancelsInDays > -1 && (
					<>
						<Typography variant="caption">
							<strong>{accountName} is on the Pro plan!</strong>{' '}
							{cancelsInDays && (
								<Hidden smDown>
									Your Pro plan was cancelled &amp; ends{' '}
									{calculateDayText(cancelsInDays)}
								</Hidden>
							)}
						</Typography>
						<Button
							onClick={navigateToBillingPortal}
							variant="contained"
							color="primary"
							size="small"
						>
							<SvgIcon
								component={ProTransparentBadge}
								className={styles.proBadge}
							/>
							Renew Pro
						</Button>
					</>
				)}
			{subscriptionStatus === 'inactive' && (
				<>
					<Typography variant="caption">
						<strong>You're on the Free plan.</strong>
						<Hidden smDown>
							{' '}
							{hasTrialled ? 'Renew' : 'Start a trial of'} Pro to
							unlock all of Remote Social
						</Hidden>
					</Typography>
					<LinkButton
						to={routes.subscribe({
							preserveContinuePath: true,
							location,
							intent: 'upsell',
						})}
						variant="contained"
						color="primary"
						size="small"
					>
						<SvgIcon
							component={ProTransparentBadge}
							className={styles.proBadge}
						/>
						{hasTrialled ? 'Renew' : 'Trial'} Pro
						<Hidden smDown> for 30 days</Hidden>
					</LinkButton>
				</>
			)}
			{subscriptionStatus === 'trialing' && (
				<Typography variant="caption">
					<strong>{accountName} is on the Pro plan!</strong>{' '}
					{trialEndsInDays && (
						<Hidden smDown>
							Your Pro free trial ends{' '}
							{calculateDayText(trialEndsInDays)}.
						</Hidden>
					)}
				</Typography>
			)}
		</div>
	);
};
