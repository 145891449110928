import React from 'react';
import { Menu, Snackbar } from '@material-ui/core';
import { useActionItems } from './action-items/useActionItems';
import { Alert } from '@material-ui/lab';
import { CommonDialog } from '@common';

export const useActionsMenu = ({ refreshMembers }) => {
	const [actionsMenuState, showActionsForMember] = React.useState(null);
	const [snackBarMessage, showSuccessSnackBar] = React.useState(null);
	const [DialogueContentComponent, setDialogueContentComponent] =
		React.useState(null);

	const closeActions = React.useCallback(() => {
		showActionsForMember(null);
	}, [showActionsForMember]);

	const closeSnackBar = React.useCallback(() => {
		showSuccessSnackBar(null);
	}, [showSuccessSnackBar]);

	const closeDialogue = React.useCallback(() => {
		setDialogueContentComponent(null);
	}, [setDialogueContentComponent]);

	const showDialogueContent = React.useCallback(
		(content) => {
			closeActions();
			setDialogueContentComponent(() => content);
		},
		[closeActions, setDialogueContentComponent],
	);

	return {
		showActionsForMember,
		showSuccessSnackBar,
		actionMenuElements: (
			<>
				{actionsMenuState && (
					<ActionMenu
						id={actionsMenuState.id}
						anchorEl={actionsMenuState.anchorEl}
						member={actionsMenuState.member}
						onClose={closeActions}
						refreshMembers={refreshMembers}
						closeDialogue={closeDialogue}
						showSuccessSnackBar={showSuccessSnackBar}
						showDialogueContent={showDialogueContent}
					/>
				)}
				{snackBarMessage && (
					<Snackbar
						open={true}
						autoHideDuration={5000}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						onClose={closeSnackBar}
					>
						<Alert onClose={closeSnackBar} severity="success">
							{snackBarMessage}
						</Alert>
					</Snackbar>
				)}
				{DialogueContentComponent && (
					<CommonDialog
						open={true}
						variant="single-column"
						onClose={closeDialogue}
					>
						<DialogueContentComponent />
					</CommonDialog>
				)}
			</>
		),
	};
};

export const ActionMenu = ({ id, anchorEl, onClose, ...rest }) => {
	const items = useActionItems(rest);
	return (
		<Menu
			id={id}
			anchorEl={anchorEl}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={onClose}
		>
			{items}
		</Menu>
	);
};
