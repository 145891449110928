import { MemberProfile } from '@contracts/member-profile';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { CommonDialog, LoadingButton } from '@remote-social/common/src';
import React from 'react';
import { useDialogueState } from './state';
import { EditProfileDialogueContent } from './content';

const useStyles = makeStyles(() => ({
	stretch: {
		// stretch content of the dialogue to maximum width of the screen
		// which will be "md" on desktop due to two-column variant of CommonDialogue
		width: `100%`,
	},
}));

export const useEditProfileDialogue = (props: {
	profile?: MemberProfile;
	onUpdate: () => void;
}) => {
	const [open, setOpen] = React.useState(false);

	const openDialogue = React.useCallback(() => {
		setOpen(true);
	}, [setOpen]);

	const closeDialogue = React.useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return React.useMemo(
		() => ({
			openDialogue,
			dialogueElement: (
				<EditProfileDialogue
					open={open}
					close={closeDialogue}
					onUpdate={props.onUpdate}
					profile={props.profile}
				/>
			),
		}),
		[openDialogue, open, closeDialogue, props.onUpdate, props.profile],
	);
};

export const EditProfileDialogue: React.ComponentType<{
	open: boolean;
	close: () => void;
	onUpdate: () => void;
	profile?: MemberProfile;
}> = (props) => {
	const styles = useStyles();
	const state = useDialogueState({
		profile: props.profile,
		close: props.close,
		onUpdate: props.onUpdate,
		open: props.open,
	});
	if (!props.profile) {
		return null;
	}
	return (
		<CommonDialog
			open={props.open}
			onClose={props.close}
			variant="two-column"
			paperClassName={styles.stretch}
		>
			<DialogTitle disableTypography>
				<Typography variant="h3">Edit Profile</Typography>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<EditProfileDialogueContent
					useFieldValue={state.useFieldValue}
					getField={state.getField}
					topSectionFields={state.topSectionFields}
					bottomSectionFields={state.bottomSectionFields}
				/>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button onClick={props.close}>Cancel</Button>
				<LoadingButton
					variant="outlined"
					color="primary"
					disabled={!state.isDirty}
					onClick={state.updateProfile}
					loading={state.updateProfileRequest.status === 'pending'}
				>
					Save changes
				</LoadingButton>
			</DialogActions>
		</CommonDialog>
	);
};
