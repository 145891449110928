export const connect = [
	{
		id: 'connect',
		name: 'Connect',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/connect/tile-connect-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/connect/tile-connect-md.png',
		},
		avatarAlt: 'Remote Social Connect',
		priority: 0,
		category: 'home',
		introduction: {
			explainerVideoUrl:
				'https://cdn.remotesocial.io/hub/img/connect/explainer-connect.gif',
			points: [
				'Whether your team is in the office, fully remote, or working hybrid, Remote Social Connect allows you to spark brilliant conversations from right within your Slack workspace.',
				'Choose from one of our amazing content packs to kick off conversations within your team today.',
				'Learn new facts, discover undisclosed talents, and grow closer together through shared conversation.',
			],
		},
		links: [
			{
				rel: 'primary',
				href: '/explore',
				label: 'Get started using Connect',
			},
		],
	},
	{
		id: 'generic-onboarding',
		name: 'Onboarding',
		tiles: {
			xs: 'https://cdn.remotesocial.io/connect/generic-onboarding/generic-onboarding-xs-tile.png',
			md: 'https://cdn.remotesocial.io/connect/generic-onboarding/generic-onboarding-large-tile.png',
		},
		avatarAlt: 'Remote Social Connect',
		priority: 0,
		category: 'prompts',
		introduction: {
			explainerVideoUrl:
				'https://cdn.remotesocial.io/hub/img/connect/explainer-connect.gif',
			points: [
				'Want to help break the ice with the new hires and your team?',
				'Simply schedule this pack, and bring your new hires out of their shell with prompts that help express their individuality and strengths. This pack will help get your new hires known and comfortable around the office, whether your team is virtual, hybrid or otherwise.',
			],
		},
		links: [
			{
				rel: 'secondary',
				href: '/',
				label: 'Learn more about Connect',
			},
			{
				rel: 'primary',
				href: '/explore/activity/generic-onboarding',
				label: 'Explore Onboarding',
			},
		],
	},
	{
		id: 'wordle',
		name: 'Wordle',
		tiles: {
			xs: 'https://cdn.remotesocial.io/connect/wordle-challenge/wordle-challenge-xs-tile.png',
			md: 'https://cdn.remotesocial.io/connect/wordle-challenge/wordle-challenge-large-tile.png',
		},
		avatarAlt: 'Remote Social Connect',
		priority: 0,
		category: 'prompts',
		introduction: {
			explainerVideoUrl:
				'https://cdn.remotesocial.io/hub/img/connect/explainer-connect.gif',
			points: [
				'Play the hit sensation Wordle daily with your team.',
				"This team challenge pack will send a daily prompt into a channel of your choosing with a link to the NY Times viral game. Guess the mystery 5-letter word in 6 goes or less. Use the copy link at the end of the game and paste your score into the channel. Who will be today's best challenger?",
			],
		},
		links: [
			{
				rel: 'secondary',
				href: '/',
				label: 'Learn more about Connect',
			},
			{
				rel: 'primary',
				href: '/explore/activity/wordle-challenge',
				label: 'Explore Wordle',
			},
		],
	},
	{
		id: 'watercooler',
		name: 'Watercooler',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/connect/tile-watercooler-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/connect/tile-watercooler-md.png',
		},
		avatarAlt: 'The Watercooler',
		priority: 0,
		category: 'prompts',
		introduction: {
			explainerVideoUrl:
				'https://cdn.remotesocial.io/hub/img/connect/explainer-watercooler.gif',
			points: [
				'Whether your team is in the office, fully remote, or working hybrid, Remote Social Connect allows you to spark brilliant conversations from right within your Slack workspace.',
				'Our Watercooler pack is the starting point for engaging social conversations with lighter topics that get people talking.',
				'Learn new facts, discover undisclosed talents, and grow closer together through shared conversation.',
			],
		},
		links: [
			{
				rel: 'secondary',
				href: '/',
				label: 'Learn more about Connect',
			},
			{
				rel: 'primary',
				href: '/explore/activity/water-cooler',
				label: 'Explore Watercooler',
			},
		],
	},
	{
		id: 'globetrotters',
		name: 'Globetrotters',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/connect/tile-globetrotters-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/connect/tile-globetrotters-md.png',
		},
		avatarAlt: 'Globetrotters',
		priority: 0,
		category: 'prompts',
		introduction: {
			explainerVideoUrl:
				'https://cdn.remotesocial.io/hub/img/connect/explainer-globetrotters.gif',
			points: [
				'Whether your team is in the office, fully remote, or working hybrid, Remote Social Connect allows you to spark brilliant conversations from right within your Slack workspace.',
				'Window or aisle seat? Why? With this engaging pack focussed on travel and exploration you can discover hidden gems or amazing journeys.',
				'Use our Globetrotters pack to delve deeper into where your colleagues or friends have been and loved.',
			],
		},
		links: [
			{
				rel: 'secondary',
				href: '/',
				label: 'Learn more about Connect',
			},
			{
				rel: 'primary',
				href: '/explore/activity/globetrotters',
				label: 'Explore Globetrotters',
			},
		],
	},
	{
		id: 'below',
		name: 'Below the Surface',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/connect/tile-below-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/connect/tile-below-md.png',
		},
		avatarAlt: 'Below the Surface',
		priority: 0,
		category: 'prompts',
		introduction: {
			explainerVideoUrl:
				'https://cdn.remotesocial.io/hub/img/connect/explainer-below.gif',
			points: [
				'Whether your team is in the office, fully remote, or working hybrid, Remote Social Connect allows you to spark brilliant conversations from right within your Slack workspace.',
				'Unconver more about those around you with this insightful pack of content that asks people to open up.',
				'Below the Surface focusses on topics beyond a simple "what\'s your favourite color".',
			],
		},
		links: [
			{
				rel: 'secondary',
				href: '/',
				label: 'Learn more about Connect',
			},
			{
				rel: 'primary',
				href: '/explore/activity/below-the-surface',
				label: 'Explore Below the Surface',
			},
		],
	},
	{
		id: 'physical',
		name: 'Physical Challenge',
		tiles: {
			xs: 'https://cdn.remotesocial.io/hub/img/connect/tile-physical-cs-xs.png',
			md: 'https://cdn.remotesocial.io/hub/img/connect/tile-physical-cs-md.png',
		},
		avatarAlt: 'Physical Challenge',
		priority: 0,
		category: 'challenge',
		introduction: {
			explainerVideoUrl:
				'https://cdn.remotesocial.io/hub/img/connect/explainer-physical.gif',
			points: [
				"Remote Social Connect Challeges are a great way to track your crew's progress towards a common goal whilst having some fun.",
				'Our Physical Challenge pack will test your teams fitness. See who can complete all the exercises throughout the week.',
				'Getting fit never felt so fun.',
			],
		},
		links: [
			{
				rel: 'secondary',
				href: '/',
				label: 'Learn more about Connect',
			},
			{
				rel: 'primary',
				href: '',
				label: 'Coming soon',
			},
		],
	},
];
