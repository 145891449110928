import { DateTime } from 'luxon';

function formatDateWithOptionalYear(value: string, displayYear: boolean) {
	const fullDate = DateTime.fromISO(value);
	if (fullDate.isValid) {
		if (!displayYear) {
			return fullDate.toFormat('LLL dd');
		}
		return fullDate.toLocaleString(DateTime.DATE_MED);
	}
	// while sometimes we get a full date, we won't always get that
	// and we wouldn't want to ask people for their birth year if they
	// are not willing to share, so we support that here:
	const hasNoYear = /^\d\d-\d\d$/g.test(value);
	const parsed = hasNoYear
		? DateTime.fromFormat(value, 'dd-MM')
		: DateTime.fromFormat(value, 'dd-MM-yyyy');
	if (!parsed.isValid) {
		return;
	}
	if (hasNoYear || !displayYear) {
		return parsed.toFormat('LLL dd');
	} else {
		return parsed.toLocaleString(DateTime.DATE_MED);
	}
}

export function formatBirthday(value?: string) {
	if (!value) {
		return;
	}
	return formatDateWithOptionalYear(value, false);
}

export function formatWorkAnniversary(value?: string) {
	if (!value) {
		return;
	}
	const fullDate = DateTime.fromISO(value);
	if (fullDate.isValid) {
		return fullDate.toLocaleString(DateTime.DATE_MED);
	}
	const parsed = DateTime.fromFormat(value, 'dd-MM-yyyy');
	if (!parsed.isValid) {
		return;
	}
	return parsed.toLocaleString(DateTime.DATE_MED);
}
