import { MemberProfile, ProfileField } from '@contracts/member-profile';
import React from 'react';
import { useProfileEditState } from './editState';

export type EditProfileDialogueState = ReturnType<typeof useDialogueState>;

export function useDialogueState(props: {
	profile?: MemberProfile;
	close: () => void;
	onUpdate: () => void;
	open: boolean;
}) {
	const profileEditState = useProfileEditState({
		profile: props.profile,
		open: props.open,
	});
	const { top, bottom } = React.useMemo(
		() =>
			(props.profile?.fields || [])
				.filter(
					(field) =>
						field.type === 'text' ||
						field.type === 'multi-line-text' ||
						field.type === 'date',
				)
				.reduce(
					({ top, bottom }, field) =>
						field.fieldCategoryId !== 'working-with-me'
							? { top: [...top, field], bottom }
							: { top, bottom: [...bottom, field] },
					{
						top: [],
						bottom: [],
					} as {
						top: ProfileField[];
						bottom: ProfileField[];
					},
				),
		[props.profile],
	);

	const { close, onUpdate } = props;

	React.useEffect(() => {
		if (profileEditState.updateProfileRequest.status === 'success') {
			close();
			onUpdate();
		}
	}, [close, onUpdate, profileEditState.updateProfileRequest]);

	return React.useMemo(
		() => ({
			...profileEditState,
			topSectionFields: top,
			bottomSectionFields: bottom,
			close,
		}),
		[profileEditState, top, bottom, close],
	);
}
