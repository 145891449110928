import { MemberProfile, MemberProfileProps } from '@contracts/member-profile';
import { ensureExists, useBackendFunction } from '@remote-social/common/src';
import React from 'react';

export type ProfileEditState = ReturnType<typeof useProfileEditState>;

type Values = { [profileFieldId: string]: string };

export function useProfileEditState(props: {
	profile?: MemberProfile;
	open: boolean;
}) {
	const initial = React.useMemo(
		() => props.profile?.values || {},
		[props.profile?.values],
	);
	const getField = React.useCallback(
		(propName: keyof MemberProfileProps) => {
			return ensureExists(
				props.profile?.fields.find(
					(field) => field.propertyName === propName,
				),
			);
		},
		[props.profile?.fields],
	);

	const changedValuesRef = React.useRef<{
		values: Values;
		numberOfChanges: number;
	}>({
		values: {},
		numberOfChanges: 0,
	});

	const [isDirty, setIsDirty] = React.useState(false);

	const setChangedValues = React.useCallback(
		(value: { values: Values; numberOfChanges: number }) => {
			changedValuesRef.current = value;
			setIsDirty(value.numberOfChanges > 0);
		},
		[setIsDirty],
	);

	React.useEffect(() => {
		if (props.open) {
			// reset when opened again
			setChangedValues({
				values: {},
				numberOfChanges: 0,
			});
		}
	}, [props.open, setChangedValues]);

	const setFieldValue = React.useCallback(
		(profileFieldId: string, value: string) => {
			const state = changedValuesRef.current;
			const initialValue = initial[profileFieldId] || '';
			const wasChanged = Boolean(profileFieldId in state.values);
			if (initialValue !== value) {
				setChangedValues({
					values: {
						...state.values,
						[profileFieldId]: value,
					},
					numberOfChanges: !wasChanged
						? state.numberOfChanges + 1
						: state.numberOfChanges,
				});
			} else {
				const next = { ...state.values };
				delete next[profileFieldId];
				setChangedValues({
					values: next,
					numberOfChanges: wasChanged
						? state.numberOfChanges - 1
						: state.numberOfChanges,
				});
			}
		},
		[initial, setChangedValues],
	);

	const [updateProfileRequest, triggerUpdateProfile] = useBackendFunction(
		'memberProfile-update',
	);

	const updateProfile = React.useCallback(() => {
		triggerUpdateProfile({
			accountId: ensureExists(props.profile?.accountId),
			values: ensureExists(changedValuesRef.current?.values),
		});
	}, [changedValuesRef, props.profile?.accountId, triggerUpdateProfile]);

	const useFieldValue = React.useCallback(
		(profileFieldId: string) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const [value, setValue] = React.useState(
				initial[profileFieldId] || '',
			);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			React.useEffect(() => {
				setFieldValue(profileFieldId, value);
			}, [profileFieldId, value]);
			return [value, setValue] as const;
		},
		[initial, setFieldValue],
	);

	return React.useMemo(
		() => ({
			useFieldValue,
			getField,
			isDirty,
			updateProfile,
			updateProfileRequest,
		}),
		[useFieldValue, getField, isDirty, updateProfile, updateProfileRequest],
	);
}
