import React, { useState } from 'react';
import { formatGameUrl } from '../../data/urlBuilding';
import { usePlayableGames } from '../../store/games';
import { Loading, ErrorMessage, Time, Button, DocumentHead } from '@common';
import {
	Link as UILink,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Avatar,
	makeStyles,
	Container,
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useCurrentAccount } from '@common';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: 'auto',
	},
	gameColumn: {
		whiteSpace: 'nowrap',
		width: '1%',
		paddingRight: theme.spacing(4),
	},
	playerColumn: {
		width: '100%',
	},
	timeColumn: {
		whiteSpace: 'nowrap',
		width: '1%',
	},
	marginTop: { marginTop: theme.spacing(2) },
}));

const usePaginated = (
	useLoader,
	{
		startAt = null,
		pageSize = 20,
		loaderProp = 'data',
		prop = loaderProp,
	} = {},
) => {
	// grab one extra so we know if there's more
	const [limit, setLimit] = useState(pageSize + 1);
	const {
		loading,
		error,
		[loaderProp]: items,
	} = useLoader({
		startAt,
		limit,
	});

	return {
		[prop]: items && items.slice(0, limit - 1),
		loading,
		error,
		hasMore: loading || items?.length === limit,
		loadMore() {
			setLimit((limit) => limit + pageSize);
		},
	};
};

export const findGameLobbyUrlAndFormat = (game, params = {}) => {
	const gameUrl = game?.links?.find((l) => l.rel === 'lobby');
	if (!gameUrl || !gameUrl.href) {
		return;
	}
	return formatGameUrl(gameUrl.href, params);
};

const useFirestoreInfoFor = (storeAs) => {
	return {
		loading: useSelector(
			(state) => state.firestore.status.requesting[storeAs],
		),
		error: useSelector((state) => state.firestore.errors[storeAs]),
		data: useSelector((state) => state.firestore.ordered[storeAs]),
	};
};

const useGameAvatarStyles = makeStyles((theme) => ({
	img: {
		borderRadius: theme.spacing(1),
		height: '3em',
		width: '3em',
		verticalAlign: 'middle',
		marginRight: theme.spacing(1),
	},
}));

const GameAvatar = ({ game: { name, avatarUrl } }) => {
	const classes = useGameAvatarStyles();
	return <img src={avatarUrl} className={classes.img} alt={name} />;
};

/** @deprecated this has been removed from navigatory access
 * please remove this component if it does not get reinstated */
export const AccountPlayHistory = () => {
	const classes = useStyles();
	const { isLoaded, currentAccount: account } = useCurrentAccount();
	const useGameStartedActivitiesForAccount = ({ limit }) => {
		const storeAs = account && 'accounts/' + account.id + '/games-history';
		useFirestoreConnect({
			storeAs,
			collection: 'accounts',
			doc: account.id,
			subcollections: [
				{
					collection: 'games',
				},
			],
			limit,
			orderBy: ['createdAt', 'desc'],
		});
		return useFirestoreInfoFor(storeAs);
	};
	const games = usePlayableGames();
	const { loading, error, gamesPlayed, hasMore, loadMore } = usePaginated(
		useGameStartedActivitiesForAccount,
		{
			prop: 'gamesPlayed',
		},
	);

	if (!isLoaded) {
		return <Loading />;
	}
	if (error) {
		return <ErrorMessage error={error} />;
	}

	if (!gamesPlayed || !gamesPlayed.length) {
		if (loading) {
			return <Loading />;
		}
		return (
			<Container className={classes.root} maxWidth="sm">
				<DocumentHead title="Gameplay sessions" />
				<Typography component="h1" variant="h5">
					No games played
				</Typography>
				<Typography className={classes.marginTop} variant="body1">
					It looks like you haven't played any games yet. Go have some
					fun!
				</Typography>
			</Container>
		);
	}

	return (
		<Container className={classes.root} maxWidth="sm">
			<DocumentHead title="Gameplay sessions" />
			<Typography component="h1" variant="h5">
				Games played
			</Typography>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell className={classes.gameColumn}>
								<Typography variant="srOnly">
									Game type
								</Typography>
							</TableCell>
							<TableCell className={classes.playerColumn}>
								Players
							</TableCell>
							<TableCell className={classes.timeColumn}>
								When
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{gamesPlayed.map((gamePlayed) => {
							const game = games.find(
								(g) => g.id === gamePlayed.gameType,
							);
							const url = findGameLobbyUrlAndFormat(game, {
								gameSlug: gamePlayed.id,
							});
							return (
								<TableRow key={gamePlayed.id}>
									<TableCell className={classes.gameColumn}>
										<UILink href={url}>
											<GameAvatar game={game} />
										</UILink>
									</TableCell>
									<TableCell className={classes.playerColumn}>
										<AvatarGroup>
											{Object.entries(
												gamePlayed.playersData,
											).flatMap(([k, v]) => (
												//[<Avatar key={k} alt={v.displayName} src={v.photoURL} title={v.displayName} />,
												<Avatar
													key={k}
													alt={v.displayName}
													src={v.photoURL}
													title={v.displayName}
												/>
												//,<Avatar key={k} alt={v.displayName} src={v.photoURL} title={v.displayName} />]
											))}
										</AvatarGroup>
									</TableCell>
									<TableCell className={classes.timeColumn}>
										<Time
											timestamp={gamePlayed.createdAt}
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{hasMore && <Button onClick={loadMore}>Show more</Button>}
		</Container>
	);
};
