import { BaseProviders } from '@remote-social/common/src';
import { appConfig } from '../appConfig';
import { store } from './reduxStore';
import { createHubTheme } from '../hubTheme';
import { PlayableGamesProvider } from './games';
import { AccountMembersStoreProvider } from './account-members';

const hubTheme = createHubTheme();

export const HubProvider: React.ComponentType = ({ children }) => (
	<BaseProviders store={store} appConfig={appConfig} theme={hubTheme}>
		<AccountMembersStoreProvider>
			<PlayableGamesProvider>{children}</PlayableGamesProvider>
		</AccountMembersStoreProvider>
	</BaseProviders>
);
