import { RequestState, useCombineRequestState } from '@common/store-tools';
import { Member, MemberProfile } from '@contracts/member-profile';
import { useBackendFunction, useCurrentUser } from '@remote-social/common/src';
import { useRolesCheck } from '@remote-social/common/src/hooks/useRolesCheck';
import { useParams } from 'react-router';
import { useAccountMembers } from '../../store/account-members';
import { useEditProfileDialogue } from './components/edit-profile/dialogue';

type RouteParams = {
	accountId: string;
	uid: string;
};

type EditState = {
	openEditProfileDialogue?: () => void;
	editProfileDialogueElement?: React.ReactNode;
};

type MergedState = EditState & RouteParams;

type State =
	| RequestState<
			MergedState & {
				profile: MemberProfile;
				members: Member[];
			},
			MergedState
	  >
	| {
			status: 'not-a-member';
	  };

export const useState = (): State => {
	const { accountId, uid } = useParams<RouteParams>();
	const { uid: currentUserId } = useCurrentUser();
	const roleCheck = useRolesCheck({
		roles: ['admin', 'member', 'owner'],
		accountId,
	});
	const isMemberOfAccountRequested = roleCheck.status === 'allowed';
	const [profileRequest, refreshProfile] = useBackendFunction(
		'memberProfile-get',
		{
			callOnChange: () => {
				if (!isMemberOfAccountRequested) {
					return;
				}
				return {
					accountId,
					uid,
				};
			},
		},
	);
	const membersRequest = useAccountMembers(
		isMemberOfAccountRequested
			? {
					accountId,
			  }
			: undefined,
	);
	const editProfile = useEditProfileDialogue({
		...(profileRequest.status === 'success' && {
			profile: profileRequest.data,
		}),
		onUpdate: refreshProfile,
	});

	const state = useCombineRequestState(
		{
			profile: profileRequest,
			members: membersRequest,
		},
		{
			accountId,
			uid,
			openEditProfileDialogue:
				profileRequest.status === 'success' &&
				currentUserId === profileRequest.data.uid &&
				!profileRequest.data.isProfileEmpty
					? editProfile.openDialogue
					: undefined,
			editProfileDialogueElement: editProfile.dialogueElement,
		},
	);

	if (roleCheck.status === 'not-allowed') {
		return { status: 'not-a-member' as const };
	}

	return state;
};
