import { Box, Breadcrumbs, Container } from '@material-ui/core';

export const BreadcrumbsContainer: React.ComponentType = ({ children }) => {
	return (
		<Container maxWidth="md">
			<Box mb={2}>
				<Breadcrumbs aria-label="breadcrumb">{children}</Breadcrumbs>
			</Box>
		</Container>
	);
};
