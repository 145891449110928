import React from 'react';
import { Button, Hidden } from '@material-ui/core';
import { CommonDialog, useCurrentAccount } from '@common';
import { InvitePeople } from './InvitePeople';

type PropTypes = {
	showSuccessSnackBar: (snackBarMessage: string) => void;
};

export const InviteButton: React.ComponentType<PropTypes> = ({
	showSuccessSnackBar,
}) => {
	const { currentAccount } = useCurrentAccount();

	const [open, setOpen] = React.useState(false);
	const toggleDialog = React.useCallback(() => {
		setOpen(!open);
	}, [open, setOpen]);

	const invitingAllowed = ['admin', 'owner'].includes(
		currentAccount?.role ?? '',
	);

	if (!invitingAllowed) {
		return null;
	}

	return (
		<>
			<Button variant="contained" color="primary" onClick={toggleDialog}>
				Invite
				<Hidden smDown> people</Hidden>
			</Button>

			<CommonDialog
				variant="two-column"
				open={open}
				onClose={toggleDialog}
			>
				<InvitePeople
					handleClose={toggleDialog}
					showSuccessSnackBar={showSuccessSnackBar}
				/>
			</CommonDialog>
		</>
	);
};
