import React from 'react';
import { Chip, makeStyles, SvgIcon, Theme } from '@material-ui/core';
import { ReactComponent as ProBadge } from '../assets/images/pro-gold.svg';
import clsx from 'clsx';
import { useConfigFlag } from '../hooks/useConfigFlag';

export const useStyles = makeStyles((theme: Theme) => ({
	proBadge: {
		width: '18px',
		height: '18px',
	},
	proChip: {
		backgroundColor: '#DAA520',
		color: '#fff',
		fontWeight: 'bold',
		fontSize: theme.typography.body2.fontSize,
		lineHeight: 1,

		'& .MuiChip-label': {
			/** visual hack to center text */
			marginTop: '1px',
			paddingLeft: '7px',
			paddingRight: '6px',
		},
	},
}));

type Props = {
	className?: string;
};

export const ProChip: React.ComponentType<Props> = ({ className }) => {
	const styles = useStyles();
	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();

	if (!billingEnabled) {
		return null;
	}

	return (
		<Chip
			size="small"
			icon={
				<SvgIcon
					viewBox="0 0 22 22"
					component={ProBadge}
					className={styles.proBadge}
				/>
			}
			className={clsx(styles.proChip, className)}
			label="PRO"
		/>
	);
};
