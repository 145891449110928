import React from 'react';
import { Member } from '@contracts/member-profile';
import { RequestState } from '@common/store-tools';
import Measure, { ContentRect } from 'react-measure';
import { AccountMembersGrid } from './grid';

type Props = {
	membersRequest: RequestState<Member[], Member[]>;
	maxNumRowsWhenCollapsed: number;
	collapsed: boolean;
	onExpandabilityChange: (opts: { numberOfHiddenMembers: number }) => void;
};

const heightOfTwoPlaceholderHeightsPx = 304;
const gapBetweenMembersPx = 16;

type Size = { width: number; height: number };

const useComponentState = (props: Props) => {
	const { maxNumRowsWhenCollapsed, onExpandabilityChange, membersRequest } =
		props;

	const [gridSize, setGridSize] = React.useState<Size | undefined>();
	const [cellSize, setCellSize] = React.useState<Size | undefined>();

	const maxHeight = cellSize
		? (cellSize.height + gapBetweenMembersPx) * maxNumRowsWhenCollapsed
		: heightOfTwoPlaceholderHeightsPx;

	const onGridResize = React.useCallback(
		(contentRect: ContentRect) => {
			if (!contentRect.bounds) {
				return;
			}
			setGridSize({
				width: contentRect.bounds.width,
				height: contentRect.bounds.height,
			});
		},
		[setGridSize],
	);

	const measuredNumberOfGaps =
		cellSize && gridSize
			? Math.round(
					(gridSize.width - cellSize.width) /
						(cellSize.width + gapBetweenMembersPx),
			  )
			: undefined;
	const measuredNumberOfColumns = measuredNumberOfGaps
		? measuredNumberOfGaps + 1
		: undefined;

	const numberOfElementsVisibleWhenCollapsed = measuredNumberOfColumns
		? measuredNumberOfColumns * maxNumRowsWhenCollapsed
		: undefined;

	React.useEffect(() => {
		if (!numberOfElementsVisibleWhenCollapsed) {
			return;
		}
		onExpandabilityChange({
			numberOfHiddenMembers: Math.max(
				0,
				membersRequest.data.length -
					numberOfElementsVisibleWhenCollapsed,
			),
		});
	}, [
		membersRequest.data.length,
		numberOfElementsVisibleWhenCollapsed,
		onExpandabilityChange,
	]);

	return {
		maxHeight,
		onGridResize,
		setCellSize,
	};
};

export const CollapsableAccountMembersGrid: React.ComponentType<Props> = (
	props,
) => {
	const { membersRequest, collapsed } = props;
	const { maxHeight, onGridResize, setCellSize } = useComponentState(props);
	return (
		<div
			style={{
				...(collapsed && {
					maxHeight,
				}),
				overflow: 'hidden',
				// this is necessary so we can see borders
				// that otherwise are cut by overflow hidden above
				// box sizing doesn't work for some reason
				boxSizing: 'border-box',
				padding: `1px`,
			}}
		>
			<Measure onResize={onGridResize} bounds>
				{({ measureRef }) => {
					return (
						<div ref={measureRef}>
							<AccountMembersGrid
								membersRequest={membersRequest}
								onCellSizeMeasured={setCellSize}
							/>
						</div>
					);
				}}
			</Measure>
		</div>
	);
};
