import React, { useContext } from 'react';
import {
	RequestState,
	useBackendFunction,
	useConfigFlag,
	useCurrentAccount,
} from '@common';
import { GetMembersParams, Member } from '@contracts/member-profile';

export type AccountMembersStore = {
	membersRequest: RequestState<Member[], Member[]>;
	getMembers: (params: GetMembersParams) => void;
};

const Context = React.createContext<AccountMembersStore | null>(null);

export const AccountMembersStoreProvider: React.ComponentType = ({
	children,
}) => {
	const [membersRequest, getMembers] = useBackendFunction(
		'memberProfile-getMembers',
		{
			initialValue: [],
		},
	);
	const state = React.useMemo(() => {
		return {
			membersRequest,
			getMembers,
		};
	}, [membersRequest, getMembers]);
	return <Context.Provider value={state}>{children}</Context.Provider>;
};

export function useAccountMembers(params?: { accountId?: string }) {
	const currentAccountState = useContext(Context);
	if (!currentAccountState) {
		throw new Error(
			'Component using the hook is not wrapped with AccountMembersStoreProvider',
		);
	}
	const getCurrentAccountMembers = currentAccountState.getMembers;

	const { currentAccountId } = useCurrentAccount();

	const memberProfilesEnabled = useConfigFlag(
		'memberProfilesEnabled',
	).asBoolean();

	// since this hook can be used across multiple components, check
	// whether current account already loaded so that effect below
	// doesn't trigger reloading when it's already there
	const currentAccountLoaded =
		currentAccountState.membersRequest.status === 'success' &&
		currentAccountState.membersRequest.data[0]?.accountId ===
			currentAccountId;

	React.useEffect(() => {
		if (
			!memberProfilesEnabled ||
			!currentAccountId ||
			currentAccountLoaded
		) {
			return;
		}
		getCurrentAccountMembers({
			accountId: currentAccountId,
		});
	}, [
		getCurrentAccountMembers,
		currentAccountId,
		memberProfilesEnabled,
		currentAccountLoaded,
	]);

	const accountId = params?.accountId ?? currentAccountId;

	// separate state for possibly another account
	const [membersRequest] = useBackendFunction('memberProfile-getMembers', {
		callOnChange: () => {
			if (!currentAccountId || !accountId) {
				return;
			}
			if (accountId === currentAccountId) {
				return;
			}
			return {
				accountId,
			};
		},
		initialValue: [],
	});

	if (!memberProfilesEnabled) {
		return membersRequest;
	}

	return accountId === currentAccountId
		? currentAccountState.membersRequest
		: membersRequest;
}
