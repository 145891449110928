import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { AppLayout as CommonAppLayout } from '@common/components/AppLayout';
import backgroundHome from '../img/background-hub.svg';

const useStyles = makeStyles((theme) => ({
	homeAppLayout: {
		[theme.breakpoints.up('sm')]: {
			background: `url(${backgroundHome}) white`,
		},
	},
}));

export const HubLayout: React.ComponentType<
	React.ComponentProps<typeof CommonAppLayout>
> = (props) => {
	const styles = useStyles();

	return (
		<CommonAppLayout
			{...props}
			className={clsx(styles.homeAppLayout, props.className)}
		>
			{props.children}
		</CommonAppLayout>
	);
};
