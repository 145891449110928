import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Showcase } from './Showcase';
import { gamesAndCategoriesSettings } from './SliderSettings';
// import { env } from '@common/environment';
import { Card } from './card';
import { CardMetadata } from './cardMetadata';

const useStyles = makeStyles((theme) => ({
	categoryCard: {
		height: '290px',
	},
	labelsBelowCard: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	categoryText: {
		fontWeight: 500,
	},
}));

const CategoriesShowcase = () => {
	const CDN = 'https://cdn.remotesocial.io/hub/img';
	// const envConfig = env();
	// const MKTPLC = `${envConfig.experiencesUrl}/s?pub_categories=`;
	const CONTACT = `https://www.remotesocial.io/contact`;

	const categoryCards = [
		{
			img: `${CDN}/Cooking.svg`,
			name: 'Cooking',
			// href: `${MKTPLC}has_any%3Acooking`,
			href: `${CONTACT}`,
		},
		{
			img: `${CDN}/Fitness.svg`,
			name: 'Fitness',
			// href: `${MKTPLC}has_any%3Afitness`,
			href: `${CONTACT}`,
		},
		{
			img: `${CDN}/Drinks.svg`,
			name: 'Drinks',
			// href: `${MKTPLC}has_any%3Adrinks`,
			href: `${CONTACT}`,
		},
		{
			img: `${CDN}/Arts.svg`,
			name: 'Arts',
			// href: `${MKTPLC}has_any%3Aarts`,
			href: `${CONTACT}`,
		},
		{
			img: `${CDN}/Entertainment.svg`,
			name: 'Entertainment',
			// href: `${MKTPLC}has_any%3Aentertainment`,
			href: `${CONTACT}`,
		},
		{
			img: `${CDN}/Wellness.svg`,
			name: 'Wellness',
			// href: `${MKTPLC}has_any%3Awellness`,
			href: `${CONTACT}`,
		},
		{
			img: `${CDN}/Learning-and-Development.svg`,
			name: 'Learning and Development',
			// href: `${MKTPLC}has_any%3Alearning%20and%20development`,
			href: `${CONTACT}`,
		},
	];

	return (
		<Showcase
			title="Hosted Experiences"
			subtitle="Explore world-class hosted Experiences for your team"
			sliderSetting={gamesAndCategoriesSettings}
		>
			{categoryCards.map((category, index) => {
				return (
					<div key={index}>
						<CategoryCard key={category.id} category={category} />
					</div>
				);
			})}
		</Showcase>
	);
};

const CategoryCard = ({ category }) => {
	const classes = useStyles();
	return (
		<Card
			className={classes.categoryCard}
			avatarUrl={category.img}
			avatarAlt={category.name}
			href={category.href}
			newTab={true}
		>
			<div className={classes.labelsBelowCard}>
				<CardMetadata>
					<Typography className={classes.categoryText}>
						{category.name}
					</Typography>
				</CardMetadata>
			</div>
		</Card>
	);
};

export { CategoriesShowcase };
