import React from 'react';
import {
	Checkbox,
	Grid,
	InputAdornment,
	makeStyles,
	MenuItem,
	TextField,
	Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
	gridForm: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: theme.spacing(2),
		},
	},
	textSearch: {
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2),
		},
	},
}));

export function FilterAccount({
	status,
	statusOptions,
	multiSelectLabel,
	handleRoleFilterChange,
	handleSearchFilterChange,
}) {
	const classes = useStyles();

	return (
		<Grid container direction="row">
			<Grid item xs={12} sm={4} md={3} className={classes.gridForm}>
				<TextField
					fullWidth
					variant="outlined"
					size="small"
					className={classes.textSearch}
					onChange={handleSearchFilterChange}
					InputProps={{
						labelWidth: 0,
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={4} md={3} className={classes.gridForm}>
				<TextField
					variant="outlined"
					size="small"
					fullWidth
					select
					value={status}
					onChange={handleRoleFilterChange}
					SelectProps={{
						multiple: true,
						displayEmpty: true,
						MenuProps: {
							getContentAnchorEl: () => null,
						},
						renderValue: (options) => {
							if (options.length === 0) {
								return <span>All {multiSelectLabel}</span>;
							}
							return options
								.map((option) => option.label)
								.join(', ');
						},
					}}
				>
					{statusOptions.map((option) => (
						<MenuItem key={option.value} value={option}>
							<Checkbox
								checked={status.some(
									({ value }) => value === option.value,
								)}
							/>
							<Typography>{option.label}</Typography>
						</MenuItem>
					))}
				</TextField>
			</Grid>
		</Grid>
	);
}
