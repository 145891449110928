import { ProfileField } from '@contracts/member-profile';
import { Avatar, Button, makeStyles } from '@material-ui/core';
import { FluidImgContainer } from '@common/components/fluidImgContainer';
import { useDropzone, FileRejection, FileError } from 'react-dropzone';
import React from 'react';
import {
	ensure,
	ensureExists,
	ErrorMessage,
	isPending,
	LoadingButton,
	useBackendFunction,
	useCurrentAccount,
	useCurrentUser,
} from '@common';
import Skeleton from '@material-ui/lab/Skeleton';
import { ProfileEditState } from './editState';

const useStyles = makeStyles(() => ({
	dropZoneWrapper: {
		width: `100%`,
		height: `100%`,

		position: 'relative',
	},
	fill: {
		width: `100%`,
		height: `100%`,

		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
}));

function readFileBase64(file: Blob) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			() => {
				try {
					ensure(typeof reader.result === 'string');
					const [, base64] = ensureExists(
						/base64,(.*)/.exec(reader.result),
					);
					resolve(ensureExists(base64));
				} catch (err) {
					reject(err);
				}
			},
			false,
		);
		reader.addEventListener(
			'error',
			() => reject(new Error('Cannot process file upload')),
			false,
		);
		reader.readAsDataURL(file);
	});
}

const tenMb = 10 * 1024 * 1024;
const sizeWhenConvertedFromBase64 = (current: number) => (current * 3) / 4;
const maxSize = sizeWhenConvertedFromBase64(tenMb);
const errorMessageFromFileError = (fileError?: FileError) => {
	switch (fileError?.code) {
		case 'file-too-large':
			return `File size should be less than ${(
				maxSize /
				1024 /
				1024
			).toFixed(1)}Mb`;
		default:
			return fileError?.message;
	}
};

type Props = Pick<ProfileEditState, 'useFieldValue'> & {
	field: ProfileField;
};

const usePhotoEditorState = ({ field, useFieldValue }: Props) => {
	const { currentAccountId } = useCurrentAccount();
	const { uid } = useCurrentUser();

	const [errorMessage, setErrorMessage] = React.useState<
		string | undefined
	>();
	const [uploadRequest, upload] = useBackendFunction(
		'userImages-uploadPublic',
	);

	const onDrop = React.useCallback(
		async (acceptedFiles: File[]) => {
			if (acceptedFiles.length === 0) {
				return;
			}
			setErrorMessage(undefined);
			const imageBase64 = await readFileBase64(
				ensureExists(acceptedFiles[0]),
			);
			upload({
				imageBase64,
				accountId: currentAccountId,
				purpose: field.propertyName,
				fileName: ensureExists(acceptedFiles[0]?.name),
			});
		},
		[currentAccountId, field.propertyName, upload, setErrorMessage],
	);

	const onDropRejected = React.useCallback(
		(rejections: FileRejection[]) => {
			setErrorMessage(
				errorMessageFromFileError(rejections[0]?.errors[0]),
			);
		},
		[setErrorMessage],
	);

	const [value, setValue] = useFieldValue(field.profileFieldId);

	React.useEffect(() => {
		if (uploadRequest.status === 'success') {
			setValue(uploadRequest.data.publicImageUrl);
		}
	}, [setValue, uploadRequest]);

	const reset = React.useCallback(() => {
		if (!uid) {
			return;
		}
		// TODO: Introduce BE function that generates this?
		setValue(`https://avatars.remotesocial.io/285/${uid}.png`);
	}, [setValue, uid]);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'image/jpeg, image/png',
		maxSize,
		maxFiles: 1,
		onDropRejected,
	});

	return {
		getRootProps,
		getInputProps,
		uploadRequest,
		reset,
		error:
			errorMessage ||
			(uploadRequest.status === 'error'
				? uploadRequest.error
				: undefined),
		value,
	};
};

export const PhotoEditor: React.ComponentType<Props> = React.memo((props) => {
	const styles = useStyles();
	const { getInputProps, getRootProps, uploadRequest, reset, error, value } =
		usePhotoEditorState(props);

	return (
		<>
			<div className={styles.dropZoneWrapper} {...getRootProps()}>
				<FluidImgContainer aspectRatio={1}>
					{isPending(uploadRequest) && <Skeleton variant="rect" />}
					{!isPending(uploadRequest) && (
						<Avatar src={value} variant="rounded" />
					)}
				</FluidImgContainer>
				<input
					{...getInputProps({
						disabled: isPending(uploadRequest),
					})}
				/>
			</div>

			<LoadingButton
				variant="contained"
				color="primary"
				onClick={getRootProps().onClick}
				loading={isPending(uploadRequest)}
			>
				Upload Photo
			</LoadingButton>
			<Button variant="outlined" onClick={reset}>
				Reset Photo
			</Button>

			<ErrorMessage error={error} />
		</>
	);
});
