import {
	firestoreReducer,
	FirestoreReducer as FirestoreReducerNamespace,
} from 'redux-firestore';
import {
	FirebaseReducer as FirebaseReducerNamespace,
	firebaseReducer as firebaseReducerCore,
} from 'react-redux-firebase';
import { Reducer } from './action';

type FirebaseReducerState<Schema> = FirebaseReducerNamespace.Reducer<
	{},
	Schema
>;

type FirebaseReducer<Schema> = (
	state: any,
	action: any,
) => FirebaseReducerState<Schema>;

type FirestoreReducerState<Schema> = FirestoreReducerNamespace.Reducer<Schema>;

type FirestoreReducer<Schema> = Reducer<FirestoreReducerState<Schema>>;

export type FirebaseReduxState<Schema> = {
	firebase: FirebaseReducerState<Schema>;
	firestore: FirestoreReducerState<Schema>;
};

export function makeFirestoreReducer<Schema>(): FirestoreReducer<Schema> {
	return firestoreReducer;
}

export function makeFirebaseReducer<Schema = {}>(): FirebaseReducer<Schema> {
	return firebaseReducerCore;
}
