import React, { useMemo } from 'react';
import { useFormikContext, useField } from 'formik';
import * as Yup from 'yup';
import {
	CheckboxField,
	DatePickerField,
	TimeSelectField,
	SelectField,
} from '../../FormFields';

import LuxonUtils from '@date-io/luxon';
import { Box, Icon, InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { makeStyles } from '@material-ui/styles';
import buildRecurrenceOptions from '../../../data/RecurrenceOptions';

import EventIcon from '@material-ui/icons/Event';

const useStyle = makeStyles(() => ({
	input: {
		color: 'inherit',
	},
}));

const DateTimeFrequencySchema = Yup.object().shape({
	startDate: Yup.date().required(),
	startTime: Yup.date().required(),
	isRepeating: Yup.boolean().default(false),
	schedule: Yup.string().when('isRepeating', (isRepeating, schema) =>
		isRepeating ? schema.required() : schema.nullable(),
	),
	duration: Yup.string().required(),
});

const DateTimeFrequency = ({ durationOptions }) => {
	const { values } = useFormikContext();
	const [, , helpers] = useField('schedule');

	const classes = useStyle();

	const recurrenceOptions = useMemo(() => {
		// build the recurrence options based on the date selected by user
		const options = buildRecurrenceOptions(values.startDate);

		// update the schedule value to reflect the change in date
		helpers.setValue(options[0].value);

		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.startDate]);

	return (
		<Box mt={2}>
			<MuiPickersUtilsProvider utils={LuxonUtils}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<DatePickerField
							margin="dense"
							fullWidth
							disableToolbar
							name="startDate"
							format="EEEE, d MMMM"
							inputVariant="outlined"
							variant="inline"
							label="Date"
							InputProps={{
								className: classes.input,
								endAdornment: (
									<InputAdornment position={'end'}>
										<Icon>
											<EventIcon />
										</Icon>
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<TimeSelectField name="startTime" label="Time" />
					</Grid>

					<Grid item xs={12}>
						<SelectField
							fullWidth
							name="duration"
							options={durationOptions}
							label="Duration"
							margin="dense"
						/>
					</Grid>

					<Grid item xs={4}>
						<Box display="flex" height="100%" alignItems="center">
							<CheckboxField name="isRepeating" label="Repeat" />
						</Box>
					</Grid>

					<Grid item xs={8}>
						<SelectField
							disabled={!values.isRepeating}
							name="schedule"
							options={recurrenceOptions}
							margin="dense"
						/>
					</Grid>
				</Grid>
			</MuiPickersUtilsProvider>
		</Box>
	);
};

export { DateTimeFrequencySchema };

export default DateTimeFrequency;
