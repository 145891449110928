import { combineRequestState } from './requestStatusHelpers';
import { AnyRequestState } from './requestState';
import React from 'react';

export function useCombineRequestState<
	A extends Record<string, AnyRequestState>,
	I,
>(stateMap: A, initialValues: I | {} = {}) {
	const state = React.useMemo(
		() => combineRequestState<A, I>(stateMap, initialValues),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[...Object.values(stateMap), ...Object.values(initialValues)],
	);
	return state;
}
