import React from 'react';

type Props = {
	className?: string;
};

export const CardMetadata: React.ComponentType<Props> = ({
	className,
	children,
}) => {
	return <div className={className}>{children}</div>;
};
