import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { useSlackStore } from '../../slack';
import { routes } from '../routes';
import React from 'react';

export const SlackIntegratedRoute: React.ComponentType<RouteProps> = (
	props,
) => {
	const { status } = useSlackStore();
	const location = useLocation();

	if (status === 'needs-integration') {
		return (
			<Redirect
				to={routes.integrateSlack({
					preserveContinuePath: true,
					location,
				})}
			/>
		);
	}

	return <Route {...props} />;
};
