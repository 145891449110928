import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Divider,
	IconButton,
	Link,
	Snackbar,
	Switch,
	Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Loading, LoadingButton, registerError } from '@common';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';

/*import { TimePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';*/
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useCurrentAccount } from '@common/contexts';
import { hubRoutes } from '@common/routes/hubRoutes';

const useStyles = makeStyles((theme) => ({
	heading: {
		fontSize: '1.25rem',
		color: '#444444',
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
		},
	},
	subHeading: {
		fontSize: '1rem',
		fontWeight: 500,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.875rem',
		},
	},
	label: {
		maxWidth: 380,
		fontSize: '.875rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem',
			maxWidth: '80%',
		},
	},
	switch: {
		marginTop: -12,
	},
	divider: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	restrictOptionGrid: {
		display: 'grid',
		gridTemplateAreas: '"dt tm sw"',
		gridTemplateColumns: '1fr auto auto',
		[theme.breakpoints.down('sm')]: {
			gridTemplateAreas: '"dt sw" "tm tm"',
		},
	},
	restrictionDetails: {
		gridArea: 'dt',
	},
	restrictionTime: {
		gridArea: 'tm',
		marginRight: '0.5rem',
		[theme.breakpoints.down('sm')]: {
			marginTop: '0.5rem',
		},
	},
	restrictionToggle: {
		gridArea: 'sw',
	},
	timePicker: {
		margin: 0,
		'& > .MuiTextField-root': {
			background: 'red',
		},
		'& > .MuiInputBase-root': {
			fontSize: 14,

			'& > .MuiInputBase-input': {
				paddingTop: 0,
			},
		},
	},
	dash: {
		backgroundColor: '#444444',
		marginTop: 10,
	},
	tableOptionItem: {
		width: '3.25rem',
	},
	optionHeaderText: {
		fontSize: '0.75rem',
		fontWeight: 600,
	},
}));

export const TriviaSettings = () => {
	const classes = useStyles();
	const history = useHistory();
	const { publishCustomTriviaCategory } = useCurrentAccount();

	const [fetchingCategories, setFetchingCategories] = useState(false);
	const [creatingCategory, setCreatingCategory] = useState(false);

	const [snackbarConfig, setSnackbarConfig] = useState({
		isOpen: false,
		message: '',
		variant: 'info',
	});

	const {
		createCustomTriviaCategory,
		fetchCustomTriviaCategories,
		deleteCustomTriviaCategory,
	} = useCurrentAccount();

	const [customCategories, setCustomCategories] = useState([]);

	const fetchCategories = useCallback(async () => {
		try {
			setFetchingCategories(true);
			const { data } = await fetchCustomTriviaCategories();

			setCustomCategories(data);
		} catch (error) {
			registerError(error);
		} finally {
			setFetchingCategories(false);
		}
	}, [fetchCustomTriviaCategories]);

	const deleteCategory = useCallback(
		async (id) => {
			try {
				// delete a category
				await deleteCustomTriviaCategory(id);

				// refresh the list
				await fetchCategories();

				setSnackbarConfig({
					isOpen: true,
					message: 'Deleted',
					variant: 'success',
				});
			} catch (error) {
				registerError(error);
			}
		},
		[fetchCategories, deleteCustomTriviaCategory],
	);

	const _publishCategory = useCallback(
		async (index, status) => {
			let categories = [...customCategories];
			// optimistically set the published state
			categories[index].published = status;
			setCustomCategories(categories);

			try {
				await publishCustomTriviaCategory(categories[index].id, status);

				// if the publish/un-publish operation was success, show the proper snackbar
				setSnackbarConfig({
					isOpen: true,
					variant: status ? 'success' : 'info',
					message: status ? 'Published to Categories' : 'Unpublished',
				});
			} catch (error) {
				registerError(error);
				// if there was an error, revert back the toggle state
				categories = [...customCategories];
				categories[index].published = !status;
				setCustomCategories(categories);

				// if the action failed, show an error message
				setSnackbarConfig({
					isOpen: true,
					variant: 'error',
					message: error.message || 'Error saving, retry again later',
				});
			}
		},
		[customCategories, setCustomCategories, publishCustomTriviaCategory],
	);

	// on initial load, fetch all custom categories in the account
	useEffect(() => {
		fetchCategories();
	}, [fetchCategories]);

	const renderCategories = useMemo(() => {
		const options = ['Edit', 'Delete', 'Publish'];

		return (
			<>
				{customCategories?.length > 0 && (
					<>
						<Typography variant="h3" className={classes.heading}>
							Your custom categories
						</Typography>
						<Box display="flex" justifyContent="flex-end">
							<Box display="flex">
								{options.map((option) => (
									<Box
										key={option}
										display="flex"
										justifyContent="center"
										className={classes.tableOptionItem}
									>
										<Typography
											className={classes.optionHeaderText}
										>
											{option}
										</Typography>
									</Box>
								))}
							</Box>
						</Box>
					</>
				)}
				{customCategories.map((category, idx) => (
					<Box
						key={idx}
						my={1}
						display="flex"
						justifyContent="space-between"
					>
						<Box>
							<Link
								style={{ textDecoration: 'none' }} // maybe we don't need the underline in this case
								color="inherit"
								to={hubRoutes.settings.activity.trivia({
									categoryId: category.id,
								})}
								component={RouterLink}
							>
								<Typography
									component="h4"
									className={classes.subHeading}
								>
									{category.name || `Category ${idx + 1}`}
								</Typography>
							</Link>
							<Typography className={classes.label}>
								{category.description}
							</Typography>
						</Box>
						<Box display="flex" alignItems="center">
							<Box
								display="flex"
								justifyContent="center"
								className={classes.tableOptionItem}
							>
								<IconButton
									onClick={() =>
										history.push(
											hubRoutes.settings.activity.trivia({
												categoryId: category.id,
											}),
										)
									}
									aria-label="edit category"
								>
									<EditIcon />
								</IconButton>
							</Box>

							<Box
								display="flex"
								justifyContent="center"
								className={classes.tableOptionItem}
							>
								<IconButton
									onClick={() => deleteCategory(category.id)}
									aria-label="delete category"
								>
									<DeleteForever />
								</IconButton>
							</Box>

							<Box
								display="flex"
								justifyContent="center"
								className={classes.tableOptionItem}
							>
								<Switch
									checked={category.published}
									onClick={(evt) =>
										_publishCategory(
											idx,
											evt.target.checked,
										)
									}
								/>
							</Box>
						</Box>
					</Box>
				))}
			</>
		);
	}, [
		customCategories,
		deleteCategory,
		_publishCategory,
		classes.heading,
		classes.label,
		classes.subHeading,
		classes.optionHeaderText,
		classes.tableOptionItem,
		history,
	]);

	const _createCustomTriviaCategory = async () => {
		try {
			setCreatingCategory(true);
			let { data } = await createCustomTriviaCategory();

			history.push(
				hubRoutes.settings.activity.trivia({ categoryId: data.id }),
			);
		} catch (error) {
			registerError(error);
		} finally {
			setCreatingCategory(false);
		}
	};

	if (fetchingCategories) {
		return <Loading />;
	}

	return (
		<Box>
			{/*<Box
				display="flex"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Box>
					<Typography variant="h3" className={classes.heading}>
						Hide trivia on dashboard
					</Typography>
					<Typography className={classes.label}>
						Make Trivia hidden from players
					</Typography>
				</Box>

				<Switch
					inputProps={{
						'aria-label': 'Switch hide trivia on dashboard'
					}}
					className={classes.switch}
				/>
			</Box>

			<Divider className={classes.divider} />

			<Box className={classes.restrictOptionGrid} mb={2}>
				<Box className={classes.restrictionDetails}>
					<Typography variant="h3" className={classes.heading}>
						Restrict trivia time
					</Typography>
					<Typography className={classes.label}>
						Restrict Trivia between selected times
					</Typography>
				</Box>

				<Box
					className={classes.restrictionTime}
					display="flex"
					alignItems="flex-start"
				>
					<MuiPickersUtilsProvider utils={LuxonUtils}>
						<Box width={60}>
							<TimePicker
								disabled
								className={classes.timePicker}
							/>
						</Box>

						<Box
							mx={1}
							width={8}
							height={2}
							className={classes.dash}
						/>

						<Box width={60}>
							<TimePicker
								disabled
								className={classes.timePicker}
							/>
						</Box>
					</MuiPickersUtilsProvider>
				</Box>

				<Box className={classes.restrictionToggle}>
					<Switch
						inputProps={{
							'aria-label': 'Switch hide trivia on dashboard'
						}}
						className={classes.switch}
					/>
				</Box>
			</Box>

			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Box>
					<Typography variant="h3" className={classes.heading}>
						Scheduled exception
					</Typography>
					<Typography className={classes.label}>
						Time restriction is lifted when a game is scheduled at
						least one hour in advance or is setup by an admin
					</Typography>
				</Box>

				<Switch
					inputProps={{
						'aria-label': 'Switch hide trivia on dashboard'
					}}
					className={classes.switch}
				/>
			</Box>

			<Divider className={classes.divider} />*/}

			<Box mt={1} mb={1}>
				<Typography variant="h3" className={classes.heading}>
					Custom Trivia categories
				</Typography>
				<Typography className={classes.label}>
					Personalise Trivia by adding a custom category
				</Typography>
			</Box>

			<Box width={227} mt={1} mb={1}>
				<LoadingButton
					onClick={_createCustomTriviaCategory}
					loading={creatingCategory}
					disabled={creatingCategory}
					variant="contained"
					color="primary"
				>
					Create category
				</LoadingButton>
			</Box>

			<Divider className={classes.divider} />

			{renderCategories}

			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarConfig.isOpen}
				onClose={() =>
					setSnackbarConfig({ ...snackbarConfig, isOpen: false })
				}
				autoHideDuration={2000}
			>
				<Alert
					icon={false}
					variant="filled"
					severity={snackbarConfig.variant}
					onClose={() =>
						setSnackbarConfig({ ...snackbarConfig, isOpen: false })
					}
				>
					{snackbarConfig.message}
				</Alert>
			</Snackbar>
		</Box>
	);
};
