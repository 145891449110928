import React from 'react';
import {
	Container,
	Divider,
	makeStyles,
	Paper,
	Typography,
} from '@material-ui/core';
import { DocumentHead } from '@common/components';
import { UpdateEmail } from './components/updateEmail';
import { UpdatePassword } from './components/updatePassword';
import { UpdateProviders } from './components/updateProviders';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		borderRadius: '1.25rem',

		[theme.breakpoints.down('sm')]: {
			padding: 0,
			boxShadow: 'none',
			background: 'transparent',
		},
	},
	heading: {
		paddingBottom: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
	},
	divider: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
}));

export const LoginSettings = (): React.ReactElement => {
	const styles = useStyles();

	return (
		<>
			<DocumentHead title="Login Settings" />
			<Container maxWidth="md">
				<Typography variant="h2" className={styles.heading}>
					Login Settings
				</Typography>
				<Paper elevation={5} className={styles.paper}>
					<UpdateEmail />
					<Divider className={styles.divider} />
					<UpdatePassword />
					<Divider className={styles.divider} />
					<UpdateProviders />
				</Paper>
			</Container>
		</>
	);
};
