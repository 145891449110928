import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Showcase } from './Showcase';
import { gamesAndCategoriesSettings } from './SliderSettings';
import { env } from '@common/environment';
import { Card } from './card';

const useStyles = makeStyles((theme) => ({
	gameCard: {
		height: '290px',
		paddingBottom: '1rem',
	},
	playerCount: {
		marginTop: theme.typography.fontSize / 4,
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize - 4,
		fontWeight: 'bold',
	},
	chip: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));

const GamesShowcase = ({ onGameSelected, games }) => {
	const gamesPublishedToHub = games.filter((g) => g.publishedToHub);
	return (
		<>
			<Showcase
				title="Games"
				subtitle="Boost engagement with quick games"
				sliderSetting={gamesAndCategoriesSettings}
				emptyMessage={
					<>
						<h4>You don't have access to any games.</h4>
						<p>
							That probably shouldn't happen, so please get in
							touch with support.
						</p>
						{env().name === 'local' && (
							<pre>DEV: Try running `npm run seed-db`</pre>
						)}
					</>
				}
			>
				{gamesPublishedToHub.map((game, index) => {
					return (
						<div key={index}>
							<GameCard game={game} onSelect={onGameSelected} />
						</div>
					);
				})}
			</Showcase>
		</>
	);
};

const GameCard = ({ game, onSelect }) => {
	const classes = useStyles();

	return (
		<Card
			className={classes.gameCard}
			avatarUrl={game.avatarUrl}
			avatarAlt={game.avatarAlt}
			playerSupport={game.playerSupport}
			onSelect={() => onSelect(game)}
			isPro={game.isPro}
		/>
	);
};

export { GamesShowcase };
