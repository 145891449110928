import { MenuItem } from '@material-ui/core';
import { ActionOpts } from './types';
import { ConfirmationDialogContent } from '../ConfirmationDialogContent';
import { useCurrentAccount } from '@common/contexts';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

export const useMakeAdmin = ({
	member,
	closeDialogue,
	refreshMembers,
	showDialogueContent,
	showSuccessSnackBar,
}: ActionOpts) => {
	const { updateMemberRole } = useCurrentAccount();

	const handleMakeAdmin = async () => {
		await updateMemberRole(member.id, 'admin');
		refreshMembers();
		closeDialogue();
		showSuccessSnackBar(`${member.displayName} is now an admin`);
	};

	const DialogueContent = () => {
		const content = {
			title: 'Assign admin access',
			subTitle1:
				'Admins can invite new people to the Team, provide admin privileges to other members and block and unblock members.',
			subTitle2: 'Admin access can be revoked at any time.',
			btnText: 'ASSIGN',
		};
		return (
			<ConfirmationDialogContent
				content={content}
				action={handleMakeAdmin}
				handleClose={closeDialogue}
			/>
		);
	};

	const openDialog = () => {
		showDialogueContent(DialogueContent);
	};

	return (
		<MenuItem onClick={openDialog}>
			<VerifiedUserOutlinedIcon style={{ marginRight: 10 }} />
			Make admin
		</MenuItem>
	);
};
