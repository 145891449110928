import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { LaxContinueState } from './types';
import { isAllowed } from './user-land-routes/isAllowedRoute';

export function useRouterDiagnostics(opts: {
	elements: JSX.Element[];
	isAuthenticated: boolean;
	isLoaded: boolean;
	enabled: boolean;
}) {
	const location = useLocation<LaxContinueState | undefined>();
	const history = useHistory<LaxContinueState | undefined>();

	const routeElements = opts.elements.filter(isAllowed);

	const routes = routeElements.map(({ props, type }) => ({
		...props,
		type: typeof type === 'string' ? type : type.name,
		match:
			'to' in props
				? props.from
					? matchPath(location.pathname, props.from)
					: true
				: matchPath(location.pathname, props),
	}));

	const matching = routes.filter(({ match }) => Boolean(match));

	if (opts.enabled) {
		/**
		 * These would only cover all declaratively rendered routes and redirects
		 * But redirects that are effected through history.push cannot be highlighted here
		 */
		console.log('Routing configuration', {
			pathname: location.pathname,
			...(location.state?.continuePath && {
				continuePath: location.state?.continuePath,
			}),
			...(location.state?.returnPath && {
				returnPath: location.state?.returnPath,
			}),
			...(location.state?.previousPath && {
				previousPath: location.state?.previousPath,
			}),
			...(location.state &&
				'from' in location.state && {
					from: location.state.from,
				}),
			location,
			/**
			 * Routes and Redirects that match current location
			 */
			matching,
			isAuthenticated: opts.isAuthenticated,
			isLoaded: opts.isLoaded,
			/**
			 * All current routes
			 */
			routes,
		});
	}

	React.useEffect(() => {
		return history.listen((location, action) => {
			if (opts.enabled) {
				console.log(`History ${action} to`, location);
			}
		});
	}, [opts.enabled, history]);
}
