import { DateTime } from 'luxon';

export const timestampToDate = ({ seconds }) => new Date(seconds * 1000);

export const formatDate = (date, now = new Date(), locale) => {
	const target = DateTime.fromJSDate(date).reconfigure({
		locale,
	});
	const currentTime = DateTime.fromJSDate(now);
	const diff = target.diff(currentTime);
	const totalMinutes = Math.abs(diff.as('minutes'));
	const totalHours = Math.abs(diff.as('hours'));
	const totalDays = Math.abs(diff.as('days'));
	const totalWeeks = Math.abs(diff.as('weeks'));
	const totalMonths = Math.abs(diff.as('months'));
	const totalYears = Math.abs(diff.as('years'));
	if (totalYears > 1) {
		return target.toRelative({
			base: currentTime,
			unit: 'years',
		});
	}
	if (totalMonths > 1 && totalMonths <= 12) {
		return target.toRelative({
			base: currentTime,
			unit: 'months',
		});
	}
	if (totalWeeks > 1 && totalWeeks <= 5) {
		return target.toRelative({
			base: currentTime,
			unit: 'weeks',
		});
	}
	if (totalDays > 1 && totalDays <= 7) {
		return target.toRelative({
			base: currentTime,
			unit: 'days',
		});
	}
	if (totalHours > 1 && totalHours <= 24) {
		return target.toRelative({
			base: currentTime,
			unit: 'hours',
		});
	}
	if (totalMinutes >= 10 && totalMinutes <= 60) {
		return target.toRelative({
			base: currentTime,
			unit: 'minutes',
		});
	}
	return 'moments ago';
};
