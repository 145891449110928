import { MemberProfile } from '@contracts/member-profile';
import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AboutField } from './aboutField';
import { formatBirthday, formatWorkAnniversary } from '../format';
import clsx from 'clsx';
import { internalSpacing, paperStyles } from './sharedStyles';
import { ensureExists } from '@remote-social/common/src';

const useStyles = makeStyles((theme) => {
	return createStyles({
		rightInfoPaper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(internalSpacing),

			...paperStyles(theme),
		},
	});
});

type Props = {
	className?: string;
	profile: MemberProfile;
};

export const AboutPanel: React.ComponentType<Props> = ({
	className,
	profile,
}) => {
	const styles = useStyles();
	const displayNameFieldId = React.useMemo(
		() =>
			ensureExists(
				profile.fields.find(
					(field) => field.propertyName === 'displayName',
				)?.profileFieldId,
			),
		[profile.fields],
	);
	return (
		<Paper className={clsx(styles.rightInfoPaper, className)} elevation={5}>
			<Typography variant="h3">About</Typography>
			{profile.values[displayNameFieldId] &&
				(profile.props.firstName || profile.props.lastName) && (
					<AboutField
						label="Name"
						value={[
							profile.props.firstName,
							profile.props.lastName,
						].join(' ')}
						wordBreakAll
					/>
				)}
			<AboutField
				label="Email"
				value={profile.props.email}
				wordBreakAll
			/>
			<AboutField
				label="Birthday"
				value={formatBirthday(profile.props.birthday)}
			/>
			<AboutField
				label="Work Anniversary"
				value={formatWorkAnniversary(profile.props.employmentStartedOn)}
			/>
			<AboutField
				label="Remote Social Team"
				value={
					<>
						{profile.accountName || 'Unnamed'}
						<div>
							Joined{' '}
							{formatWorkAnniversary(profile.accountJoinedOn)}
						</div>
					</>
				}
			/>
			<AboutField label="Fun fact" value={profile.props.funFact} />
		</Paper>
	);
};
