import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useCurrentAccount } from '../../contexts';
import { routes } from '../routes';
import React from 'react';
import { Loading } from '../../components/loading';
import { PageError } from '@common';

export type HasAccountRouteProps = RouteProps & {
	shouldUpsell?: boolean;
	Loading?: React.ComponentType;
};

export type CreateFirstAccountState = {
	shouldUpsell: boolean;
};

export const HasAccountRoute: React.ComponentType<HasAccountRouteProps> = (
	props,
) => {
	const { loadingAccounts, needsAccountCreated, error } = useCurrentAccount();
	const areAccountsLoaded = !loadingAccounts;
	const location = useLocation();
	const shouldUpsell = props?.shouldUpsell ?? true;
	const LoadingProp = props.Loading || Loading;

	if (error) {
		return (
			<Route {...props}>
				<PageError error={error} />;
			</Route>
		);
	}

	if (!areAccountsLoaded) {
		return (
			<Route {...props}>
				<LoadingProp />
			</Route>
		);
	}

	if (typeof needsAccountCreated === 'boolean' && needsAccountCreated) {
		return (
			<Redirect
				to={routes.createFirstAccount({
					preserveContinuePath: true,
					location,
					shouldUpsell,
				})}
			/>
		);
	}

	return <Route {...props} />;
};
