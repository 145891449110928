import React from 'react';
import { Member } from '@contracts/member-profile';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { RequestState } from '@common/store-tools';
import { CollapsableAccountMembersGrid } from '../../../components/account-members-containers/collapsable';
import LinkIcon from '@material-ui/icons/Link';
import { useInviteMembersDialogue } from '../../../components/invite-members-dialogue/dialogue';

const useStyles = makeStyles((theme) => ({
	myTeamHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'baseline',
	},
	myTeamLinks: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(2),
	},
	button: {
		padding: 0,
	},
}));

type Props = {
	membersRequest: RequestState<Member[], Member[]>;
};

const useComponentState = () => {
	const [collapsed, setCollapsed] = React.useState(true);
	const [numberOfHiddenMembers, setNumberOfHiddenMembers] =
		React.useState<number>();

	const triggerCollapsed = React.useCallback(() => {
		setCollapsed(!collapsed);
	}, [collapsed, setCollapsed]);

	const setExpandability = React.useCallback(
		(opts: { numberOfHiddenMembers: number }) => {
			setNumberOfHiddenMembers(opts.numberOfHiddenMembers);
		},
		[],
	);

	const { dialogueElement, openDialogue } = useInviteMembersDialogue();

	return {
		collapsed,
		numberOfHiddenMembers,
		triggerCollapsed,
		setExpandability,
		dialogueElement,
		openDialogue,
	};
};

export const AccountMembersSection: React.ComponentType<Props> = ({
	membersRequest,
}) => {
	const styles = useStyles();
	const {
		collapsed,
		triggerCollapsed,
		setExpandability,
		numberOfHiddenMembers,
		dialogueElement,
		openDialogue,
	} = useComponentState();

	return (
		<>
			<div className={styles.myTeamHeader}>
				<Typography variant="h3" gutterBottom>
					My Team
				</Typography>
				<div className={styles.myTeamLinks}>
					<Button
						variant="text"
						color="primary"
						onClick={openDialogue}
						className={styles.button}
						startIcon={<LinkIcon />}
					>
						Invite People
					</Button>
					{typeof numberOfHiddenMembers === 'number' &&
						numberOfHiddenMembers > 0 && (
							<Button
								variant="text"
								color="primary"
								onClick={triggerCollapsed}
								className={styles.button}
							>
								{collapsed
									? `Show ${numberOfHiddenMembers} More`
									: 'Show Less'}
							</Button>
						)}
				</div>
			</div>
			<CollapsableAccountMembersGrid
				membersRequest={membersRequest}
				maxNumRowsWhenCollapsed={2}
				onExpandabilityChange={setExpandability}
				collapsed={collapsed}
			/>
			{dialogueElement}
		</>
	);
};
