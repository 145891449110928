import { MenuItem } from '@material-ui/core';
import { ActionOpts } from './types';
import { ConfirmationDialogContent } from '../ConfirmationDialogContent';
import { useCurrentAccount } from '@common/contexts';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

export const useRevokeAdmin = ({
	member,
	closeDialogue,
	refreshMembers,
	showDialogueContent,
	showSuccessSnackBar,
}: ActionOpts) => {
	const { updateMemberRole } = useCurrentAccount();

	const handleRevokeAdmin = async () => {
		await updateMemberRole(member.id, 'member');
		refreshMembers();
		closeDialogue();
		showSuccessSnackBar(`${member.displayName} is no longer an admin`);
	};

	const DialogueContent = () => {
		const content = {
			title: 'Revoke admin access',
			subTitle1: 'This member will lose admin access.',
			subTitle2:
				'If you change your mind, you can simply assign admin access to this member again later.',
			btnText: 'REVOKE',
		};
		return (
			<ConfirmationDialogContent
				content={content}
				action={handleRevokeAdmin}
				handleClose={closeDialogue}
			/>
		);
	};

	const openDialog = () => {
		showDialogueContent(DialogueContent);
	};

	return (
		<MenuItem onClick={openDialog}>
			<RemoveCircleOutlineOutlinedIcon style={{ marginRight: 10 }} />
			Revoke admin
		</MenuItem>
	);
};
