import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { AppLayout as CommonAppLayout } from '@common/components/AppLayout';
import backgroundSmall from '@common/assets/images/Background-small.svg';
import {
	ScreenContainer,
	ScreenContainerProps,
} from '@common/components/screenContainer';

const useStyles = makeStyles(() => ({
	onboardingAppLayout: {
		background: `url(${backgroundSmall}) center 100% no-repeat`,
		backgroundSize: 'cover',
	},
}));

export const OnboardingLayout: React.ComponentType<
	React.ComponentProps<typeof CommonAppLayout> & ScreenContainerProps
> = ({ variant, containerClassName, children, ...rest }) => {
	const styles = useStyles();

	return (
		<CommonAppLayout {...rest} className={styles.onboardingAppLayout}>
			<Container maxWidth="md" fixed>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					style={{ minHeight: 'calc(100vh - 184px)' }}
				>
					{variant ? (
						<ScreenContainer
							variant={variant}
							containerClassName={containerClassName}
						>
							{children}
						</ScreenContainer>
					) : (
						<>{children}</>
					)}
				</Grid>
			</Container>
		</CommonAppLayout>
	);
};
