import React from 'react';
import { useCurrentAccount } from '@common/contexts/useCurrentAccount';
import { useCurrentUser } from '@common/contexts/CurrentUser';
import { useConfigFlag } from '@common/hooks/useConfigFlag';
import { dashboard, GameInformation } from '../../data';
import { usePlayableGames } from '../../store/games';
import { ensureExists } from '@contracts/shared/preconditions';
import { useAccountMembers } from '../../store/account-members';

export function useState() {
	const user = useCurrentUser();
	const { currentAccountId } = useCurrentAccount();
	const games = usePlayableGames();

	const [gameIntroModal, setGameIntroModal] = React.useState<
		| {
				isOpen: true;
				game: GameInformation;
		  }
		| {
				isOpen: false;
				game?: undefined;
		  }
	>({
		isOpen: false,
	});

	const handleOpenGameIntro = React.useCallback(
		(game: GameInformation) => {
			setGameIntroModal({ isOpen: true, game });
		},
		[setGameIntroModal],
	);

	const handleCloseGameIntro = React.useCallback(() => {
		setGameIntroModal({ isOpen: false });
	}, [setGameIntroModal]);

	const [hubScheduler, setHubScheduler] = React.useState<
		| {
				isOpen: true;
				game?: GameInformation;
		  }
		| {
				isOpen: false;
				game?: undefined;
		  }
	>({
		isOpen: false,
	});

	const handleOpenScheduler = React.useCallback(
		(game?: GameInformation) => {
			setHubScheduler({ isOpen: true, game });
		},
		[setHubScheduler],
	);

	const handleCloseScheduler = React.useCallback(() => {
		setHubScheduler({ isOpen: false });
	}, [setHubScheduler]);

	const [snackBar, setSnackBar] = React.useState<
		| {
				isOpen: true;
				data: React.ReactNode;
		  }
		| {
				isOpen: false;
				data?: undefined;
		  }
	>({
		isOpen: false,
	});

	const handleOpenSnackBar = React.useCallback(
		(data: React.ReactNode) => {
			setSnackBar({ isOpen: true, data });
		},
		[setSnackBar],
	);

	const handleCloseSnackBar = React.useCallback(() => {
		setSnackBar({ isOpen: false });
	}, [setSnackBar]);

	const handleCtaSelection = React.useCallback(
		(cta: { type: 'schedule' | 'game'; gameId: string }) => {
			switch (cta.type) {
				case 'schedule':
					handleOpenScheduler();
					break;
				case 'game':
					handleOpenGameIntro(
						ensureExists(games.find((g) => g.id === cta.gameId)),
					);
					break;
				default:
					return;
			}
		},
		[handleOpenScheduler, handleOpenGameIntro, games],
	);

	const memberProfilesEnabled = useConfigFlag(
		'memberProfilesEnabled',
	).asBoolean();

	const membersRequest = useAccountMembers();

	const { banners, ctas, connect, checkin } = dashboard;

	return {
		banners: banners(currentAccountId),
		ctas,
		connect,
		checkin,
		user,
		games,
		gameIntroModal,
		handleOpenGameIntro,
		handleCloseGameIntro,
		hubScheduler,
		handleOpenScheduler,
		handleCloseScheduler,
		snackBar,
		handleOpenSnackBar,
		handleCloseSnackBar,
		handleCtaSelection,
		membersRequest,
		memberProfilesEnabled,
	};
}
