import { useCurrentAccount, useCurrentUser } from '@common';
import { GameInformation } from './games';

function ensureValidUrl(url: string) {
	try {
		return new URL(url).toString();
	} catch (err) {
		throw new Error(`${url} is not a valid URL`);
	}
}

export const formatUrlTemplate = (
	template: string,
	params: Record<string, string | undefined>,
	opts: {
		keepVariables: boolean;
	},
) => {
	return (
		template
			.replace(/{([^}]+)}/g, (match, parameterKey) => {
				if (opts.keepVariables) {
					return params[parameterKey] ?? match;
				} else {
					return params[parameterKey] ?? '';
				}
			})
			.replace(/([^:])\/\//g, (_, text) => text) ?? ''
	);
};

const formatHubUrlOrPath = (
	template: string,
	params: {
		uid: string;
		accountId: string;
		displayName: string | undefined;
	},
) => {
	return formatUrlTemplate(
		template,
		{
			userId: params.uid,
			accountId: params.accountId,
			displayName: params.displayName,
		},
		{
			keepVariables: false,
		},
	);
};

export const useFormattedHubUrlOrPath = (url: string) => {
	const { uid, displayName } = useCurrentUser();
	const { currentAccountId } = useCurrentAccount();

	return (
		url &&
		currentAccountId &&
		uid &&
		formatHubUrlOrPath(url, {
			uid,
			accountId: currentAccountId,
			displayName: displayName,
		})
	);
};

export const preFormatGameUrlTemplate = (
	template: string,
	params: {
		uid: string;
		accountId: string;
		displayName: string | undefined;
	},
) => {
	return formatUrlTemplate(
		template,
		{
			userId: params.uid,
			accountId: params.accountId,
			displayName: params.displayName,
		},
		{
			keepVariables: true,
		},
	);
};

export const preFormatGameUrlsTemplates = (
	gamesData: GameInformation[],
	params: {
		uid: string;
		accountId: string;
		displayName: string | undefined;
	},
): GameInformation[] => {
	return gamesData
		.map((game) => ({
			...game,
			links: game.links.map((link) => ({
				...link,
				href: preFormatGameUrlTemplate(link.href, params),
			})),
		}))
		.map((game) => {
			const newGameLink = game.links.find(
				(link) => link.rel === 'new-game',
			);
			return newGameLink
				? {
						...game,
						newGameHref: ensureValidUrl(newGameLink.href),
				  }
				: game;
		});
};

export const formatGameUrl = (
	template: string,
	params: {
		gameSlug: string;
	},
) => {
	return ensureValidUrl(
		formatUrlTemplate(template, params, {
			keepVariables: false,
		}),
	);
};
