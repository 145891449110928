import clsx from 'clsx';
import { Chip, makeStyles, Theme, Typography } from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import React, { MouseEventHandler } from 'react';
import { CardMetadata } from './cardMetadata';
import { GameInformation } from '../../../data/games';
import { ProChip } from '@common/components/proChip';

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		position: 'relative',
		margin: theme.spacing(1),
		cursor: 'pointer',
	},
	interactive: {
		transition: 'transform 200ms',
		'&:hover': {
			transform: 'scale(1.03)',
			'& > div:nth-child(1)': {
				boxShadow:
					'0 12px 15px -12px rgba(0, 0, 100, 0.6), 0 0 2px rgba(0, 0, 100, 0.2)',
			},
		},
	},
	cardImageWrapper: {
		width: '100%',
		height: '100%',
		position: 'relative',
		borderRadius: theme.spacing(2),
		overflow: 'hidden',
		boxShadow: '0 3px 6px rgba(0, 0, 50, 0.3)',
		transition: 'all 200ms',
	},
	hostedExperienceWrapper: {
		width: '100%',
	},
	cardImage: {
		top: 0,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	cardLabel: {
		minHeight: '1rem',
	},
	coverCardAssistive: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		width: '100%',
		textIndent: -99999,
		border: 'none',
		padding: 0,
		margin: 0,
		cursor: 'pointer',
		borderRadius: '1rem',
	},
	category: {
		fontSize: theme.typography.fontSize - 6,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.primary.main,
	},
	chip: {
		zIndex: 10,
		position: 'absolute',
		bottom: 10,
		right: 10,
		background: '#fff',
		pointerEvents: 'none',
	},
	proChip: {
		zIndex: 10,
		position: 'absolute',
		top: 10,
		right: 10,
		pointerEvents: 'none',
	},
}));

type Props = {
	name?: string;
	category?: string;
	playerSupport?: GameInformation['playerSupport'];
	avatarUrl: string;
	avatarAlt?: string;
	tooltip?: string;
	href?: string;
	onSelect?: MouseEventHandler<HTMLButtonElement> | undefined;
	hostedExperience?: boolean;
	newTab?: boolean;
	isPro?: boolean;
	className?: string;
};

export const Card: React.ComponentType<Props> = ({
	name,
	category,
	playerSupport,
	className,
	avatarUrl,
	avatarAlt = '',
	tooltip,
	href,
	onSelect,
	hostedExperience,
	newTab = false,
	isPro = false,
	children,
}) => {
	const styles = useStyles();
	onSelect = onSelect || function () {};

	const formatPlayerCount = React.useCallback(
		(playerSupport: GameInformation['playerSupport']) => {
			if (playerSupport.max) {
				if (playerSupport.min) {
					return `${playerSupport.min}-${playerSupport.max}`;
				}
				return `Up to ${playerSupport.max}`;
			}
			return playerSupport.min
				? `${playerSupport.min}+`
				: 'Unlimited Participants';
		},
		[],
	);

	return (
		<article
			className={clsx(styles.card, styles.interactive, className)}
			title={tooltip}
		>
			<div
				className={clsx(
					styles.cardImageWrapper,
					hostedExperience ? styles.hostedExperienceWrapper : null,
				)}
			>
				<img
					className={styles.cardImage}
					src={avatarUrl}
					alt={avatarAlt}
					width="100%"
				/>
				{playerSupport && (
					<Chip
						className={styles.chip}
						icon={<PeopleAltIcon />}
						size="small"
						label={
							playerSupport && formatPlayerCount(playerSupport)
						}
					/>
				)}
				{isPro && (
					<>
						{' '}
						<ProChip className={styles.proChip} />
					</>
				)}
			</div>
			{category && (
				<CardMetadata className={styles.category}>
					{category}
				</CardMetadata>
			)}
			{name && (
				<Typography variant="h6" className={styles.cardLabel}>
					{name}
				</Typography>
			)}
			{href ? (
				<a
					className={styles.coverCardAssistive}
					href={href}
					target={newTab ? '_blank' : '_self'}
					rel="noreferrer"
				>
					{name}
				</a>
			) : (
				<button
					className={styles.coverCardAssistive}
					onClick={onSelect}
				>
					{name}
				</button>
			)}
			{children}
		</article>
	);
};
