import { hubRoutes } from '@common/routes/hubRoutes';
import { useLocation } from 'react-router-dom';
import { BreadcrumbSegment } from './segment';
import { BreadcrumbsContainer } from './container';

export const staticBreadcrumbDescriptionByRoute: Record<
	string,
	string | undefined
> = {
	[hubRoutes.sessions.path]: 'Sessions',
	[hubRoutes.settings.activity.root.path]: 'Activity Settings',
	[hubRoutes.settings.activity.trivia.path]: 'Trivia Customizer',
	[hubRoutes.settings.account.path]: 'Team Settings',
	[hubRoutes.settings.people.path]: 'People',
	[hubRoutes.settings.billing.path]: 'Billing & Subscription',
};

export const StaticBreadcrumbs = () => {
	const location = useLocation();

	const segments = location.pathname.split('/').filter((x) => x);
	if (segments.includes('trivia')) {
		segments.splice(segments.length - 1, 1);
	}

	return (
		<BreadcrumbsContainer>
			<BreadcrumbSegment key={'/'} to={'/'}>
				Dashboard
			</BreadcrumbSegment>

			{segments.map((_, index) => {
				const isLast = index === segments.length - 1;
				const to = `/` + segments.slice(0, index + 1).join('/');
				const description = staticBreadcrumbDescriptionByRoute[to];

				if (!description) {
					return null;
				}

				return (
					<BreadcrumbSegment key={to} to={to} current={isLast}>
						{description}
					</BreadcrumbSegment>
				);
			})}
		</BreadcrumbsContainer>
	);
};
