import React from 'react';
import {
	Box,
	Button,
	Dialog,
	IconButton,
	Theme,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { ProChip } from '@common/components/proChip';
import { GameInformation } from '../../../data/games';
import { routes, useConfigFlag, useCurrentAccount } from '@common';
import { useHistory, useLocation } from 'react-router-dom';
import { ensureValidUrl } from '@common/utils/ensureValidUrl';

const useStyle = makeStyles((theme: Theme) => ({
	border: {
		borderRadius: '1.5rem',
	},
	modalPaper: {
		padding: 10,
		maxWidth: 800,
	},
	closeButton: {
		position: 'absolute',
		top: 15,
		right: 15,
		padding: 0,
	},
	modalWrapper: {
		position: 'relative',
		minWidth: '100%',
		display: 'grid',
		gridTemplateAreas: '"illustration" "buttons" "content"',
		gridTemplateColumns: '1fr',
		gridTemplateRows: '1fr auto auto',
		gridGap: theme.spacing(3),
		padding: theme.spacing(2),
		paddingRight: theme.spacing(1.5),

		[theme.breakpoints.up('md')]: {
			gridTemplateAreas: '"illustration content" "illustration buttons"',
			gridTemplateColumns: '1fr 1fr',
			gridTemplateRows: '1fr',
		},
	},
	illustrationContainer: {
		gridArea: 'illustration',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	illustration: {
		width: '100%',
		height: '100%',

		[theme.breakpoints.up('sm')]: {
			width: 400,
			height: 400,
		},
	},
	content: {
		gridArea: 'content',
	},
	title: {
		fontWeight: 900,
		textAlign: 'center',
		marginBottom: theme.spacing(1),

		[theme.breakpoints.up('md')]: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(2),
		},
	},
	pointText: {
		fontSize: '0.875rem',
		marginBottom: theme.spacing(1),
	},
	buttons: {
		gridArea: 'buttons',
	},
	secondaryButton: {
		marginTop: theme.spacing(1),
	},
}));

type Props = {
	open: boolean;
	onClose: () => void;
	game: GameInformation & { newGameHref?: string };
	onScheduleGame: () => void;
};

export const GameIntroModal: React.ComponentType<Props> = ({
	open,
	onClose,
	game,
	onScheduleGame,
}) => {
	const location = useLocation();
	const history = useHistory();
	const styles = useStyle();
	const isVideo = game.introduction?.explainerVideo.indexOf('.mp4') > 0;
	const { currentAccount } = useCurrentAccount();
	const isProAccount = currentAccount?.tier === 'pro';
	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();
	const canAccessGame = !billingEnabled || !game.isPro || isProAccount;

	const newGameHandler = React.useCallback(() => {
		if (canAccessGame) {
			if (game.newGameHref) {
				window.location.href = ensureValidUrl(game.newGameHref);
			}
		} else {
			history.push(
				routes.subscribe({
					preserveContinuePath: true,
					location,
					intent: 'paywall',
				}),
			);
		}
	}, [game.newGameHref, canAccessGame, history, location]);

	const scheduleHandler = React.useCallback(() => {
		if (canAccessGame) {
			onScheduleGame();
		} else {
			history.push(
				routes.subscribe({
					preserveContinuePath: true,
					location,
					intent: 'paywall',
				}),
			);
		}
	}, [canAccessGame, history, location, onScheduleGame]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperProps={{
				className: `${styles.border} ${styles.modalPaper}`,
			}}
			aria-labelledby="game-introduction"
		>
			<Box className={styles.modalWrapper}>
				<Box className={styles.illustrationContainer}>
					{isVideo ? (
						<video
							className={`${styles.border} ${styles.illustration}`}
							src={game.introduction?.explainerVideo}
							title={game.name}
							playsInline={true}
							loop={true}
							controls={false}
							autoPlay={true}
							muted
						/>
					) : (
						<img
							className={`${styles.border} ${styles.illustration}`}
							src={game.introduction?.explainerVideo}
							alt={game.name}
						/>
					)}
				</Box>
				<Box className={styles.content}>
					<Typography
						component="h2"
						variant="h4"
						className={styles.title}
					>
						{game.name}
						{game.isPro && (
							<>
								{' '}
								<ProChip />
							</>
						)}
					</Typography>
					{game.introduction?.points?.map(
						(point: string, index: number) => (
							<Typography
								key={index}
								className={styles.pointText}
							>
								{point}
							</Typography>
						),
					)}
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					className={styles.buttons}
				>
					{game.newGameHref && (
						<Button
							variant="contained"
							color="default"
							onClick={newGameHandler}
						>
							{game.category === 'slack'
								? `Create a ${game.name}`
								: 'Play now'}
						</Button>
					)}
					{game.canSchedule && (
						<Button
							variant="outlined"
							color="default"
							onClick={scheduleHandler}
							className={styles.secondaryButton}
						>
							Schedule a future game
						</Button>
					)}
				</Box>
			</Box>

			<IconButton
				onClick={onClose}
				aria-label="close"
				className={styles.closeButton}
			>
				<CloseIcon />
			</IconButton>
		</Dialog>
	);
};
