import { MenuItem } from '@material-ui/core';
import { ActionOpts } from './types';
import { ConfirmationDialogContent } from '../ConfirmationDialogContent';
import { useCurrentAccount } from '@common/contexts';
import CancelScheduleSendOutlinedIcon from '@material-ui/icons/CancelScheduleSendOutlined';

export const useCancelInvite = ({
	member,
	closeDialogue,
	refreshMembers,
	showDialogueContent,
	showSuccessSnackBar,
}: ActionOpts) => {
	const { cancelInvite } = useCurrentAccount();

	const handleCancelInvite = async () => {
		await cancelInvite(member.id);
		refreshMembers();
		closeDialogue();
		showSuccessSnackBar(`Invitation has been cancelled`);
	};

	const DialogueContent = () => {
		const content = {
			title: 'Cancel invite',
			subTitle1: 'This invitation will be cancelled',
			subTitle2:
				'If you change your mind you can resend in the invitation.',
			btnText: 'CONFIRM',
		};
		return (
			<ConfirmationDialogContent
				content={content}
				action={handleCancelInvite}
				handleClose={closeDialogue}
			/>
		);
	};

	const openDialog = () => {
		showDialogueContent(DialogueContent);
	};

	return (
		<MenuItem onClick={openDialog}>
			<CancelScheduleSendOutlinedIcon style={{ marginRight: 10 }} />
			Cancel invite
		</MenuItem>
	);
};
