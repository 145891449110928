import React, { useMemo, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Paper,
	Typography,
} from '@material-ui/core';
import { useCurrentAccount } from '@common/contexts';
import {
	DocumentHead,
	isPending,
	isSuccess,
	Loading,
	useAsyncFunction,
} from '@common';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FilterAccount } from '../components/FilterAccount';
import { AccountMembersTable } from './AccountMembersTable';
import { useActionsMenu } from './ActionsMenu';
import { InviteButton } from './invite-button/inviteButton';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		borderRadius: '1.25rem',

		[theme.breakpoints.down('sm')]: {
			padding: 0,
			boxShadow: 'none',
			background: 'transparent',
		},
	},
	heading: {
		paddingBottom: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
	},
	intro: {
		paddingBottom: theme.spacing(2),
	},
	tableWrapper: {
		[theme.breakpoints.down('sm')]: {
			overflowX: 'scroll',
		},
	},
}));

const roles = [
	{ label: 'Owner', value: 'owner' },
	{ label: 'Admin', value: 'admin' },
	{ label: 'Member', value: 'member' },
	// { label: 'Blocked', value: 'blocked' },
];

export default function People() {
	const classes = useStyles();
	const theme = useTheme();
	const { isLoaded, currentAccount, getAccountMembersWithRolesAndInvites } =
		useCurrentAccount();
	const [roleList, setRoleList] = useState([]);
	const [searchFilter, setSearchFilter] = useState('');
	const isXs = useMediaQuery(theme.breakpoints.down('xs'));

	const [getAccountMembersRequest, refreshMembers] = useAsyncFunction(
		/**
		 * @param {string} accountId
		 * */
		async (accountId) => {
			const { data } = await getAccountMembersWithRolesAndInvites(
				accountId,
			);

			const { members, invites } = data;

			return [...members, ...invites];
		},
		{
			callOnChange: () => {
				if (!currentAccount?.accountId) return;

				return currentAccount.accountId;
			},
		},
	);

	const allMembers =
		isSuccess(getAccountMembersRequest) && getAccountMembersRequest.data;

	const members = useMemo(() => {
		if (!allMembers) return [];

		if (!roleList.length && !searchFilter) {
			return allMembers;
		}

		const roles = roleList.length > 0 && roleList.map((role) => role.value);

		return allMembers.filter(
			(member) =>
				(!searchFilter ||
					[member?.displayName, member?.email]
						.filter(Boolean)
						.map((text) => text.toLowerCase())
						.some((text) => text.includes(searchFilter))) &&
				(!roles || roles.includes(member.role)),
		);
	}, [allMembers, roleList, searchFilter]);

	const { showActionsForMember, showSuccessSnackBar, actionMenuElements } =
		useActionsMenu({ refreshMembers });

	const handleInviteSent = (message) => {
		refreshMembers();
		showSuccessSnackBar(message);
	};

	if (!isLoaded || isPending(getAccountMembersRequest)) {
		return <Loading />;
	}

	return (
		<>
			<DocumentHead title="People" />
			<Container maxWidth="md">
				<Typography variant="h2" className={classes.heading}>
					People
				</Typography>

				<Paper elevation={5} className={classes.paper}>
					<Grid
						container
						className={classes.intro}
						spacing={1}
						alignItems="center"
					>
						<Grid item xs={12} sm={9}>
							<FilterAccount
								multiSelectLabel="Roles"
								status={roleList}
								statusOptions={roles}
								handleRoleFilterChange={(e) =>
									setRoleList(e.target.value)
								}
								handleSearchFilterChange={(e) =>
									setSearchFilter(
										e.target.value.toLowerCase(),
									)
								}
							/>
						</Grid>

						<Grid item xs={12} sm={3}>
							<Box
								display="flex"
								justifyContent={isXs ? 'center' : 'flex-end'}
							>
								<InviteButton
									showSuccessSnackBar={handleInviteSent}
								/>
							</Box>
						</Grid>
					</Grid>
					<div className={classes.tableWrapper}>
						<AccountMembersTable
							members={members}
							showActionsForMember={showActionsForMember}
						/>
						{actionMenuElements}
					</div>
				</Paper>
			</Container>
		</>
	);
}
