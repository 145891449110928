import { createRemoteSocialTheme } from '@common/styles';
import { createTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
	export interface Palette {
		adminChip: {
			contrastText: string;
			background: string;
		};
		ownerChip: {
			contrastText: string;
			background: string;
		};
	}
}

export const createHubTheme = () => {
	return createTheme(
		createRemoteSocialTheme({
			name: 'hub',
			createPalette: () => ({
				type: 'light',
				primary: {
					main: '#2196F3',
				},
				secondary: {
					main: '#FF505F',
				},
				adminChip: {
					contrastText: 'white',
					background: `#007DFF`,
				},
				ownerChip: {
					contrastText: '#fff',
					background: `#9B51E0`,
				},
			}),
		}),
	);
};
